import React, { memo, useCallback, useMemo, useState } from "react";
import Logo from "../../assets/logo.svg";
import { useLocale } from "../../hooks/useLocale";
import { Link } from "react-router-dom";
import { CHILD_LINK, PARENT_LINK } from "../../common/define";
import { useAppRouterContext } from "../../contexts/RouterContext";

interface INavChild {
	name: string;
	url: string;
	childLink: string;
	isShow?: boolean;
}

interface INavItem {
	name: string;
	icon: string;
	url: string;
	parentLink: string;
	children?: INavChild[];
}

const NAV_LIST: INavItem[] = [
	// { name: "SERVICE_MANAGEMENT", icon: "users", url: "/services" },
	{
		name: "MEMBER_MANAGEMENT",
		icon: "users",
		url: "/member",
		parentLink: PARENT_LINK.MEMBER_MANAGEMENT,
		children: [
			{
				isShow: false,
				name: PARENT_LINK.MEMBER_MANAGEMENT,
				url: "/member",
				childLink: PARENT_LINK.MEMBER_MANAGEMENT,
			},
		],
	},
	{
		name: "INSURANCE_CUSTOMER_MANAGEMENT",
		icon: "user-circle",
		url: "/customer",
		parentLink: PARENT_LINK.INSURANCE_CUSTOMER_MANAGEMENT,
		children: [
			{
				isShow: false,
				name: PARENT_LINK.INSURANCE_CUSTOMER_MANAGEMENT,
				url: "/customer",
				childLink: PARENT_LINK.INSURANCE_CUSTOMER_MANAGEMENT,
			},
		],
	},
	{
		name: "CONTRACT_MANAGEMENT",
		icon: "phone",
		url: "/contract",
		parentLink: PARENT_LINK.CONTRACT,
		children: [
			{
				name: "VIEW_ENTIRE_CONTRACT",
				url: "/contract",
				childLink: CHILD_LINK.VIEW_CONTRACT,
			},
			{
				name: "APPLICATION_PENDING_MANAGEMENT",
				url: "/contract/pending",
				childLink: CHILD_LINK.PENDING_CONTRACT,
			},
			{
				name: "CANCELLATION_MANAGEMENT",
				url: "/contract/cancellation",
				childLink: CHILD_LINK.CANCELLING_CONTRACT,
			},
			{
				name: "EARLY_TERMINATION_MANAGEMENT",
				url: "/contract/early-termination",
				childLink: CHILD_LINK.EARLY_TERMINATION_CONTRACT,
			},
		],
	},
	{
		name: "PRODUCT_MANAGEMENT",
		icon: "box",
		url: "/product",
		parentLink: PARENT_LINK.PRODUCT,
		children: [
			{
				name: "SALES_PRODUCT_MANAGEMENT",
				url: "/product",
				childLink: CHILD_LINK.VIEW_PRODUCT,
			},
			{
				name: "ACTUAL_LOSS_INSURANCE",
				url: "/product/actual",
				childLink: CHILD_LINK.ACTUAL_LOSS_PRODUCT,
			},
			{
				name: "POST_TRANSIT_INSURANCE",
				url: "/product/postTransit",
				childLink: CHILD_LINK.POST_TRANSIT_PRODUCT,
			},
		],
	},
	{
		name: "SETTING",
		icon: "settings",
		url: "/setting",
		parentLink: PARENT_LINK.SETTING,
		children: [
			{
				name: "COUNTRY_MANAGEMENT",
				url: "/setting",
				childLink: CHILD_LINK.COUNTRY_MANAGEMENT,
			},
			{
				name: "INSURED_ITEMS",
				url: "/setting/insured-items",
				childLink: CHILD_LINK.INSURED_ITEMS,
			},
		],
	},
];

const NavItem = ({ menuItem }: { menuItem: INavItem }) => {
	const { t } = useLocale();
	const {
		currentChildLink,
		currentParentLink,
		setCurrentChildLink,
		setCurrentParentLink,
	} = useAppRouterContext();

	const handleParentLink = () => {
		console.log("menuItem", menuItem.parentLink);
		setCurrentParentLink(menuItem.parentLink);
		menuItem.children?.length
			? setCurrentChildLink(menuItem.children[0].childLink)
			: setCurrentChildLink("");
	};

	// console.log("currentParentLink--", currentParentLink);

	const checkPath = useMemo(() => {
		return currentParentLink === menuItem.parentLink;
	}, [currentParentLink, menuItem.parentLink]);

	const activeChildLink = useMemo(
		() => (url: string) => {
			return url === currentChildLink ? "active" : "";
		},
		[currentChildLink],
	);

	const handleChildLink = (childLink: string) => {
		setCurrentChildLink(childLink);
		setCurrentParentLink(menuItem.parentLink);
	};

	// console.log("currentParentLink", currentParentLink);
	// console.log("menuItem.parentLink", menuItem.parentLink);
	// console.log("checkPath", checkPath);
	// console.log("=================================");
	// console.log("=================================");

	return (
		<li className="sidebar-item" key={menuItem.name}>
			<Link
				onClick={() => handleParentLink()}
				className={`sidebar-link ${checkPath ? "active" : ""}`}
				to={menuItem.url}
			>
				<span>
					<i className={`ti ti-${menuItem.icon}`} />
				</span>
				<span className="hide-menu">{t(menuItem.name)}</span>
			</Link>
			{menuItem.children && menuItem.children.length ? (
				<ul className="collapse first-level in">
					{menuItem.children.map((childItem) => {
						if (childItem.isShow === false) return;
						return (
							<li key={childItem.name} className="mini-sidebar-item text-break">
								<Link
									onClick={() => handleChildLink(childItem.childLink)}
									to={childItem.url}
									className={`sidebar-link mini-sidebar-link ${activeChildLink(childItem.childLink)}`}
								>
									<div className="round-16 d-flex align-items-center justify-content-center">
										<i className="dots"></i>
									</div>
									<span className="mini-sidebar-text">{t(childItem.name)}</span>
								</Link>
							</li>
						);
					})}
				</ul>
			) : null}
		</li>
	);
};

const SideBar: React.FC = () => {
	const { t } = useLocale();

	return (
		<div className="d-flex">
			<div
				className="d-flex flex-column flex-shrink-0 "
				style={{
					padding: 24,
				}}
				id="sidebar"
			>
				<Link
					to="/"
					className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none"
				>
					<img src={Logo} alt="logo" />
				</Link>
				<hr style={{ margin: "24px 12px 0px 12px" }} />
				<nav className="sidebar-nav">
					<ul className="nav nav-pills flex-column mb-auto">
						<li className="nav-small-cap">
							<span className="hide-menu">{t("SERVICE_MANAGEMENT")}</span>
						</li>

						{NAV_LIST.map((menuItem) => (
							<NavItem key={menuItem.name} menuItem={menuItem} />
						))}
					</ul>
				</nav>
			</div>
			{/* <div className="page-wrapper p-4" id="page">
				hiii
				<LanguageSettings />
			</div> */}
		</div>
	);
};

export default memo(SideBar);
