import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
	IError,
	IProductPostTransitConfig,
	IProductPostTransitItem,
} from "../../common/define";
import { addAsyncReducers } from "../store";
import { GET_LIST_PRODUCT_POST_TRANSIT } from "./actions";
import { GET_PRODUCT_ACTUAL_LOSS_DETAIL } from "../productActualLoss/actions";

export interface IProductPostTransit {
	data: {
		content: IProductPostTransitItem[];
		pageNumber: number;
		pageSize: number;
		totalElements: number;
		totalPages: number;
	};
	currentPage: number;
	keyword: string;
	countryId: string;
	postTransitConfigs: IProductPostTransitConfig[];
	postTransitConfigsFormData: IProductPostTransitConfig[];
}

export interface IAuth {
	access_token: string;
	access_token_expires_dttm: string;
	refresh_token: string;
	refresh_token_expires_dttm: string;
}

interface IOtherState {
	isLoading: boolean;
}

const initialState: IProductPostTransit & IOtherState & IError = {
	data: {
		content: [],
		pageNumber: 0,
		pageSize: 0,
		totalElements: 0,
		totalPages: 0,
	},
	currentPage: 1,
	keyword: "",
	countryId: "",
	result_code: "",
	result_msg: "",
	code: "",
	isLoading: false,
	postTransitConfigs: [],
	postTransitConfigsFormData: [],
};

const listProductPostTransitSlice = createSlice({
	name: "listProductPostTransitSlice",
	initialState,
	reducers: {
		updateData: (state, action: PayloadAction<any>) => {
			return {
				...state,
				data: action.payload,
			};
		},
		setErrorCode: (state, action: PayloadAction<string>) => {
			state.code = action.payload;
			return state;
		},
		updateKeyword: (state, action: PayloadAction<string>) => {
			return {
				...state,
				keyword: action.payload,
			};
		},
		updateCountryId: (state, action: PayloadAction<string>) => {
			return {
				...state,
				countryId: action.payload,
			};
		},
		updateCurrentPage: (state, action: PayloadAction<number>) => {
			return {
				...state,
				currentPage: action.payload,
			};
		},
		updatePostTransitConfig: (state, action: PayloadAction<any>) => {
			return {
				...state,
				postTransitConfigs: action.payload,
				postTransitConfigsFormData: action.payload,
			};
		},
		updatePostTransitConfigFormData: (state, action: PayloadAction<any>) => {
			return {
				...state,
				postTransitConfigsFormData: action.payload,
			};
		},
		reset: () => initialState,
	},
	extraReducers: (builder) => {
		addAsyncReducers(builder, GET_PRODUCT_ACTUAL_LOSS_DETAIL, initialState);
	},
});

export const listProductPostTransitActions =
	listProductPostTransitSlice.actions;
export const listProductPostTransitReducers =
	listProductPostTransitSlice.reducer;
