import React from "react";
import { useLocale } from "../../hooks/useLocale";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";

const ProductInfoActualOnlyView: React.FC = () => {
	const { t } = useLocale();

	const actualLossInsurance = useSelector(
		(state: RootState) => state.productDetailReducers.data?.actualLossInsurance,
	);

	return (
		<div className="card-body text-dark border-top">
			<div className="row mb-2">
				<div className="fs-6 col-4 justify-content-center align-self-center fw-bold">
					{t("ACTUAL_LOSS_INSURANCE")}
				</div>
			</div>

			{/* <div className="row mb-4">
				<div className="col-4 justify-content-center align-self-center fw-bolder">
					{t("ACTUAL_LOSS_CASE_NAME")}
				</div>
				<div className="col-8 justify-content-center align-self-center">
					<select
						defaultValue={"123"}
						className="form-select"
						id="exampleFormControlSelect1"
						onSelect={(e) => {
							console.log(e.target);
						}}
						onChange={(e) => {
							const selected = productFeatures?.find(
								(feature) => feature.name === e.target.value,
							);
							setCurrentSelect(selected);
						}}
					>
						<option selected value={undefined}>
							{t("PLEASE_SELECT_A_CASE")}
						</option>
						{productFeatures && productFeatures?.length > 0
							? productFeatures.map((feature, index) => (
									<option key={index} value={feature.name}>
										{feature.name}
									</option>
								))
							: null}
					</select>
				</div>
			</div> */}
			<div className="row mb-4">
				<div className="col-4 justify-content-center align-self-center fw-bolder">
					{t("ACTUAL_LOSS_CASE_NAME")}
				</div>
				<div className="col-8 justify-content-center align-self-center">
					<input
						type="text"
						className="form-control"
						placeholder={`${t("PLEASE_SELECT_A_CASE")}`}
						aria-label="Recipient's username"
						aria-describedby="basic-addon2"
						value={actualLossInsurance?.name}
						disabled
					/>
				</div>
			</div>
			<div className="row mb-2">
				<div className="col-4 justify-content-center align-self-center fw-bolder">
					{t("ACTUAL_LOSS_CASE_NUMBER")}
				</div>
				<div className="col-8 justify-content-center align-self-center">
					<input
						type="text"
						className="form-control"
						placeholder={`${t("PLEASE_SELECT_A_CASE")}`}
						aria-label="Recipient's username"
						aria-describedby="basic-addon2"
						value={actualLossInsurance?.code}
						disabled
					/>
				</div>
			</div>
		</div>
	);
};

export default ProductInfoActualOnlyView;
