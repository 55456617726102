import { useLocale } from "../../hooks/useLocale";
import SelectCheckBox from "../SelectCheckBox";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";

const ProductRewardInfoOnlyView = () => {
	const { t } = useLocale();

	const productDetail = useSelector(
		(state: RootState) => state?.productDetailReducers?.data?.productFeatures,
	);

	return (
		<>
			<div className="card-header">
				<h4 className="mb-0">{t("REWARD_INFORMATION")}</h4>
			</div>
			<div className="card-body text-dark">
				<div className="row mb-1">
					<div className="fs-4 d-flex justify-content-between align-items-center align-self-center fw-bolder">
						<p className="mb-3">{t("OVERSEAS_MEDICAL_SUPPORT_SERVICE")}</p>
					</div>
				</div>
				{productDetail?.length
					? productDetail.map((item) => (
							<div key={item.code} className="row mb-4">
								<div className="col-4 justify-content-center align-self-center fw-bolder">
									{t(item.name)}
								</div>
								<div className="col-5 justify-content-center align-self-center">
									{/* {item.isChecked === true ? "YES" : "NO"} */}
									<SelectCheckBox
										disabled={true}
										selectOnlyOne
										dataChecked={item.isChecked === true ? "YES" : "NO"}
										classColor="secondary"
										// handleOnChange={handleSelectStatus}
										data={["YES", "NO"]}
									/>
								</div>
							</div>
						))
					: null}
			</div>
		</>
	);
};

export default ProductRewardInfoOnlyView;
