import React, { useEffect, useState } from "react";
import { useLocale } from "../../../hooks/useLocale";
import WrapperPage from "../../../components/WrapperPage";
import HeaderMini from "../../../components/HeaderMini";
import {
	CHILD_LINK,
	IProductPostTransitConfig,
	PARENT_LINK,
} from "../../../common/define";

import RewardPostTransit from "../../../components/RewardPostTransit";
import InsuranceInformationPostTransit from "../../../components/InsuranceInformationPostTransit";
import useActiveCurrentPage from "../../../hooks/useActiveCurrentPage";
import { useSelector } from "react-redux";
import { RootState, globalStore } from "../../../state/store";
import useAddNewPostTransit from "./useAddNewPostTransit";
import { productPostTransitDetailActions } from "../../../state/productPostTransit/productPostTransitSlice";
import { useNavigate } from "react-router-dom";

const AddNewPostTransitPage: React.FC = () => {
	const { t } = useLocale();

	const navigate = useNavigate();
	const {
		handleCreateProduct,
		caseName,
		error,
		handleChangeCaseName,
		onUpload,
		hasNotUploadFile,
	} = useAddNewPostTransit();

	useActiveCurrentPage(CHILD_LINK.POST_TRANSIT_PRODUCT, PARENT_LINK.PRODUCT);

	return (
		<WrapperPage className="container-fluid py-3">
			<HeaderMini
				title={`${t("ADD_POST_TRANSIT_INSURANCE")}`}
				breadcrumbs={[t("PRODUCT_MANAGEMENT"), t("POST_TRANSIT_INSURANCE")]}
			/>

			<InsuranceInformationPostTransit
				name={caseName}
				file=""
				hasNotUploadFile={hasNotUploadFile}
				onChange={handleChangeCaseName}
				onUpload={onUpload}
				errorName={error.caseName}
			/>
			<div className="card">
				<RewardPostTransit />
			</div>

			<div className="p-3">
				<div>
					<div className="text-end">
						<button
							onClick={() => navigate(-1)}
							className="btn bg-danger-subtle text-danger ms-6 rounded-pill me-4"
						>
							{t("CANCEL")}
						</button>
						<button
							onClick={handleCreateProduct}
							type="submit"
							className="btn btn-success rounded-pill px-4"
						>
							{t("SAVE")}
						</button>
					</div>
				</div>
			</div>
		</WrapperPage>
	);
};

export default AddNewPostTransitPage;
