import React from "react";
import { useLocale } from "../../hooks/useLocale";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { NOT_AVAILABLE } from "../../common/define";

const ProductInfoPostTransitOnlyView: React.FC = () => {
	const { t } = useLocale();

	const postTransitInsurance = useSelector(
		(state: RootState) =>
			state.productDetailReducers.data?.postTransitInsurance,
	);

	return (
		<div className="card-body text-dark border-top">
			<div className="row mb-2">
				<div className="fs-6 col-4 justify-content-center align-self-center fw-bold">
					{t("POST_TRANSIT_INSURANCE")}
				</div>
			</div>

			<div className="row mb-4">
				<div className="col-4 justify-content-center align-self-center fw-bolder">
					{t("POST_TRANSIT_CASE_NAME")}
				</div>
				<div className="col-8 justify-content-center align-self-center">
					<input
						type="text"
						className="form-control"
						placeholder={`${t("PLEASE_SELECT_A_CASE")}`}
						aria-label="Recipient's username"
						aria-describedby="basic-addon2"
						value={postTransitInsurance?.name || NOT_AVAILABLE}
						disabled
					/>
				</div>
			</div>

			<div className="row mb-2">
				<div className="col-4 justify-content-center align-self-center fw-bolder">
					{t("POST_TRANSIT_CASE_NUMBER")}
				</div>
				<div className="col-8 justify-content-center align-self-center">
					<input
						type="text"
						className="form-control"
						placeholder={`${t("PLEASE_SELECT_A_CASE")}`}
						aria-label="Recipient's username"
						aria-describedby="basic-addon2"
						value={postTransitInsurance?.code || NOT_AVAILABLE}
						disabled
					/>
				</div>
			</div>
		</div>
	);
};

export default ProductInfoPostTransitOnlyView;
