import React, { memo, useCallback } from "react";
import { useLocale } from "../../hooks/useLocale";
import { useNavigate, useParams } from "react-router-dom";
import { globalStore, RootState } from "../../state/store";
import { GET_CUSTOMER_INFO } from "../../state/customerInfo/actions";
import { UnknownAction } from "@reduxjs/toolkit";
import useEffectOnce from "../../hooks/useEffectOnce";
import { useSelector } from "react-redux";

const CustomerInformation: React.FC = () => {
	const { t } = useLocale();
	const { id } = useParams();
	const customerInfoStore = useSelector(
		(state: RootState) => state.customerInfoReducers.data,
	);
	const navigate = useNavigate();

	const gotoMemberDetail = () => {
		if (customerInfoStore?.member.id) {
			navigate(`/member/${customerInfoStore?.member.id}`);
		}
	};

	const ButtonMemberDetail = () => {
		return (
			<div className="d-flex gap-4 align-items-center">
				<span>Y</span>{" "}
				<button onClick={gotoMemberDetail} className="btn btn-success">
					{t("GO_TO_DETAIL")}
				</button>
			</div>
		);
	};

	const initCustomerInfo = useCallback(async () => {
		id &&
			(await globalStore.dispatch(
				GET_CUSTOMER_INFO({ id: Number(id) }) as unknown as UnknownAction,
			));
	}, []);

	useEffectOnce(initCustomerInfo);

	return (
		<div className="col-md-5">
			<div className="card">
				<div className="card-header d-flex justify-content-between align-items-center">
					<h4 className="mb-0">{t("CUSTOMER_INFORMATION")}</h4>
					{/* <div className="form-group text-end">
						<button type="button" className="btn btn-success">
							{t("EDIT")}
						</button>
					</div> */}
				</div>
				<div className="card-body text-dark">
					<div className="row mb-4">
						<div className="col-6 justify-content-center align-self-center fw-bolder">
							{t("INSURANCE_CUSTOMER_NUMBER")}
						</div>
						<div className="col-6 justify-content-center align-self-center">
							{customerInfoStore?.code}
						</div>
					</div>
					<div className="row mb-4">
						<div className="col-6 justify-content-center align-self-center fw-bolder">
							{t("MEMBER_STATUS")}
						</div>
						<div className="col-6 justify-content-center align-self-center">
							{customerInfoStore?.isMember ? <ButtonMemberDetail /> : "N"}
						</div>
					</div>
					{customerInfoStore?.isMember && (
						<div className="row mb-4">
							<div className="col-6 justify-content-center align-self-center fw-bolder">
								{t("MEMBER_NUMBER")}
							</div>
							<div className="col-6 justify-content-center align-self-center">
								{customerInfoStore?.member?.code}
							</div>
						</div>
					)}
					{/* <div className="row mb-4">
						<div className="col-6 justify-content-center align-self-center fw-bolder">
							{t("MEMBER_CLASS")}
						</div>
						<div className="col-6 justify-content-center align-self-center">
							APP
						</div>
					</div> */}
					<div className="row mb-4">
						<div className="col-6 justify-content-center align-self-center fw-bolder">
							{t("MEMBER_REGISTRATION_DATE")}
						</div>
						<div className="col-6 justify-content-center align-self-center">
							{customerInfoStore?.registrationDate}
						</div>
					</div>
					<div className="row mb-4">
						<div className="col-6 justify-content-center align-self-center fw-bolder">
							{t("NAME")} ({t("KOREAN")})
						</div>
						<div className="col-6 justify-content-center align-self-center">
							{customerInfoStore?.fullName}
						</div>
					</div>
					<div className="row mb-4">
						<div className="col-6 justify-content-center align-self-center fw-bolder">
							{t("NAME")} ({t("ENGLISH")})
						</div>

						<span className="col-3">{`${customerInfoStore?.firstNameEn.toUpperCase()}`}</span>
						<span className="col-3">{`${customerInfoStore?.lastNameEn.toUpperCase()}`}</span>
					</div>
					<div className="row mb-4">
						<div className="col-6 justify-content-center align-self-center fw-bolder">
							{t("ID_CARD_NUMBER")}
						</div>
						<div className="col-6 justify-content-center align-self-center">
							{customerInfoStore?.idCard}
						</div>
					</div>
					<div className="row mb-4">
						<div className="col-6 justify-content-center align-self-center fw-bolder">
							{t("CONTACT")}
						</div>
						<div className="col-6 justify-content-center align-self-center">
							{customerInfoStore?.phone}
						</div>
					</div>
					<div className="row mb-4">
						<div className="col-6 justify-content-center align-self-center fw-bolder">
							{t("EMAIL")}
						</div>
						<div className="col-6 justify-content-center align-self-center">
							{customerInfoStore?.email}
						</div>
					</div>
					<div className="row mb-4">
						<div className="col-6 justify-content-center align-self-center fw-bolder">
							{t("COUNTRY_OF_RESIDENCE")}
						</div>
						<div className="col-6 justify-content-center align-self-center">
							{t(`${customerInfoStore?.country?.nameEn.toUpperCase()}`)}
						</div>
					</div>
					{/* <div className="row">
						<button type="button" className="btn btn-outline-primary">
							{t("EDIT")}
						</button>
					</div> */}
				</div>
			</div>
		</div>
	);
};

export default memo(CustomerInformation);
