import React, { memo } from "react";
import { useLocale } from "../../hooks/useLocale";
import { useAppModalContext } from "../../contexts/ModalContext";
import ModalEditCustomerNotes from "../../components/Modals/ModalEditCustomerNotes";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { NOT_AVAILABLE } from "../../common/define";
import { formatISOToString, formatMoney } from "../../common/utils";

interface INoteItem {
	note: { content: string; createdAt: string };
}

const NoteItem: React.FC<INoteItem> = ({ note }) => {
	const { openModal } = useAppModalContext();
	const { t } = useLocale();

	const open = () => {
		openModal &&
			openModal?.({
				title: "",
				style: {
					zIndex: 9,
					top: "0",
					background: "transparent",
				},
				body: <ModalEditCustomerNotes t={t} />,
			});
	};

	return (
		<div
			onClick={open}
			role="button"
			className="col-md-4 single-note-item note-business mb-3"
		>
			<div className="card card-body text-dark">
				<span className="side-stick"></span>
				<p className="fw-bolder fs-2">{note.createdAt}</p>
				<div className="note-content">{note.content}</div>
			</div>
		</div>
	);
};

const PaymentInformation: React.FC = () => {
	const { t } = useLocale();

	const payment = useSelector(
		(state: RootState) => state.contractInfoReducers.data?.payment,
	);

	return (
		<div id="customer-notes" className="col-md-7">
			<div className="card">
				<div className="card-header">
					<h4 className="mb-0">{t("PAYMENT_INFORMATION")}</h4>
				</div>
				<div className="card-body text-dark">
					<div className="row mb-4">
						<div className="col-6 justify-content-center align-self-center fw-bolder">
							{t("PAYMENT_METHOD")}
						</div>
						<div className="col-6 justify-content-center align-self-center">
							{payment?.payMethod || NOT_AVAILABLE}
						</div>
					</div>
					<div className="row mb-4">
						<div className="col-6 justify-content-center align-self-center fw-bolder">
							{t("PAYMENT_DATE_AND_TIME")}
						</div>
						<div className="col-6 justify-content-center align-self-center">
							{payment?.createdDate
								? `${formatISOToString(payment?.createdDate).date} ${formatISOToString(payment?.createdDate).time}`
								: NOT_AVAILABLE}
						</div>
					</div>
					<div className="row mb-4">
						<div className="col-6 justify-content-center align-self-center fw-bolder">
							{t("PAYMENT_AMOUNT")}
						</div>
						<div className="col-6 justify-content-center align-self-center">
							{payment?.price
								? formatMoney(Number(payment?.price))
								: NOT_AVAILABLE}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default memo(PaymentInformation);
