import React from "react";
import { useLocale } from "../../../hooks/useLocale";
import WrapperPage from "../../../components/WrapperPage";
import HeaderMini from "../../../components/HeaderMini";
import InsuranceInformation from "../../../components/InsuranceInformation";
import { RootState } from "../../../state/store";
import { useSelector } from "react-redux";
import AddNewRewardInformationActualLoss from "../../../components/AddNewRewardInformationActualLoss";
import useAddNewActualLoss from "./useAddNewActualLoss";

const AddNewActualLossPage: React.FC = () => {
	const { t } = useLocale();

	const {
		caseName,
		error,
		handleChangeCaseName,
		handleGetData,
		handleCreateProduct,
		onUpload,
		hasNotUploadFile,
	} = useAddNewActualLoss();

	const config = useSelector(
		(state: RootState) => state.configActualLossReducers.data,
	);
	const isLoading = useSelector(
		(state: RootState) => state.configActualLossReducers.isLoading,
	);

	return (
		<WrapperPage isLoading={isLoading} className="container-fluid py-3">
			<HeaderMini
				title={`${t("ADD_ACTUAL_LOSS_INSURANCE")}`}
				breadcrumbs={[t("PRODUCT_MANAGEMENT"), t("ACTUAL_LOSS_INSURANCE")]}
			/>

			<InsuranceInformation
				hasNotUploadFile={hasNotUploadFile}
				name={caseName}
				errorName={error.caseName}
				onChange={handleChangeCaseName}
				onUpload={onUpload}
			/>

			{config && (
				<div className="card">
					<AddNewRewardInformationActualLoss
						data={config}
						onGetData={handleGetData}
					/>
				</div>
			)}

			<div className="p-3">
				<div>
					<div className="text-end">
						<button
							type="submit"
							className="btn bg-danger-subtle text-danger ms-6 rounded-pill me-4"
						>
							{t("CANCEL")}
						</button>
						<button
							type="submit"
							onClick={handleCreateProduct}
							className="btn btn-success rounded-pill px-4"
						>
							{t("SAVE")}
						</button>
					</div>
				</div>
			</div>
		</WrapperPage>
	);
};

export default AddNewActualLossPage;
