import React, { memo, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocale } from "../../../hooks/useLocale";
import TableList from "../../../components/TableList";
import { globalStore, RootState } from "../../../state/store";
import { useSelector } from "react-redux";
import { listProductActualLossActions } from "../../../state/listProductActualLoss/listProductActualLossSlice";
import { UnknownAction } from "@reduxjs/toolkit";
import { GET_LIST_PRODUCT_ACTUAL_LOSS } from "../../../state/listProductActualLoss/actions";
import useEffectOnce from "../../../hooks/useEffectOnce";
import { NOT_AVAILABLE } from "../../../common/define";
import { getFileNameWithURL } from "../../../common/utils";
import Table from "../../../components/Table";

const ActualLossList: React.FC = () => {
	const { t } = useLocale();
	const navigate = useNavigate();

	const listProductActualLossStore = useSelector(
		(state: RootState) => state.listProductActualLossReducers.data.content,
	);

	const totalPagesStore = useSelector(
		(state: RootState) => state.listProductActualLossReducers.data.totalPages,
	);
	const totalElements = useSelector(
		(state: RootState) =>
			state.listProductActualLossReducers.data.totalElements,
	);
	const currentPageStore = useSelector(
		(state: RootState) => state.listProductActualLossReducers.currentPage,
	);

	const goToDetail = (idx: number) => {
		navigate(`/product/actual/edit/${idx}`);
	};

	const handleChangePage = async (idx: number) => {
		await globalStore.dispatch(
			listProductActualLossActions.updateCurrentPage(
				idx,
			) as unknown as UnknownAction,
		);

		await globalStore.dispatch(
			GET_LIST_PRODUCT_ACTUAL_LOSS({
				isSearch: true,
				pageSize: 10,
				pageNumber: idx,
			}) as unknown as UnknownAction,
		);
	};

	const initData = useCallback(async () => {
		await globalStore.dispatch(
			GET_LIST_PRODUCT_ACTUAL_LOSS({
				pageSize: 10,
				pageNumber: currentPageStore,
			}) as unknown as UnknownAction,
		);
	}, [currentPageStore]);

	useEffectOnce(initData);

	const thead = [t("CASE_NO"), t("CASE_NAME"), t("FILE")];

	const tbody = useMemo(() => {
		return listProductActualLossStore?.map((item, idx) => (
			<tr role="button" key={"item" + idx + item.id}>
				<td className="text-center" onClick={() => goToDetail(item.id)}>
					{item.code}
				</td>
				<td className="text-center" onClick={() => goToDetail(item.id)}>
					{item.name}
				</td>
				<td className="text-center">
					<a href={item.feeFile} className="text-decoration-underline">
						{getFileNameWithURL(item.feeFile)}
					</a>
				</td>
			</tr>
		));
	}, [listProductActualLossStore]);

	return (
		<Table
			emptyTitle={t("EMPTY_ACTUAL")}
			title={`${t("TOTAL")} (${totalElements} ${t("CASES")})`}
			thead={thead}
			tbody={tbody}
			countPage={totalPagesStore}
			currentPage={currentPageStore}
			handleChangePage={handleChangePage}
			total={listProductActualLossStore.length}
		/>
	);
};

export default memo(ActualLossList);
