import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IError, IMember } from "../../common/define";
import { addAsyncReducers } from "../store";
import { GET_CUSTOMER_INFO } from "./actions";

interface ICustomer {
	code: number;
	id: number;
	fullName: string;
	fullNameEn: string;
	firstNameEn: string;
	lastNameEn: string;
	idCard: string;
	email: string;
	isMember: boolean;
	registrationDate: string;
	phone: string;
	member: IMember;
	members: IMember[];
	country?: {
		id: number;
		name: string;
		nameEn: string;
		code: string;
	};
}

export interface IListCustomer {
	data: ICustomer | null;
	currentPage: number;
	keyword: string;
	countryId: string;
}

export interface IAuth {
	access_token: string;
	access_token_expires_dttm: string;
	refresh_token: string;
	refresh_token_expires_dttm: string;
}

interface IOtherState {
	isLoading: boolean;
}

const initialState: IListCustomer & IOtherState & IError = {
	data: null,
	currentPage: 1,
	keyword: "",
	countryId: "",
	result_code: "",
	result_msg: "",
	code: "",
	isLoading: false,
};

const customerInfoSlice = createSlice({
	name: "customerInfoSlice",
	initialState,
	reducers: {
		updateCustomerInfo: (state, action: PayloadAction<ICustomer>) => {
			return {
				...state,
				data: action.payload,
			};
		},
		setErrorCode: (state, action: PayloadAction<string>) => {
			state.code = action.payload;
			return state;
		},
		reset: () => initialState,
	},
	extraReducers: (builder) => {
		addAsyncReducers(builder, GET_CUSTOMER_INFO, initialState);
	},
});

export const customerInfoActions = customerInfoSlice.actions;
export const customerInfoReducers = customerInfoSlice.reducer;

// export const authSelectors = {
// 	isLoading: (state: RootState) => state.authReducers.isLoading,
// 	code: (state: RootState) => state.authReducers.code,
// };
