import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IProductDetailInfo } from "../../common/define";
import { addAsyncReducers } from "../store";
import { GET_LIST_PRODUCT, GET_PRODUCT_DETAIL } from "./actions";

// interface IStateData {
// 	data: IProductDetailInfo | undefined;
// }

interface IItemsAddNewProduct {
	id?: number;
	code: string;
	name: string;
	isChecked: boolean | null;
}

export interface IProductAddNewSchema {
	name: string;
	minAge: number;
	maxAge: number;
	items: IItemsAddNewProduct[];
	actualLossInsuranceId: number;
	postTransitInsuranceId: number;
	status: string;
}

export interface IState {
	data: IProductAddNewSchema;
}

interface IOtherState {
	code: string;
	isLoading: boolean;
	fileReadyUpload: any;
}

const initialState: IState & IOtherState = {
	data: {
		name: "",
		minAge: 0,
		maxAge: 0,
		items: [],
		actualLossInsuranceId: 0,
		postTransitInsuranceId: 0,
		status: "",
	},
	fileReadyUpload: undefined,
	code: "",
	isLoading: false,
};

const addNewProductSlice = createSlice({
	name: "addNewProductSlice",
	initialState,
	reducers: {
		updateData: (state, action: PayloadAction<IProductAddNewSchema>) => {
			state.data = action.payload;
		},
		updateStatus: (state, action: PayloadAction<string>) => {
			state.data.status = action.payload;
		},
		updateName: (state, action: PayloadAction<string>) => {
			state.data.name = action.payload;
		},
		updateMinMaxAge: (
			state,
			action: PayloadAction<{
				field: "maxAge" | "minAge";
				value: any;
			}>,
		) => {
			state.data[action.payload.field] = action.payload.value;
		},
		updateActualLoss: (state, action: PayloadAction<number>) => {
			state.data.actualLossInsuranceId = action.payload;
		},
		updateTransit: (state, action: PayloadAction<number>) => {
			state.data.postTransitInsuranceId = action.payload;
		},
		updateItem: (state, action: PayloadAction<IItemsAddNewProduct[]>) => {
			state.data.items = action.payload.map((item) => ({ ...item }));
		},
		updateFileReadyUpload: (state, action: PayloadAction<any>) => {
			state.fileReadyUpload = action.payload;
		},
		setErrorCode: (state, action: PayloadAction<string>) => {
			state.code = action.payload;
			return state;
		},
		reset: () => initialState,
	},
	extraReducers: (builder) => {
		addAsyncReducers(builder, GET_PRODUCT_DETAIL, initialState);
	},
});

export const addNewProductActions = addNewProductSlice.actions;
export const addNewProductReducers = addNewProductSlice.reducer;

// export const authSelectors = {
// 	isLoading: (state: RootState) => state.authReducers.isLoading,
// 	code: (state: RootState) => state.authReducers.code,
// };
