import React from "react";
import WrapperPage from "../../../components/WrapperPage";

const ContractReapplicationPage: React.FC = () => {
	return (
		<WrapperPage>
			<h2>ContractReapplicationPage View</h2>
		</WrapperPage>
	);
};

export default ContractReapplicationPage;
