import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { apiRequest } from "../../common/api.request";
import { TYPE } from "../../common/define";
import { queryApi, uploadFileAPI } from "../../common/queryAPI";
import { globalStore } from "../store";
import { productPostTransitDetailActions } from "./productPostTransitSlice";

export const GET_PRODUCT_POST_TRANSIT_DETAIL = createAsyncThunk(
	"productPostTransitSlice/GET_PRODUCT_POST_TRANSIT_DETAIL",
	async (data?: { id: number }) => {
		try {
			const { id } = data || {};

			const res = await queryApi(apiRequest.adminGetProductInfo, {}, { id });
			if (res.type === TYPE.SUCCESS && res.data) {
				globalStore.dispatch(
					productPostTransitDetailActions.updateProductPostTransitDetail(
						res.data,
					),
				);
			}

			if (res.type === TYPE.ERROR) {
				globalStore.dispatch(
					productPostTransitDetailActions.setErrorCode(res.messages[0].code),
				);
			}
		} catch (e) {
			console.log("error", e);
		}
	},
);

export const UPLOAD_FILE_PRODUCT = createAsyncThunk(
	"productPostTransitSlice/UPLOAD_FILE_PRODUCT",
	async (data?: {
		productId: number;
		file: any;
		callbackSuccess?: (data?: any) => void;
		callbackFailed?: (data?: any) => void;
	}) => {
		try {
			console.log("call-UPLOAD_FILE_PRODUCT-UPLOAD_FILE_PRODUCT");
			const { productId, file, callbackSuccess, callbackFailed } = data || {};

			const res = await uploadFileAPI(file, Number(productId));
			console.log("res-upload", res);
			if (res.type === TYPE.SUCCESS && res.messages[0].code) {
				callbackSuccess && callbackSuccess(res.messages[0].code);
			}

			if (res.type === TYPE.ERROR) {
				callbackFailed && callbackFailed(res.messages[0].code);
				toast.error(res.messages[0].code);
				globalStore.dispatch(
					productPostTransitDetailActions.setErrorCode(res.messages[0].code),
				);
			}
		} catch (e) {
			console.log("error", e);
		}
	},
);

export const UPDATE_PRODUCT_POST_TRANSIT = createAsyncThunk(
	"configActualLossSlice/CREATE_PRODUCT_POST_TRANSIT",
	async (data: {
		name: any;
		productId: number;
		callbackSuccess?: (data?: any) => void;
		callbackFailed?: (data?: any) => void;
	}) => {
		try {
			const { name, productId, callbackSuccess, callbackFailed } = data;
			const res = await queryApi(apiRequest.adminUpdateProductPostTransit, {
				name,
				productId,
			});

			if (res.type === TYPE.SUCCESS && res.data) {
				console.log("res.data---=====---", res.data);
				callbackSuccess && callbackSuccess(res.messages[0].code);
			}

			if (res.type === TYPE.ERROR) {
				callbackFailed && callbackFailed(res.messages[0].code);
				globalStore.dispatch(
					productPostTransitDetailActions.setErrorCode(res.messages[0].code),
				);
			}
		} catch (e) {
			console.log("error", e);
		}
	},
);
