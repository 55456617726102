import React, { memo, useCallback } from "react";
import { useLocale } from "../../hooks/useLocale";
import { useAppModalContext } from "../../contexts/ModalContext";
import ModalEditCustomerNotes from "../../components/Modals/ModalEditCustomerNotes";
import { useParams } from "react-router-dom";
import { globalStore, RootState } from "../../state/store";
import { UnknownAction } from "@reduxjs/toolkit";
import { GET_LIST_CUSTOMER_NOTE } from "../../state/listCustomerNote/actions";
import useEffectOnce from "../../hooks/useEffectOnce";
import { useSelector } from "react-redux";
import { ICustomerNote } from "../../state/listCustomerNote/listCustomerNoteSlice";
import { formatISOToString } from "../../common/utils";
import ModalAddCustomerNote from "../../components/Modals/ModalAddCustomerNote";

const NoteItem = ({
	item,
	customerId,
}: {
	item: ICustomerNote;
	customerId?: string;
}) => {
	const { openModal } = useAppModalContext();
	const { t } = useLocale();

	const open = () => {
		openModal &&
			openModal?.({
				title: "",
				style: {
					zIndex: 9,
					top: "0",
					background: "transparent",
				},
				body: (
					<ModalEditCustomerNotes customerId={customerId} item={item} t={t} />
				),
			});
	};

	const timeFormat = formatISOToString(item.createdDate);

	return (
		<div
			onClick={open}
			role="button"
			className="col-md-4 single-note-item note-business mb-3"
		>
			<div className="card card-body text-dark">
				<span className="side-stick"></span>
				<p className="fw-bolder fs-2">
					{timeFormat.date} {timeFormat.time}
				</p>
				<div className="note-content">{item.note}</div>
			</div>
		</div>
	);
};

const Notes: React.FC = () => {
	const { openModal } = useAppModalContext();

	const { t } = useLocale();

	const { id } = useParams();

	const noteStore = useSelector(
		(state: RootState) => state.listCustomerNoteReducers.data.content,
	);

	const totalNotes = useSelector(
		(state: RootState) => state.listCustomerNoteReducers.data.totalElements,
	);

	const initCustomerInfo = useCallback(async () => {
		id &&
			(await globalStore.dispatch(
				GET_LIST_CUSTOMER_NOTE({ id: Number(id) }) as unknown as UnknownAction,
			));
	}, []);

	const openAddNote = () => {
		openModal &&
			openModal?.({
				title: "",
				style: {
					zIndex: 9,
					top: "0",
					background: "transparent",
				},
				body: <ModalAddCustomerNote customerId={id} t={t} />,
			});
	};

	useEffectOnce(initCustomerInfo);

	return (
		<div id="customer-notes" className="col-md-7">
			<div className="card">
				<div className="card-header">
					<h4 className="mb-0">{t("NOTES")}</h4>
				</div>
				<div className="card-body text-dark card-body--note">
					<div className="row mb-4">
						<div className="col">
							<button
								type="button"
								className="btn btn-rounded btn-outline-primary"
							>
								{t("NOTES")} ({totalNotes})
							</button>{" "}
							<button
								onClick={openAddNote}
								type="button"
								className="btn btn-rounded btn-outline-muted"
							>
								<i className="ti ti-clipboard fs-4 me-2"></i>
								{t("ADD_NOTE")}
							</button>
						</div>
					</div>
					<div className="row note-has-grid wrap-notes overflow-y-scroll">
						{noteStore.length ? (
							noteStore.map((note, idx) => (
								<NoteItem customerId={id} item={note} key={"notes" + idx} />
							))
						) : (
							<></>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default memo(Notes);
