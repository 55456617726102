import React, { useEffect, useState } from "react";
import { useLocale } from "../../hooks/useLocale";
import { IConfigActualLoss } from "../../common/define";

interface IProps {
	data?: IConfigActualLoss[];
	isDisabled?: boolean;
	onGetData?: (data: any) => void;
}

const AddNewRewardInformationActualLoss: React.FC<IProps> = ({
	data,
	isDisabled,
	onGetData,
}) => {
	const { t } = useLocale();

	const [state, setState] = useState(data);

	const handleInputChange = (code: string, newPrice: number) => {
		const newData =
			state &&
			state.map((item) => {
				if (item.code === code) {
					return { ...item, price: newPrice };
				}
				return item;
			});

		newData && setState(newData);
	};

	const handleOnGetData = () => {
		const formattedArray =
			state &&
			state.map((obj) => ({
				code: obj.code,
				name: obj.name,
				price: obj.price || 0,
			}));

		onGetData && onGetData(formattedArray);
	};

	useEffect(() => {
		handleOnGetData();
	}, []);

	return (
		<>
			<div className="card-header">
				<h4 className="mb-0">{t("REWARD_INFORMATION")}</h4>
			</div>
			<div className="card-body text-dark">
				{data &&
					data.map((item) => (
						<div className="row mb-4">
							<div className="col-3 justify-content-center align-self-center fw-bolder">
								{t(item.name)}
							</div>
							<div className="col-9 justify-content-center align-self-center position-relative">
								<input
									onBlur={handleOnGetData}
									disabled={isDisabled}
									type="number"
									onWheel={(e: any) => e.target.blur()}
									className="form-control"
									placeholder={`${t("PLEASE_ENTER_THE_AMOUNT")}`}
									aria-describedby="basic-addon2"
									onChange={(e) =>
										handleInputChange(item.code, Number(e.target.value))
									}
								/>
								<span className="man-amount">{t("MAN_WON")}</span>
							</div>
						</div>
					))}
			</div>
		</>
	);
};

export default AddNewRewardInformationActualLoss;
