import React, { memo } from "react";
import { useLocale } from "../../hooks/useLocale";

const InsuranceRejected: React.FC = () => {
	const { t } = useLocale();

	return (
		<div className="card-body text-dark ">
			<div className="row mb-4">
				<div className="fs-4 d-flex justify-content-between align-items-center align-self-center fw-bolder">
					<p className="mb-0">{t("MEMBER_CERTIFICATION")}</p>
				</div>
			</div>
			<div className="row mb-4">
				<div className="col-4 justify-content-center align-self-center fw-bolder">
					{t("CERTIFICATE_FILE")}
				</div>
				<div className="col-6 justify-content-center align-self-center">
					{t("APPLICATION_REJECTED")}
				</div>
			</div>
			<div className="row mb-4">
				<div className="col-4 justify-content-center align-self-center fw-bolder">
					{t("CERTIFICATE_NUMBER")}
				</div>
				<div className="col-6 justify-content-center align-self-center">
					{t("APPLICATION_REJECTED")}
				</div>
			</div>

			<div className="row mb-4">
				<div className="col-4 justify-content-center align-self-center fw-bolder">
					{t("INSURANCE_FORM_START_DATE")}
				</div>
				<div className="col-6 justify-content-center align-self-center">
					{t("APPLICATION_REJECTED")}
				</div>
			</div>

			<div className="row mb-4">
				<div className="col-4 justify-content-center align-self-center fw-bolder">
					{t("INSURANCE_FORM_END_DATE")}
				</div>
				<div className="col-6 justify-content-center align-self-center">
					{t("APPLICATION_REJECTED")}
				</div>
			</div>

			<div className="row ">
				<div className="col-4 justify-content-center align-self-center fw-bolder">
					{t("SHIPMENT_DETAIL")}
				</div>
				<div className="col-6 justify-content-center align-self-center">
					{t("APPLICATION_REJECTED")}
				</div>
			</div>
		</div>
	);
};

export default memo(InsuranceRejected);
