import { createAsyncThunk } from "@reduxjs/toolkit";
import { countryActions } from "./countrySlice";
import { queryApi } from "../../common/queryAPI";
import { apiRequest } from "../../common/api.request";
import { globalStore } from "../store";
import { TYPE } from "../../common/define";
import { toast } from "react-toastify";

export const GET_COUNTRY_ACTION = createAsyncThunk(
	"countrySlice/GET_COUNTRY_ACTION",
	async () => {
		try {
			const res = await queryApi(apiRequest.country);
			if (res.length) {
				globalStore.dispatch(countryActions.updateCountry(res));
			}
			return res;
		} catch (e) {
			console.log(e);
		}
	},
);

export const CREATE_COUNTRY_ACTION = createAsyncThunk(
	"countrySlice/CREATE_COUNTRY_ACTION",
	async (data: {
		name: string;
		nameEn: string;
		code: string;
		callback?: (data?: number | any) => void;
	}) => {
		try {
			const { name, nameEn, code, callback } = data;
			const res = await queryApi(apiRequest.adminCreateCountry, {
				name,
				nameEn,
				code,
			});

			if (res.type === TYPE.SUCCESS && res.data) {
				console.log("res.data---=====---", res.data);
				globalStore.dispatch(countryActions.setErrorCode(""));
				callback && callback(res.data.id);
			}

			if (res.type === TYPE.ERROR) {
				toast.error(res.messages[0].code);
				globalStore.dispatch(countryActions.setErrorCode(res.messages[0].code));
			}
		} catch (e) {
			console.log("error", e);
		}
	},
);
