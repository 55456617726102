import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ICountry } from "../../common/define";
import { RootState } from "../store";

export interface IUserInfo {
	id: number;
	code: string;
	phone: string;
	birthday: string;
	username: string;
	email: string;
	fullName: string;
	firstNameEn: string;
	lastNameEn: string;
	idCard: string;
	gender: string;
	role: string;
	country: ICountry | null;
	position: string;
}

interface IOtherState {
	isLoading: boolean;
}

const initialState: IUserInfo & IOtherState = {
	id: 0,
	code: "",
	phone: "",
	birthday: "",
	username: "",
	email: "",
	fullName: "",
	firstNameEn: "",
	lastNameEn: "",
	idCard: "",
	gender: "",
	role: "",
	country: null,
	isLoading: false,
	position: "",
};

const userInfoSlice = createSlice({
	name: "userInfoSlice",
	initialState,
	reducers: {
		updateUserInfo: (state, action: PayloadAction<any>) => {
			console.log("action.payload======", action.payload);
			return {
				...state,
				...action.payload,
			};
		},
		reset: (_state) => {
			return {
				...initialState,
			};
		},
	},
	// extraReducers: (builder) => {
	//   builder.addCase(LOGIN_ACTION.pending, (state) => {
	//     state.isLoading = true;
	//   }),
	//     builder.addCase(LOGIN_ACTION.fulfilled, (state) => {
	//       state.isLoading = false;
	//     }),
	//     builder.addCase(LOGIN_ACTION.rejected, (state) => {
	//       state = {
	//         ...initialState,
	//       };
	//       // window.open(getTwitterOauthUrl());
	//     });
	// },
});

export const userInfoActions = userInfoSlice.actions;
export const userInfoReducers = userInfoSlice.reducer;

export const userInfoSelectors = {
	isLoading: (state: RootState) => state.userInfoReducers.isLoading,
	fullName: (state: RootState) => state.userInfoReducers.fullName,
};
