// import { globalStore } from "@/state/store";
import axios from "axios";
import { apiRequest, IAPI } from "./api.request";
import { globalStore } from "../state/store";
import { CLEAR_ACTION, REFRESH_TOKEN } from "../state/auth/actions";
import { UnknownAction } from "@reduxjs/toolkit";
import { CONFIG, ERROR_CODE, TYPE } from "./define";
import { toast } from "react-toastify";
import { Navigate } from "react-router-dom";

const refreshAccessToken = async (refreshToken: string) => {
	try {
		const response = await axios.post(
			`${CONFIG.BASE_URL}/auth/refreshToken`,
			{ refreshToken },
			{
				headers: {
					"Content-Type": "application/json",
					accept: "*/*",
				},
			},
		);

		if (response.data.type === TYPE.ERROR) {
			window.location.replace("/sign-in");
		}

		return response.data;
	} catch (error) {
		console.error("Error refreshing access token:", error);
		throw error;
	}
};

const axiosInstance = axios.create({
	baseURL: `${CONFIG.BASE_URL}`,
	timeout: 10000,
});

axiosInstance.interceptors.request.use(
	async (config: any) => {
		let access_token_store = globalStore.getState().authReducers.access_token;
		const refresh_token_store =
			globalStore.getState().authReducers.refresh_token;
		console.log("access_token_store", access_token_store);
		const accessTokenT =
			globalStore.getState().authReducers.access_token_expires_dttm;

		const accessTokenTime = new Date(accessTokenT).getTime();
		const currentTime = new Date().getTime();

		const accessTokenExpired = currentTime >= accessTokenTime;

		if (accessTokenExpired) {
			const resData = await refreshAccessToken(refresh_token_store);
			console.log("resData---", resData);
			access_token_store = resData.access_token;

			if (resData.type === TYPE.ERROR) {
				window.location.replace("/sign-in");
				return;
			}

			if (resData.type === TYPE.SUCCESS) {
				globalStore.dispatch(
					REFRESH_TOKEN(resData.data) as unknown as UnknownAction,
				);
			}
		}
		const authenticated = config.authenticated;

		if (authenticated && access_token_store) {
			config.headers.Authorization = `Bearer ${access_token_store}`;
		}

		return config;
	},
	(error) => {
		console.error("Error in request interceptor:", error);
		return Promise.reject(error);
	},
);

axiosInstance.interceptors.response.use((response: any) => {
	if (response.data.type === TYPE.ERROR) {
		const errorCode = response.data.messages?.[0]?.code;

		if (errorCode === ERROR_CODE.ERR_ACCESS_DENIED) {
			globalStore.dispatch(CLEAR_ACTION() as unknown as UnknownAction);
		}
	}
	return response;
});

export const queryApi = async (
	schema: IAPI,
	body?: any,
	params?: any,
): Promise<any> => {
	const { uri, method, authenticated, useFullUri } = schema;

	try {
		const fullUri = useFullUri
			? uri
			: uri.replace(/{(\w+)}/g, (_match, param) => params[param]);

		const config = {
			url: fullUri,
			method,
			authenticated,
			data: body,
			params,
		};

		const response = await axiosInstance(config);

		return response.data;
	} catch (error: any) {
		console.error("API Error:", error);
		throw error;
	}
};

// for upload file
export const uploadFileAPI = async (file: any, productId: number) => {
	// Tạo một FormData object để chứa file
	const formData = new FormData();
	formData.append("file", file, file.name);
	formData.append("productId", productId.toString());

	try {
		const access_token = globalStore.getState().authReducers.access_token;
		const response = await axios.patch(
			`${CONFIG.BASE_URL}${apiRequest.adminUploadProductFeeFile.uri}`,
			formData,
			{
				headers: {
					"Content-Type": "multipart/form-data",
					Authorization: `Bearer ${access_token}`,
				},
			},
		);
		if (response.data.type === TYPE.ERROR) {
			console.log("alooo");
			toast.error(response.data.messages[0].message);
		}

		console.log("response-----response---response=+++response", response);

		// return response
		return response.data;
	} catch (error) {
		console.error("Error uploading file:", error);
	}
};

export const uploadCertificateFileAPI = async (file: any, formValues: any) => {
	const formData = new FormData();
	formData.append("file", file, file.name);
	formData.append("contractId", formValues.contractId.toString());
	formData.append("certificateNumber", formValues.certificateNumber.toString());
	formData.append(
		"insuranceStartDate",
		formValues.insuranceStartDate.toString(),
	);
	formData.append("insuranceEndDate", formValues.insuranceEndDate.toString());

	try {
		const access_token = globalStore.getState().authReducers.access_token;
		const response = await axios.patch(
			`${CONFIG.BASE_URL}${apiRequest.adminUploadContractCertificateFile.uri}`,
			formData,
			{
				headers: {
					"Content-Type": "multipart/form-data",
					Authorization: `Bearer ${access_token}`,
				},
			},
		);
		if (response.data.type === TYPE.ERROR) {
			console.log("alooo");
			toast.error(response.data.messages[0].message);
		}

		console.log("response-----response---response=+++response", response);

		// return response
		return response.data;
	} catch (error) {
		console.error("Error uploading file:", error);
	}
};
