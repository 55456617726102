import React from "react";
// import "react-datepicker/dist/react-datepicker.css";
import RouterApp from "./RouterApp";
import * as Sentry from "@sentry/react";

Sentry.init({
	dsn: "https://23c8d21827bbb27959f23bc1be441a46@o4507214020411392.ingest.de.sentry.io/4507214137065552",
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration(),
	],
	// Performance Monitoring
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ["kare-admin.blue-dragon-develop.com"],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const App: React.FC = () => {
	return <RouterApp />;
};

export default App;
