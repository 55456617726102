import React, { memo, useState } from "react";
import { TFunction } from "i18next";
import Swal from "sweetalert2";
import { UnknownAction } from "@reduxjs/toolkit";
import { useAppModalContext } from "../../../contexts/ModalContext";
import { globalStore } from "../../../state/store";
import { ADD_CUSTOMER_NOTE } from "../../../state/listCustomerNote/actions";

interface IPropsModal {
	t: TFunction<"translation", undefined>;
	customerId?: string;
}

const ModalAddCustomerNote: React.FC<IPropsModal> = ({ t, customerId }) => {
	const { closeModal } = useAppModalContext();

	const [inputValue, setInputValue] = useState("");

	const onSave = () => {
		if (!inputValue || !customerId) return;
		const swalWithBootstrapButtons = Swal.mixin({
			customClass: {
				confirmButton: "btn btn-secondary",
				cancelButton: "btn btn-light-indigo me-2",
			},
			confirmButtonColor: "red",
			buttonsStyling: false,
		});
		swalWithBootstrapButtons
			.fire({
				reverseButtons: true,
				text: t("ADD_NOTE_COMFIRM"),
				showCancelButton: true,
				confirmButtonColor: "red",
				cancelButtonColor: "#EAEFF4",
				confirmButtonText: t("ON_OK"),
				cancelButtonText: t("ON_CANCEL"),
			})
			.then(async (_result) => {
				if (_result.isConfirmed) {
					await globalStore.dispatch(
						ADD_CUSTOMER_NOTE({
							t: t,
							note: inputValue,
							customerId,
							callback: closeModal,
						}) as unknown as UnknownAction,
					);
				}
			});
	};

	const onChange = (e: any) => {
		const value = e.target.value;
		setInputValue(value);
	};

	return (
		<div
			className="bg-light p-4 rounded-2 max-w-1000 modal-dialog"
			role="document"
		>
			<div className="modal-content">
				<div className="modal-header d-flex align-items-center">
					<h4 className="modal-title mb-4" id="exampleModalLabel1">
						{t("INSURANCE_CUSTOMER_NOTES")}
					</h4>
				</div>

				<div className="modal-body">
					<form>
						<textarea
							onChange={onChange}
							value={inputValue}
							style={{
								border: "1px solid #dfe5ef",
							}}
							className="w-100 h-300 rounded-4 p-2"
						/>
					</form>
				</div>
				<div className="modal-footer mt-4">
					<button
						type="button"
						className="btn btn-light-indigo text-dark me-2 "
						onClick={closeModal}
					>
						{t("CANCEL")}
					</button>
					<button onClick={onSave} type="button" className="btn btn-success">
						{t("SAVE")}
					</button>
				</div>
			</div>
		</div>
	);
};

export default memo(ModalAddCustomerNote);
