import React, { memo } from "react";
import { useSelector } from "react-redux";
import { NOT_AVAILABLE, STATUS_TYPE } from "../../common/define";
import { formatMoney } from "../../common/utils";
import { useAppModalContext } from "../../contexts/ModalContext";
import { useLocale } from "../../hooks/useLocale";
import { RootState } from "../../state/store";
import InsuranceApproved from "./InsuranceApproved";
import InsuranceNonApproved from "./InsuranceNonApproved";
import InsuranceRejected from "./InsuranceRejected";

import TableTotalAmount from "./TableTotalAmount";
// import ModalViewDetail from "./ModalViewDetail";
// import ModalViewDetail from "./ModalViewDetail";

const InsuranceInfoRegistered: React.FC = () => {
	const { t } = useLocale();
	const { openModal } = useAppModalContext();

	const contractDetail = useSelector(
		(state: RootState) => state.contractInfoReducers.data,
	);

	const openViewDetail = () => {
		openModal &&
			openModal?.({
				title: "",
				style: {
					zIndex: 9,
					top: "0",
					background: "transparent",
				},
				body: <></>,
			});
	};
	return (
		<div className="">
			<div className="card">
				<div className="card-header d-flex justify-content-between align-items-center">
					<h4 className="mb-0">{t("INSURANCE_SUBSCRIBED")}</h4>
				</div>

				{contractDetail?.status === STATUS_TYPE.APPLICATION_APPROVED ||
				contractDetail?.status === STATUS_TYPE.CANCELLATION_REJECTED ? (
					<InsuranceApproved />
				) : contractDetail?.status === STATUS_TYPE.APPLICATION_REJECTED ? (
					<InsuranceRejected />
				) : (
					<InsuranceNonApproved />
				)}

				<div className="card-body text-dark">
					<div className="row mb-4">
						<div className="fs-4 col-6 justify-content-center align-self-center fw-bolder">
							{t("CONTRACT_INFORMATION")}
						</div>
					</div>
					<div className="row mb-4">
						<div className="col-4 justify-content-center align-self-center fw-bolder">
							{t("CONTRACT_NUMBER")}
						</div>
						<div className="col-6 justify-content-center align-self-center">
							{contractDetail?.code}
						</div>
					</div>
					<div className="row mb-4">
						<div className="col-4 justify-content-center align-self-center fw-bolder">
							{t("CONTRACT_TYPE")}
						</div>
						<div className="col-2 justify-content-center align-self-center">
							{t("GENERAL_CONTRACT")}
						</div>
					</div>
					<div className="row mb-4">
						<div className="col-4 justify-content-center align-self-center fw-bolder">
							{t("DATE_OF_APPLICATION")}
						</div>
						<div className="col-6 justify-content-center align-self-center">
							{contractDetail?.applyDate}
						</div>
					</div>
					<div className="row mb-4">
						<div className="col-4 justify-content-center align-self-center fw-bolder">
							{t("MEMBER_COUNTRY")}
						</div>
						<div className="col-6 justify-content-center align-self-center">
							{contractDetail?.country?.name}
						</div>
					</div>
					<div className="row mb-4">
						<div className="col-4 justify-content-center align-self-center fw-bolder">
							{t("REGISTRATION_PRODUCT")}
						</div>
						<div className="col-6 justify-content-center align-self-center">
							{contractDetail?.product?.name}
						</div>
					</div>
					<div className="row mb-4">
						<div className="col-4 justify-content-center align-self-center fw-bolder">
							{t("TOTAL_PAYMENT")}
						</div>
						<div className="col-6 justify-content-center align-self-center">
							{contractDetail?.finalAmount
								? formatMoney(contractDetail.finalAmount)
								: NOT_AVAILABLE}
						</div>
					</div>
					<div className="row mb-4">
						<div className="col-4 justify-content-center align-self-center fw-bolder">
							{t("INSURANCE_EXPECTED_START_DATE")}
						</div>
						<div className="col-6 justify-content-center align-self-center">
							{contractDetail?.expectedStartDate}
						</div>
					</div>

					<div className="row mb-4">
						<div className="col-4 justify-content-center align-self-center fw-bolder">
							{t("TOTAL_PAYMENT_AMOUNT")}
						</div>
						<div className="col-6 justify-content-center align-self-center">
							<TableTotalAmount />
						</div>
					</div>
					{/* <div className="row mb-4">
						<div className="col-4 justify-content-center align-self-center fw-bolder">
							{t("INSURANCE_START_DATE")}
						</div>
						<div className="col-6 justify-content-center align-self-center">
							{contractDetail?.insuranceStartDate || NOT_AVAILABLE}
						</div>
					</div>
					<div className="row mb-4">
						<div className="col-4 justify-content-center align-self-center fw-bolder">
							{t("INSURANCE_END_DATE")}
						</div>
						<div className="col-6 justify-content-center align-self-center">
							{contractDetail?.insuranceEndDate || NOT_AVAILABLE}
						</div>
					</div> */}
				</div>

				<div className="card-body text-dark">
					<div className="row mb-4">
						<div className="fs-4 col-6 justify-content-center align-self-center fw-bolder">
							{t("REGISTRANT_INFORMATION")}
						</div>
					</div>
					<div className="row mb-4">
						<div className="col-4 justify-content-center align-self-center fw-bolder">
							{t("MEMBER_NUMBER")}
						</div>
						<div className="col-6 justify-content-center align-self-center">
							{contractDetail?.member?.code}
						</div>
					</div>
					<div className="row mb-4">
						<div className="col-4 justify-content-center align-self-center fw-bolder">
							{t("NAME")}
						</div>
						<div className="col-2 justify-content-center align-self-center">
							{contractDetail?.member?.fullName}
						</div>
					</div>
					<div className="row mb-4">
						<div className="col-4 justify-content-center align-self-center fw-bolder">
							{t("CONTACT")}
						</div>
						<div className="col-6 justify-content-center align-self-center">
							{contractDetail?.member?.phone}
						</div>
					</div>
					<div className="row mb-4">
						<div className="col-4 justify-content-center align-self-center fw-bolder">
							{t("EMAIL")}
						</div>
						<div className="col-6 justify-content-center align-self-center">
							{contractDetail?.member?.email}
						</div>
					</div>
					<div className="row mb-4">
						<div className="col-4 justify-content-center align-self-center fw-bolder">
							{t("REGISTER_VIA")}
						</div>
						<div className="col-6 justify-content-center align-self-center">
							APP
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default memo(InsuranceInfoRegistered);
