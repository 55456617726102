import { memo, useRef, useState } from "react";
import KareLabel from "../KareLabel";

interface IKareInput {
	value?: string;
	label?: string;
	name: string;
	type?: string;
	placeholder?: string;
	isInValid?: boolean;
	isPassword?: boolean;
	textInValid?: string;
	isRequire?: boolean;
	isShowLabelStar?: boolean;
	max?: number;
	disabled?: boolean;
	onChange?: (e: any) => void;
}

const KareInput = ({
	value,
	label,
	name,
	isPassword,
	type = "text",
	placeholder = "",
	isInValid = false,
	textInValid,
	isRequire = false,
	isShowLabelStar = false,
	max,
	disabled,
	onChange,
}: IKareInput) => {
	const inputRef = useRef<any>();

	const [showPassword, setShowPassword] = useState(false);
	const inputType = isPassword ? (showPassword ? "text" : "password") : type;

	const handleOnChange = (e: any) => {
		inputRef.current.value = e.target.value;
		onChange && onChange(e.target.value);
	};

	const toggleShowPassword = () => {
		setShowPassword(!showPassword);
	};
	return (
		<div className={`kare-input ${isPassword ? "kare-input-pw" : ""}`}>
			{label ? (
				<KareLabel
					name={name}
					label={label}
					isShowLabelStar={isShowLabelStar}
				/>
			) : null}
			<div className="wrap-input">
				<input
					ref={inputRef}
					onChange={onChange}
					type={inputType}
					className={`py-2 form-control ${isInValid ? "is-invalid" : ""}`}
					id={name}
					name={name}
					placeholder={placeholder}
					required={isRequire}
					value={value}
					maxLength={max}
					disabled={disabled}
				/>
				{isPassword && (
					<button
						className="btn-toggle-pw"
						onClick={toggleShowPassword}
						role="button"
					>
						<i className={`ti ti-${showPassword ? "eye" : "eye-off"}`}></i>
					</button>
				)}
			</div>
			<div className={`invalid-feedback ${isInValid ? "block" : ""}`}>
				{textInValid}
			</div>
		</div>
	);
};

export default memo(KareInput);
