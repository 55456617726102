import { createAsyncThunk, UnknownAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { userInfoActions } from "./userInfoSlice";
import { IResponseData, TYPE } from "../../common/define";
import { queryApi } from "../../common/queryAPI";
import { apiRequest } from "../../common/api.request";
import { globalStore } from "../store";
// import { LOGIN_ACTION } from "state/auth/actions";
import { TFunction } from "i18next";

export const GET_USER_INFO_ACTION = createAsyncThunk(
	"userInfoSlice/GET_USER_INFO_ACTION",
	async (data: any) => {
		try {
			const { t } = data;
			const res: IResponseData = await queryApi(apiRequest.userInfo);
			console.log("res", res);
			if (res.type === TYPE.ERROR) {
				// globalStore.dispatch(authActions.setErrorCode(res.messages[0].code));
				toast.warning(`${t(res.messages[0].code)}`);
				return;
			}
			globalStore.dispatch(userInfoActions.updateUserInfo(res.data));
			return res;
		} catch (e) {
			console.log("error", e);
		}
	},
);

export const EDIT_USER_INFO_ACTION = createAsyncThunk(
	"userInfoSlice/EDIT_USER_INFO_ACTION",
	async (data?: {
		t?: TFunction<"translation", undefined>;
		fullName: string;
		position: string;
		email: string;
		phone: string;
		callback?: (data?: number | any) => void;
	}) => {
		try {
			const { fullName, position, email, phone, t, callback } = data || {};
			const res: IResponseData = await queryApi(apiRequest.updateUserInfo, {
				fullName,
				position,
				email,
				phone,
			});
			if (t) {
				if (res.type === TYPE.ERROR) {
					toast.warning(`${t(res.messages[0].code)}`);
					return;
				}
				if (res.type === TYPE.SUCCESS) {
					callback && callback();
				}
				globalStore.dispatch(
					GET_USER_INFO_ACTION({ t }) as unknown as UnknownAction,
				);
			}

			return res;
		} catch (e) {
			console.log("error", e);
		}
	},
);

export const UPDATE_PASSWORD_ACTION = createAsyncThunk(
	"userInfoSlice/UPDATE_PASSWORD_ACTION",
	async (data?: {
		t?: TFunction<"translation", undefined>;
		currentPassword: string;
		newPassword: string;
		callback?: (data?: number | any) => void;
	}) => {
		try {
			const { currentPassword, newPassword, t, callback } = data || {};
			const res: IResponseData = await queryApi(apiRequest.changePassword, {
				currentPassword,
				newPassword,
			});

			if (t) {
				if (res.type === TYPE.ERROR) {
					toast.warning(`${t(res.messages[0].code)}`);
					return;
				}
				if (res.type === TYPE.SUCCESS) {
					callback && callback();
				}
				globalStore.dispatch(
					GET_USER_INFO_ACTION({ t }) as unknown as UnknownAction,
				);
			}
			return res;
		} catch (e) {
			console.log("error", e);
		}
	},
);
