export const METHOD = {
	GET: "GET",
	POST: "POST",
	PUT: "PUT",
	PATCH: "PATCH",
	DELETE: "DELETE",
};

const signUp: IAPI = {
	uri: "/auth/signup",
	method: METHOD.POST,
	authenticated: false,
};

const login: IAPI = {
	uri: "/auth/getAdminToken",
	method: METHOD.POST,
	authenticated: false,
};

const logout: IAPI = {
	uri: "/auth/logout",
	method: METHOD.POST,
	authenticated: true,
};

const refreshToken: IAPI = {
	uri: "/auth/refreshToken",
	method: METHOD.POST,
	authenticated: false,
};

const country: IAPI = {
	uri: "/common/country/getListCountry",
	method: METHOD.GET,
	authenticated: false,
};

const updateUserInfo: IAPI = {
	uri: "/common/user/updateBaseUser",
	method: METHOD.PUT,
	authenticated: true,
};

const userInfo: IAPI = {
	uri: "/common/user/getBaseUserInfo",
	method: METHOD.GET,
	authenticated: true,
};

const changePassword: IAPI = {
	uri: "/common/user/updatePassword",
	method: METHOD.PATCH,
	authenticated: true,
};

const adminListCustomer: IAPI = {
	uri: "/admin/customer/getListCustomer",
	method: METHOD.GET,
	authenticated: true,
};

const adminCustomerInfo: IAPI = {
	uri: "/admin/customer/getCustomerInfo",
	method: METHOD.GET,
	authenticated: true,
};

const adminListCustomerNote: IAPI = {
	uri: "/admin/customer/note/getListCustomerNote",
	method: METHOD.GET,
	authenticated: true,
};

const adminAddCustomerNote: IAPI = {
	uri: "/admin/customer/note/createCustomerNote",
	method: METHOD.POST,
	authenticated: true,
};

const adminEditCustomerNote: IAPI = {
	uri: "/admin/customer/note/updateCustomerNote",
	method: METHOD.PATCH,
	authenticated: true,
};

const adminDeleteCustomerNote: IAPI = {
	uri: "/admin/customer/note/deleteCustomerNote",
	method: METHOD.DELETE,
	authenticated: true,
};

const adminGetListMember: IAPI = {
	uri: "/admin/member/getListMember",
	method: METHOD.GET,
	authenticated: true,
};

const adminGetMemberInfo: IAPI = {
	uri: "/admin/member/getMemberInfo",
	method: METHOD.GET,
	authenticated: true,
};

const adminGetMemberNote: IAPI = {
	uri: "/admin/member/getFirstMemberNote",
	method: METHOD.GET,
	authenticated: true,
};

const adminUpdateMemberNote: IAPI = {
	uri: "/admin/member/updateFirstMemberNote",
	method: METHOD.POST,
	authenticated: true,
};

const adminGetListProductActualLoss: IAPI = {
	uri: "/admin/product/getListProductActualLoss",
	method: METHOD.GET,
	authenticated: true,
};

const adminGetListPostTransit: IAPI = {
	uri: "/admin/product/getListPostTransit",
	method: METHOD.GET,
	authenticated: true,
};

const adminGetListPostTransitConfig: IAPI = {
	uri: "/admin/product/getProductPostTransitConfig",
	method: METHOD.GET,
	authenticated: true,
};

const adminGetListMedicalConfig: IAPI = {
	uri: "/admin/product/getProductOverseaMedicalServiceConfig",
	method: METHOD.GET,
	authenticated: true,
};

const adminCreateProductPostTransit: IAPI = {
	uri: "/admin/product/createProductPostTransit",
	method: METHOD.POST,
	authenticated: true,
};

const adminUpdateProductPostTransit: IAPI = {
	uri: "/admin/product/updateProductPostTransit",
	method: METHOD.PATCH,
	authenticated: true,
};

const adminGetProductInfo: IAPI = {
	uri: "/admin/product/getProductInfo",
	method: METHOD.GET,
	authenticated: true,
};

const adminGetConfigActualLoss: IAPI = {
	uri: "/admin/product/getProductActualLossConfig",
	method: METHOD.GET,
	authenticated: true,
};

const adminCreateProductActualLoss: IAPI = {
	uri: "/admin/product/createProductActualLoss",
	method: METHOD.POST,
	authenticated: true,
};

const adminUploadProductFeeFile: IAPI = {
	uri: "/admin/product/uploadProductFeeFile",
	method: METHOD.PATCH,
	authenticated: true,
};

const adminUploadContractCertificateFile: IAPI = {
	uri: "/admin/contract/uploadCertificateFile",
	method: METHOD.PATCH,
	authenticated: true,
};

const adminGetListContractByMember: IAPI = {
	uri: "/admin/contract/getListContractByMember",
	method: METHOD.GET,
	authenticated: true,
};

const adminGetListContract: IAPI = {
	uri: "/admin/contract/getListContract",
	method: METHOD.GET,
	authenticated: true,
};

const adminGetListContractByCustomer: IAPI = {
	uri: "/admin/contract/getListContractByCustomer",
	method: METHOD.GET,
	authenticated: true,
};

const adminGetContractInfo: IAPI = {
	uri: "/admin/contract/getContractInfo",
	method: METHOD.GET,
	authenticated: true,
};

const adminGetContractStatusAllowUpdate: IAPI = {
	uri: "/admin/contract/getContractStatusAllowUpdate",
	method: METHOD.GET,
	authenticated: true,
};
const adminGetListContractEmailMessage: IAPI = {
	uri: "/admin/contract/getListContractEmailMessage",
	method: METHOD.GET,
	authenticated: true,
};

const adminUpdateContractStatus: IAPI = {
	uri: "/admin/contract/updateContractStatus",
	method: METHOD.POST,
	authenticated: true,
};

const adminResendMail: IAPI = {
	uri: "/admin/contract/sendMailCertificateFile",
	method: METHOD.POST,
	authenticated: true,
};
const adminGetContractEmailMessage: IAPI = {
	uri: "/admin/contract/getListContractEmailMessage",
	method: METHOD.GET,
	authenticated: true,
};

const adminCreateCountry: IAPI = {
	uri: "/admin/country/createCountry",
	method: METHOD.POST,
	authenticated: true,
};

const adminGetListProduct: IAPI = {
	uri: "/admin/product/getListProduct",
	method: METHOD.GET,
	authenticated: true,
};

const adminCreateMedicalConfig: IAPI = {
	uri: "/admin/product/createProductOverseaMedicalServiceConfig",
	method: METHOD.POST,
	authenticated: true,
};

const adminCreateActualLossConfig: IAPI = {
	uri: "/admin/product/createProductActualLossConfig",
	method: METHOD.POST,
	authenticated: true,
};

const adminCreatePostTransitConfig: IAPI = {
	uri: "/admin/product/createProductPostTransitConfig",
	method: METHOD.POST,
	authenticated: true,
};

const adminGetOverseaConfig: IAPI = {
	uri: "/admin/product/getProductOverseaMedicalServiceConfig",
	method: METHOD.GET,
	authenticated: true,
};

const adminGetPostTransitConfig: IAPI = {
	uri: "/admin/product/getProductPostTransitConfig",
	method: METHOD.GET,
	authenticated: true,
};

const adminGetActualLossConfig: IAPI = {
	uri: "/admin/product/getListProductActualLoss",
	method: METHOD.GET,
	authenticated: true,
};

const adminCreateProductSale: IAPI = {
	uri: "/admin/product/createProductSale",
	method: METHOD.POST,
	authenticated: true,
};

const adminUpdateStatusProduct: IAPI = {
	uri: "/admin/product/updateProductSale",
	method: METHOD.PATCH,
	authenticated: true,
};

// const adminGetListProductActualLoss: IAPI = {
// 	uri: "/admin/product/getListProductActualLoss",
// 	method: METHOD.GET,
// 	authenticated: true,
// };

export const apiRequest = {
	signUp,
	country,
	login,
	logout,
	userInfo,
	updateUserInfo,
	adminListCustomer,
	adminCustomerInfo,
	adminListCustomerNote,
	adminAddCustomerNote,
	adminEditCustomerNote,
	adminDeleteCustomerNote,
	adminGetListMember,
	adminGetMemberInfo,
	adminGetMemberNote,
	adminUpdateMemberNote,
	adminGetListProductActualLoss,
	adminGetListPostTransit,
	adminGetProductInfo,
	adminGetConfigActualLoss,
	adminCreateProductActualLoss,
	adminUploadProductFeeFile,
	adminGetListPostTransitConfig,
	adminCreateProductPostTransit,
	refreshToken,
	adminGetListContractByMember,
	adminGetListContractByCustomer,
	adminGetContractInfo,
	adminCreateCountry,
	adminUpdateProductPostTransit,
	adminGetListProduct,
	adminGetListMedicalConfig,
	adminCreateMedicalConfig,
	adminCreateActualLossConfig,
	adminCreatePostTransitConfig,
	adminGetListContract,
	adminGetContractStatusAllowUpdate,
	adminUpdateContractStatus,
	adminGetListContractEmailMessage,
	adminGetOverseaConfig,
	adminGetPostTransitConfig,
	adminGetActualLossConfig,
	adminCreateProductSale,
	changePassword,
	adminUploadContractCertificateFile,
	adminResendMail,
	adminGetContractEmailMessage,
	adminUpdateStatusProduct,
};

export interface IAPI {
	uri: string;
	method: string;
	authenticated?: boolean;
	useFullUri?: boolean;
}
