import { UnknownAction } from "@reduxjs/toolkit";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { CHILD_LINK, PARENT_LINK } from "../../../common/define";
import { scrollPageToTop } from "../../../common/utils";
import useActiveCurrentPage from "../../../hooks/useActiveCurrentPage";
import { useLocale } from "../../../hooks/useLocale";
import { CREATE_PRODUCT_POST_TRANSIT } from "../../../state/listProductPostTransit/actions";
import { UPLOAD_FILE_PRODUCT } from "../../../state/productActualLoss/actions";
import { RootState, globalStore } from "../../../state/store";

const SWAL_ADD_NEW_PRODUCT = {
	reverseButtons: true,
	text: "Are you sure you want to add product?",
	showCancelButton: true,
	confirmButtonColor: "red",
	cancelButtonColor: "#EAEFF4",
	confirmButtonText: "Ok",
};

const swalWithBootstrapButtons = Swal.mixin({
	customClass: {
		confirmButton: "btn btn-secondary",
		cancelButton: "btn btn-light-indigo me-2",
	},
	confirmButtonColor: "red",
	buttonsStyling: false,
});

const useAddNewPostTransit = () => {
	const { t } = useLocale();
	const navigate = useNavigate();
	const [isClickSubmit, setIsClickSubmit] = useState(false);

	const [caseName, setCaseName] = useState<string | undefined>(undefined);
	const [error, setError] = useState({
		caseName: "",
	});
	const [fileUpload, setFileUpload] = useState();

	const { postTransitConfigsFormData } = useSelector(
		(state: RootState) => state.listProductPostTransitReducers,
	);

	const fireAlertSuccess = useCallback(async () => {
		await Swal.fire({
			title: `${t("ANY_SUCCESSFULLY", {
				any: t("ADD"),
			})}`,
			icon: "success",
		}).then(async ({ isConfirmed }) => {
			if (!isConfirmed) return;
			navigate(-1);
			scrollPageToTop();
		});
	}, []);

	const validCaseName = useCallback(
		(data: string) => {
			if (caseName === undefined || data.length === 0) {
				setCaseName("");
				setError((prev) => ({
					...prev,
					caseName: t("POST_TRANSIT_LOSS_CASE_NAME_REQUIRED"),
				}));
				scrollPageToTop();
				return;
			}

			setError((prev) => ({
				...prev,
				caseName: "",
			}));
		},
		[caseName],
	);

	const onUpload = useCallback((file: any) => {
		setFileUpload(file);
	}, []);

	const handleChangeCaseName = useCallback(
		(inputText: string) => {
			validCaseName(inputText);
			setCaseName(inputText);
		},
		[caseName],
	);

	const handlePostFile = useCallback(
		async (productId: number) => {
			await globalStore.dispatch(
				UPLOAD_FILE_PRODUCT({
					productId: productId,
					file: fileUpload,
					callbackSuccess() {
						fireAlertSuccess();
					},
				}) as unknown as UnknownAction,
			);
		},
		[fileUpload],
	);

	const checkIsHaveFileToUpload = useCallback(
		async (productId: number) => {
			console.log("checkIsHaveFileToUpload---call");
			if (!fileUpload) {
				await fireAlertSuccess();
				return;
			}

			await handlePostFile(productId);
		},
		[fileUpload],
	);

	const handleCreateProduct = useCallback(async () => {
		setIsClickSubmit(true);
		validCaseName(caseName!);
		if (!postTransitConfigsFormData || !caseName) return;

		if (!fileUpload) return;
		swalWithBootstrapButtons
			.fire(SWAL_ADD_NEW_PRODUCT)
			.then(async ({ isConfirmed }) => {
				if (!isConfirmed) return;
				await globalStore.dispatch(
					CREATE_PRODUCT_POST_TRANSIT({
						t,
						name: caseName,
						items: postTransitConfigsFormData,
						callback(data) {
							console.log("data-created-product-id", data);
							checkIsHaveFileToUpload(data);
						},
					}) as unknown as UnknownAction,
				);
			});
	}, [
		caseName,
		validCaseName,
		checkIsHaveFileToUpload,
		postTransitConfigsFormData,
	]);

	useActiveCurrentPage(CHILD_LINK.ACTUAL_LOSS_PRODUCT, PARENT_LINK.PRODUCT);

	return {
		caseName,
		error,
		handleChangeCaseName,
		handleCreateProduct,
		onUpload,
		setCaseName,
		hasNotUploadFile: !fileUpload && isClickSubmit,
	};
};

export default useAddNewPostTransit;
