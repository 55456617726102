import React from "react";

function useEffectOnce(cb: () => void, condition = true) {
	const isCalledRef = React.useRef(false);

	React.useEffect(() => {
		if (condition && !isCalledRef.current) {
			isCalledRef.current = true;
			cb();
		}
	}, []);
}

export default useEffectOnce;
