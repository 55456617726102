import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocale } from "../../../hooks/useLocale";
import WrapperPage from "../../../components/WrapperPage";
import HeaderMini from "../../../components/HeaderMini";
import { CHILD_LINK, PARENT_LINK } from "../../../common/define";
import useActiveCurrentPage from "../../../hooks/useActiveCurrentPage";
import ProductInfoPostTransitAddNew from "../../../components/ProductInfoPostTransitAddNew";
import ProductInfoActualLossAddNew from "../../../components/ProductInfoActualLossAddNew";
import { useSelector } from "react-redux";
import { globalStore, RootState } from "../../../state/store";
import { addNewProductActions } from "../../../state/listProduct/addNewProductSlice";
import PublicProductInformationAddNew from "../../../components/PublicProductInformationAddNew";
import ProductInfoOverseaAddNew from "../../../components/ProductInfoOverseaAddNew";
import Swal from "sweetalert2";
import { UnknownAction } from "@reduxjs/toolkit";
import { CREATE_PRODUCT_SALE_ACTION } from "../../../state/listProduct/actions";
import { UPLOAD_FILE_PRODUCT } from "../../../state/productActualLoss/actions";
import { useNavigate } from "react-router-dom";
import { scrollPageToTop } from "../../../common/utils";

interface Product {
	name: string;
	minAge: number;
	maxAge: number;
	items: any[];
	actualLossInsuranceId: number;
	postTransitInsuranceId: number;
	status: string;
}

function hasEmptyFields(obj: Product): boolean {
	const emptyValues: (string | number | any[])[] = ["", []];

	return Object.values(obj).some((value) => emptyValues.includes(value));
}

const SWAL_ADD_NEW_PRODUCT = {
	reverseButtons: true,
	text: "Are you sure you want to add product?",
	showCancelButton: true,
	confirmButtonColor: "red",
	cancelButtonColor: "#EAEFF4",
	confirmButtonText: "Ok",
};

const swalWithBootstrapButtons = Swal.mixin({
	customClass: {
		confirmButton: "btn btn-secondary",
		cancelButton: "btn btn-light-indigo me-2",
	},
	confirmButtonColor: "red",
	buttonsStyling: false,
});

const AddNewProductPage: React.FC = () => {
	const { t } = useLocale();

	const schemaAddNewProduct = useSelector(
		(state: RootState) => state.addNewProductReducers.data,
	);

	const fileReadyUpload = useSelector(
		(state: RootState) => state.addNewProductReducers.fileReadyUpload,
	);

	const [isClickSubmit, setIsClickSubmit] = useState(false);
	const [fileUpload, setFileUpload] = useState();

	const navigate = useNavigate();

	console.log("schemaAddNewProduct", schemaAddNewProduct);

	const reset = () => globalStore.dispatch(addNewProductActions.reset());

	const fireAlertSuccess = useCallback(async () => {
		await Swal.fire({
			title: `${t("ANY_SUCCESSFULLY", {
				any: t("ADD"),
			})}`,
			icon: "success",
		}).then(async ({ isConfirmed }) => {
			if (!isConfirmed) return;
			navigate(-1);
		});
	}, []);

	const handlePostFile = useCallback(
		async (productId: number) => {
			await globalStore.dispatch(
				UPLOAD_FILE_PRODUCT({
					productId: productId,
					file: fileReadyUpload,
					callbackSuccess() {
						fireAlertSuccess();
					},
				}) as unknown as UnknownAction,
			);
		},
		[fileReadyUpload],
	);

	const checkIsHaveFileToUpload = useCallback(
		async (productId: number) => {
			if (!fileReadyUpload) {
				await fireAlertSuccess();
				return;
			}

			await handlePostFile(productId);
		},
		[fileReadyUpload],
	);

	const validSaveButton = useMemo(() => {
		return hasEmptyFields(schemaAddNewProduct);
	}, [schemaAddNewProduct]);

	const onCreateProduct = useCallback(async () => {
		setIsClickSubmit(true);
		if (!fileUpload) return;
		swalWithBootstrapButtons
			.fire(SWAL_ADD_NEW_PRODUCT)
			.then(async ({ isConfirmed }) => {
				if (!isConfirmed) return;
				await globalStore.dispatch(
					CREATE_PRODUCT_SALE_ACTION({
						callbackSuccess(data) {
							console.log("data-successfully", data);
							checkIsHaveFileToUpload(data.id);
						},
					}) as unknown as UnknownAction,
				);
			});
	}, [schemaAddNewProduct]);

	useActiveCurrentPage(CHILD_LINK.VIEW_PRODUCT, PARENT_LINK.PRODUCT);
	useEffect(() => {
		reset();
	}, []);

	return (
		<WrapperPage className="container-fluid py-3">
			<HeaderMini
				title={`${t("REGISTER_A_NEW_PRODUCT")}`}
				breadcrumbs={[t("PRODUCT_MANAGEMENT"), t("SALES_PRODUCT_MANAGEMENT")]}
			/>

			<PublicProductInformationAddNew
				hasNotUploadFile={!fileUpload && isClickSubmit}
				setFileUpload={setFileUpload}
			/>

			<div className="card">
				<ProductInfoOverseaAddNew />
				<ProductInfoPostTransitAddNew />
				<ProductInfoActualLossAddNew />
			</div>

			<div className="p-3">
				<div>
					<div className="text-end">
						<button
							onClick={reset}
							type="submit"
							className="btn bg-danger-subtle text-danger ms-6 rounded-pill me-4"
						>
							{t("CANCEL")}
						</button>
						<button
							onClick={onCreateProduct}
							disabled={validSaveButton}
							type="submit"
							className="btn btn-success rounded-pill px-4"
						>
							{t("SAVE")}
						</button>
					</div>
				</div>
			</div>
		</WrapperPage>
	);
};

export default AddNewProductPage;
