import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IProductDetailInfo } from "../../common/define";
import { addAsyncReducers } from "../store";
import { GET_LIST_PRODUCT, GET_PRODUCT_DETAIL } from "./actions";

// interface IStateData {
// 	data: IProductDetailInfo | undefined;
// }

export interface IState {
	data: IProductDetailInfo | undefined;
}

interface IOtherState {
	code: string;
	isLoading: boolean;
}

const initialState: IState & IOtherState = {
	data: undefined,
	code: "",
	isLoading: false,
};

const productDetailSlice = createSlice({
	name: "productDetailSlice",
	initialState,
	reducers: {
		updateData: (state, action: PayloadAction<IProductDetailInfo>) => {
			state.data = action.payload;
		},
		setErrorCode: (state, action: PayloadAction<string>) => {
			state.code = action.payload;
			return state;
		},
		reset: () => initialState,
	},
	extraReducers: (builder) => {
		addAsyncReducers(builder, GET_PRODUCT_DETAIL, initialState);
	},
});

export const productDetailActions = productDetailSlice.actions;
export const productDetailReducers = productDetailSlice.reducer;

// export const authSelectors = {
// 	isLoading: (state: RootState) => state.authReducers.isLoading,
// 	code: (state: RootState) => state.authReducers.code,
// };
