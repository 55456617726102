import React, { useCallback } from "react";
import { useParams } from "react-router-dom";

import WrapperPage from "../../components/WrapperPage";
import { useLocale } from "../../hooks/useLocale";
import MemberInformation from "./MemberInformation";
import Notes from "./Notes";
import InsuranceContactDetail from "./InsuranceContactDetail";
import HeaderMini from "../../components/HeaderMini";
import { globalStore, RootState } from "../../state/store";
import { GET_MEMBER_INFO } from "../../state/memberInfo/actions";
import { UnknownAction } from "@reduxjs/toolkit";
import useEffectOnce from "../../hooks/useEffectOnce";
import { useSelector } from "react-redux";
import useActiveCurrentPage from "../../hooks/useActiveCurrentPage";
import { PARENT_LINK } from "../../common/define";

const MemberDetailPage: React.FC = () => {
	const { t } = useLocale();
	const { id } = useParams();
	const { activeLang } = useLocale();
	const memberInfoStore = useSelector(
		(state: RootState) => state.memberInfoReducers.data,
	);

	const isLoading = useSelector(
		(state: RootState) => state.memberInfoReducers.isLoading,
	);

	const initMemberInfo = useCallback(async () => {
		await globalStore.dispatch(
			GET_MEMBER_INFO({ id: Number(id) }) as unknown as UnknownAction,
		);
	}, []);

	useEffectOnce(initMemberInfo);
	useActiveCurrentPage(
		PARENT_LINK.MEMBER_MANAGEMENT,
		PARENT_LINK.MEMBER_MANAGEMENT,
	);

	const name =
		activeLang == "ko"
			? memberInfoStore?.fullName
			: `${memberInfoStore?.firstNameEn} ${memberInfoStore?.lastNameEn}`.toUpperCase();

	return (
		<WrapperPage isLoading={isLoading} className="container-fluid py-3">
			<HeaderMini
				title={`${name}${t("USER_INFORMATION_HEADER")} (${memberInfoStore?.code})`}
			/>
			<div className="row">
				<MemberInformation />
				<Notes />
				<InsuranceContactDetail />
			</div>
		</WrapperPage>
	);
};

export default MemberDetailPage;
