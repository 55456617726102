import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiRequest } from "../../common/api.request";
import { TYPE } from "../../common/define";
import { queryApi } from "../../common/queryAPI";
import { globalStore } from "../store";
import { listProductActions } from "./listProducSlice";
import { productDetailActions } from "./productDetailSlice";

export const GET_LIST_PRODUCT = createAsyncThunk(
	"listCustomerSlice/GET_LIST_PRODUCT",
	async (data?: {
		pageNumber?: number;
		pageSize?: number;
		isSearch?: boolean;
	}) => {
		try {
			const { pageNumber, pageSize = 10, isSearch } = data || {};

			console.log("pageNumber", pageNumber);

			const keywordSearch = isSearch
				? globalStore.getState().listProductReducers.keyword
				: "";
			const countryIdSearch = isSearch
				? globalStore.getState().listProductReducers.countryId
				: "";

			const currentPage = isSearch
				? globalStore.getState().listProductReducers.currentPage
				: 0;

			const params = isSearch
				? {
						pageNumber: !currentPage ? currentPage : currentPage - 1,
						pageSize,
						keyword: keywordSearch,
						countryId: countryIdSearch,
					}
				: {
						pageNumber: !currentPage ? currentPage : currentPage - 1,
						pageSize,
					};

			console.log(params);

			const res = await queryApi(apiRequest.adminGetListProduct, {}, params);

			if (res.type === TYPE.SUCCESS) {
				globalStore.dispatch(listProductActions.updateData(res.data));
				return;
			}

			if (res.type === TYPE.ERROR) {
				globalStore.dispatch(
					listProductActions.setErrorCode(res.messages[0].code),
				);
			}
		} catch (e) {
			console.log("error", e);
		}
	},
);

export const GET_PRODUCT_DETAIL = createAsyncThunk(
	"listCustomerSlice/GET_PRODUCT_DETAIL",
	async (data?: { id: number }) => {
		try {
			const { id } = data || {};

			const res = await queryApi(
				apiRequest.adminGetProductInfo,
				{},
				{
					id,
				},
			);

			if (res.type === TYPE.SUCCESS) {
				globalStore.dispatch(productDetailActions.updateData(res.data));
				return;
			}

			if (res.type === TYPE.ERROR) {
				globalStore.dispatch(
					productDetailActions.setErrorCode(res.messages[0].code),
				);
			}
		} catch (e) {
			console.log("error", e);
		}
	},
);

export const CREATE_PRODUCT_SALE_ACTION = createAsyncThunk(
	"listCustomerSlice/CREATE_PRODUCT_SALE_ACTION",
	async (data: {
		callbackSuccess?: (data?: any) => void;
		callbackFailed?: (data?: any) => void;
	}) => {
		try {
			const { callbackSuccess, callbackFailed } = data;

			const schemaAddNewProduct =
				globalStore.getState().addNewProductReducers.data;

			const res = await queryApi(
				apiRequest.adminCreateProductSale,
				schemaAddNewProduct,
			);

			if (res.type === TYPE.SUCCESS) {
				callbackSuccess && callbackSuccess(res.data);
				// globalStore.dispatch(productDetailActions.updateData(res.data));
				return;
			}

			if (res.type === TYPE.ERROR) {
				callbackFailed && callbackFailed(res.data);
				// globalStore.dispatch(
				// 	productDetailActions.setErrorCode(res.messages[0].code),
				// );
			}
		} catch (e) {
			console.log("error", e);
		}
	},
);

export const UPDTE_PRODUCT_STATUS = createAsyncThunk(
	"listCustomerSlice/UPDTE_PRODUCT_STATUS",
	async (data: {
		submitData: any;
		callbackSuccess?: (data?: any) => void;
		callbackFailed?: (data?: any) => void;
	}) => {
		try {
			const { submitData, callbackSuccess, callbackFailed } = data;

			const res = await queryApi(
				apiRequest.adminUpdateStatusProduct,
				submitData,
			);

			if (res.type === TYPE.SUCCESS) {
				callbackSuccess && callbackSuccess(res.data);
				// globalStore.dispatch(productDetailActions.updateData(res.data));
				return;
			}

			if (res.type === TYPE.ERROR) {
				callbackFailed && callbackFailed(res.data);
				// globalStore.dispatch(
				// 	productDetailActions.setErrorCode(res.messages[0].code),
				// );
			}
		} catch (e) {
			console.log("error", e);
		}
	},
);
