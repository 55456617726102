import { toast } from "react-toastify";
import { createAsyncThunk, UnknownAction } from "@reduxjs/toolkit";
import { authActions } from "./authSlice";
import { globalStore } from "../store";
import { userInfoActions } from "../userInfo/userInfoSlice";
import { ERROR_CODE, IResponseData, TYPE } from "../../common/define";
import { queryApi } from "../../common/queryAPI";
import { apiRequest } from "../../common/api.request";
import { GET_USER_INFO_ACTION } from "../userInfo/actions";
import { configActualLossActions } from "../configActualLoss/configActualLossSlice";
import { contractInfoActions } from "../contractInfo/contractInfoSlice";
import { countryActions } from "../country/countrySlice";
import { customerInfoActions } from "../customerInfo/customerInfoSlice";
import { listContractActions } from "../listContract/listContractSlice";
import { listCustomerActions } from "../listCustomer/listCustomerSlice";
import { listCustomerNoteActions } from "../listCustomerNote/listCustomerNoteSlice";
import { listMemberActions } from "../listMember/listMemberSlice";
import { listProductActualLossActions } from "../listProductActualLoss/listProductActualLossSlice";
import { listProductPostTransitActions } from "../listProductPostTransit/listProductPostTransitSlice";
import { memberInfoActions } from "../memberInfo/memberInfoSlice";
import { productActualLossDetailActions } from "../productActualLoss/productActualLossSlice";
import { userSignUpActions } from "../userSignUp/userSignUpSlice";
import { useLocale } from "../../hooks/useLocale";

const getTokenData = (access_token: string) => {
	const tokenParts = access_token.split(".");
	const payload = JSON.parse(atob(tokenParts[1]));
	return payload;
};

const GET_ROLE_ACTION = createAsyncThunk(
	"authSlice/GET_ROLE_ACTION",
	async () => {
		try {
			const access_token = globalStore.getState().authReducers.access_token;
			const res = await getTokenData(access_token);
			globalStore.dispatch(userInfoActions.updateUserInfo({ role: res.role }));
			return res;
		} catch (e) {
			console.log("error", e);
		}
	},
);

export const LOGIN_ACTION = createAsyncThunk(
	"authSlice/LOGIN_ACTION",
	async (data: any) => {
		try {
			const { body, t, setIsShowError } = data;
			const res: IResponseData = await queryApi(apiRequest.login, body);
			if (res.type === TYPE.ERROR) {
				// if (res.messages[0].code === ERROR_CODE.ERR_PASSWORD_IS_INVALID) {
				// 	setIsShowError(true);
				// } else {
				// 	globalStore.dispatch(authActions.setErrorCode(res.messages[0].code));
				// 	toast.warning(`${t(res.messages[0].code)}`);
				// 	setIsShowError(false);
				// }

				setIsShowError(true);

				return;
			}
			globalStore.dispatch(authActions.updateAuth(res));
			globalStore.dispatch(authActions.setErrorCode(""));
			globalStore.dispatch(
				GET_USER_INFO_ACTION({ t }) as unknown as UnknownAction,
			);
			globalStore.dispatch(GET_ROLE_ACTION() as unknown as UnknownAction);

			if (res.type === TYPE.SUCCESS) {
				console.log("alalalalalalal");
				toast.success(
					`${t("ANY_SUCCESSFULLY", {
						any: t("LOG_IN"),
					})}`,
				);
			}
			return res;
		} catch (e) {
			console.log("error", e);
		}
	},
);

export const CLEAR_ACTION = createAsyncThunk(
	"authSlice/CLEAR_ACTION",
	async () => {
		try {
			globalStore.dispatch(authActions.setErrorCode(""));
			globalStore.dispatch(authActions.reset());
			globalStore.dispatch(configActualLossActions.reset());
			globalStore.dispatch(contractInfoActions.reset());
			globalStore.dispatch(countryActions.reset());
			globalStore.dispatch(customerInfoActions.reset());
			globalStore.dispatch(listCustomerActions.reset());
			globalStore.dispatch(listContractActions.reset());
			globalStore.dispatch(listCustomerNoteActions.reset());
			globalStore.dispatch(listMemberActions.reset());
			globalStore.dispatch(listProductActualLossActions.reset());
			globalStore.dispatch(listProductPostTransitActions.reset());
			globalStore.dispatch(memberInfoActions.reset());
			globalStore.dispatch(productActualLossDetailActions.reset());
			globalStore.dispatch(productActualLossDetailActions.reset());
			globalStore.dispatch(userInfoActions.reset());
			globalStore.dispatch(userSignUpActions.reset());
		} catch (e) {
			console.log("error", e);
		}
	},
);

export const LOGOUT_ACTION = createAsyncThunk(
	"authSlice/LOGOUT_ACTION",
	async (data: {
		callbackSuccess?: () => void;
		callbackFailed?: () => void;
	}) => {
		try {
			const { callbackFailed, callbackSuccess } = data;
			const res = await queryApi(apiRequest.logout);

			globalStore.dispatch(CLEAR_ACTION() as unknown as UnknownAction);
			if (res.data.type === TYPE.ERROR) {
				callbackFailed && callbackFailed();
			}

			if (res.data.type === TYPE.SUCCESS) {
				callbackSuccess && callbackSuccess();
			}
		} catch (e) {
			console.log("error", e);
		}
	},
);

export const REFRESH_TOKEN = createAsyncThunk(
	"authSlice/REFRESH_TOKEN",
	async (input: any) => {
		try {
			globalStore.dispatch(authActions.updateAuth(input.data));

			// console.log("res", res);
		} catch (e) {
			console.log("error", e);
		}
	},
);
