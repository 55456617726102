import React, { useCallback } from "react";
import WrapperPage from "../../components/WrapperPage";
import { useLocale } from "../../hooks/useLocale";
import HeaderMini from "../../components/HeaderMini";
import { CHILD_LINK, PARENT_LINK } from "../../common/define";
import useActiveCurrentPage from "../../hooks/useActiveCurrentPage";
import {
	CREATE_COUNTRY_ACTION,
	GET_COUNTRY_ACTION,
} from "../../state/country/actions";
import { globalStore, RootState } from "../../state/store";
import { UnknownAction } from "@reduxjs/toolkit";
import useEffectOnce from "../../hooks/useEffectOnce";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import classNames from "classnames";
import _ from "lodash";
import KareButton from "../../components/KareButton";
import lookup from "country-code-lookup";
import Swal from "sweetalert2";

const SettingPage: React.FC = () => {
	const { t } = useLocale();

	const isLoading = useSelector(
		(state: RootState) => state.countryReducers.isLoading,
	);

	const countries = useSelector(
		(state: RootState) => state.countryReducers.country,
	);

	const initCountry = useCallback(async () => {
		globalStore.dispatch(GET_COUNTRY_ACTION() as unknown as UnknownAction);
	}, []);

	useEffectOnce(initCountry);

	useActiveCurrentPage(CHILD_LINK.COUNTRY_MANAGEMENT, PARENT_LINK.SETTING);

	const schema = yup
		.object({
			name: yup
				.string()
				.required(
					t("REQUIRED", { field: `${t("COUNTRY_NAME")} ${t("KOREAN")}` }),
				),
			nameEn: yup
				.string()
				.required(
					t("REQUIRED", { field: `${t("COUNTRY_NAME")} ${t("ENGLISH")}` }),
				),
		})
		.required();

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});
	const onSubmit = async (data: any) => {
		const swalWithBootstrapButtons = Swal.mixin({
			customClass: {
				confirmButton: "btn btn-secondary",
				cancelButton: "btn btn-light-indigo me-2",
			},
			confirmButtonColor: "red",
			buttonsStyling: false,
		});
		swalWithBootstrapButtons
			.fire({
				reverseButtons: true,
				text: "Are you sure you want to create?",
				showCancelButton: true,
				confirmButtonColor: "red",
				cancelButtonColor: "#EAEFF4",
				confirmButtonText: "Ok",
			})
			.then(async (_result: any) => {
				if (_result.isConfirmed) {
					const dataCountry = lookup.byCountry(data.nameEn);
					await globalStore.dispatch(
						CREATE_COUNTRY_ACTION({
							name: data.name,
							nameEn: data.nameEn,
							code: dataCountry ? dataCountry.iso2 : "",
							async callback(countryId: number) {
								Swal.fire({
									title: t
										? t("ANY_SUCCESSFULLY", {
												any: `${t("ADD")}`,
											})
										: "",
									icon: "success",
								}).then(async (result2) => {
									if (result2.isConfirmed) {
										console.log("data-created-country-id", countryId);
										reset();
										await globalStore.dispatch(
											GET_COUNTRY_ACTION() as unknown as UnknownAction,
										);
									}
								});
							},
						}) as unknown as UnknownAction,
					);
				}
			});
	};

	return (
		<WrapperPage className="container-fluid py-3">
			<HeaderMini
				title={`${t("COUNTRY_MANAGEMENT")}`}
				breadcrumbs={[t("SETTING"), t("COUNTRY_MANAGEMENT")]}
			/>
			<div className="card">
				<div className="card-body">
					<form
						className="row d-flex align-items-center"
						onSubmit={handleSubmit(onSubmit)}
					>
						<div className="col-md-5 mb-4">
							<label className="form-label" htmlFor="name">
								{t("COUNTRY_NAME")} ({t("KOREAN")})
							</label>
							<input
								type="text"
								id="name"
								className={classNames(
									"form-control",
									errors?.name ? "is-invalid" : "",
								)}
								placeholder={`${t("PlEASE_ENTER_COUNTRY_NAME")}`}
								{...register("name")}
							/>
						</div>
						<div className="col-md-5 mb-4">
							<label className="form-label" htmlFor="nameEn">
								{t("COUNTRY_NAME")} ({t("ENGLISH")})
							</label>
							<input
								type="text"
								id="nameEn"
								className={classNames(
									"form-control",
									errors?.nameEn ? "is-invalid" : "",
								)}
								placeholder={`${t("PlEASE_ENTER_COUNTRY_NAME")}`}
								{...register("nameEn")}
							/>
						</div>
						<div className="col-md-2">
							<KareButton
								text={t("ADD")}
								className="btn btn-primary rounded-2 w-100px"
								isLoading={isLoading}
							/>
						</div>
					</form>

					<div className="row border-top mt-4">
						<p className="mt-4">
							{t("ALL")} ({countries.length ? countries.length : 0})
						</p>

						<ul>
							{countries.length ? (
								countries.map((item) => {
									return (
										<li key={item.id} className="fw-bolder mb-4">
											{item?.name} - {item?.nameEn}
										</li>
									);
								})
							) : (
								<></>
							)}
						</ul>
					</div>
				</div>
			</div>
		</WrapperPage>
	);
};

export default SettingPage;
