export const UserIcon: React.FC<React.SVGAttributes<NonNullable<unknown>>> = (
	props,
) => (
	<svg
		className={`${props?.className ?? ""}`}
		width="32"
		height="32"
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M16 0C7.16129 0 0 7.16129 0 16C0 24.8387 7.16129 32 16 32C24.8387 32 32 24.8387 32 16C32 7.16129 24.8387 0 16 0ZM16 6.19355C19.1355 6.19355 21.6774 8.73548 21.6774 11.871C21.6774 15.0065 19.1355 17.5484 16 17.5484C12.8645 17.5484 10.3226 15.0065 10.3226 11.871C10.3226 8.73548 12.8645 6.19355 16 6.19355ZM16 28.3871C12.2129 28.3871 8.81936 26.671 6.54839 23.9871C7.76129 21.7032 10.1355 20.129 12.9032 20.129C13.0581 20.129 13.2129 20.1548 13.3613 20.2C14.2 20.471 15.0774 20.6452 16 20.6452C16.9226 20.6452 17.8064 20.471 18.6387 20.2C18.7871 20.1548 18.9419 20.129 19.0968 20.129C21.8645 20.129 24.2387 21.7032 25.4516 23.9871C23.1806 26.671 19.7871 28.3871 16 28.3871Z"
			fill="#2A3547"
		/>
	</svg>
);
