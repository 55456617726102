import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "./locales/en.json";
import translationKO from "./locales/ko.json";
import { SUPPORTED_LANGUAGES } from "./configs";

export const getInitialLang = () => {
	// get language from cache
	const userLang = localStorage.getItem("siteLanguage");
	if (userLang && SUPPORTED_LANGUAGES.includes(userLang)) {
		return userLang;
	}
	const browserLang = navigator.language.split(/[-_]/)[0];
	if (SUPPORTED_LANGUAGES.includes(browserLang)) {
		return browserLang;
	}
	return SUPPORTED_LANGUAGES[0];
};

// the translations
const resources = {
	en: {
		translation: translationEN,
	},
	ko: {
		translation: translationKO,
	},
};

console.log("getInitialLang()", getInitialLang());

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		fallbackLng: SUPPORTED_LANGUAGES[0], // Use English translations as fallback
		lng: getInitialLang(),
		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	});

export default i18n;
