import React, { useEffect, useState } from "react";
import { useLocale } from "../../hooks/useLocale";
import { globalStore, RootState } from "../../state/store";
import { UnknownAction } from "@reduxjs/toolkit";
import { IProductActualLossItem } from "../../common/define";
// import { addNewProductActions } from "../../state/listProduct/addNewProductSlice";
import { useSelector } from "react-redux";
import { GET_LIST_PRODUCT_ACTUAL_LOSS } from "../../state/listProductActualLoss/actions";
import { GET_LIST_PRODUCT_POST_TRANSIT } from "../../state/listProductPostTransit/actions";
import { addNewProductActions } from "../../state/listProduct/addNewProductSlice";

const ProductInfoPostTransitAddNew: React.FC = () => {
	const { t } = useLocale();

	const [currentSelect, setCurrentSelect] = useState<IProductActualLossItem>();

	const handleSelectConfig = (data?: IProductActualLossItem) => {
		data?.id &&
			globalStore.dispatch(addNewProductActions.updateTransit(data?.id));
	};

	const product = useSelector(
		(state: RootState) => state.listProductPostTransitReducers.data.content,
	);

	const init = () => {
		globalStore.dispatch(
			GET_LIST_PRODUCT_POST_TRANSIT({
				pageNumber: 0,
				pageSize: 100,
			}) as unknown as UnknownAction,
		);
	};

	useEffect(() => {
		init();
	}, []);

	return (
		<div className="card-body text-dark border-top">
			<div className="row mb-2">
				<div className="fs-6 col-4 justify-content-center align-self-center fw-bold">
					{t("POST_TRANSIT_INSURANCE")}
				</div>
			</div>

			<div className="row mb-4">
				<div className="col-4 justify-content-center align-self-center fw-bolder">
					{t("POST_TRANSIT_CASE_NAME")}
				</div>
				<div className="col-8 justify-content-center align-self-center">
					<select
						className="form-select"
						id="exampleFormControlSelect1"
						onChange={(e) => {
							const selected = product?.find(
								(feature) => feature.name === e.target.value,
							);

							handleSelectConfig(selected);
							setCurrentSelect(selected);
						}}
					>
						<option selected value={undefined}>
							{t("PLEASE_SELECT_A_CASE")}
						</option>
						{product && product?.length > 0
							? product.map((feature, index) => (
									<option key={index} value={feature.name}>
										{feature.name}
									</option>
								))
							: null}
					</select>
				</div>
			</div>
			<div className="row mb-2">
				<div className="col-4 justify-content-center align-self-center fw-bolder">
					{t("POST_TRANSIT_CASE_NUMBER")}
				</div>
				<div className="col-8 justify-content-center align-self-center">
					<input
						type="text"
						className="form-control"
						placeholder={`${t("PLEASE_SELECT_A_CASE")}`}
						aria-label="Recipient's username"
						aria-describedby="basic-addon2"
						value={currentSelect?.code}
						disabled
					/>
				</div>
			</div>
		</div>
	);
};

export default ProductInfoPostTransitAddNew;
