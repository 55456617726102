import React, { memo } from "react";
import { useLocale } from "../../../hooks/useLocale";
import KareButton from "../../KareButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";
import classNames from "classnames";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { RootState, globalStore } from "../../../state/store";
import { UPDATE_PASSWORD_ACTION } from "../../../state/userInfo/actions";
import { UnknownAction } from "@reduxjs/toolkit";

const ChangePasswordForm: React.FC = () => {
	const { t } = useLocale();
	const isLoading = useSelector(
		(state: RootState) => state.userInfoReducers.isLoading,
	);
	const user = useSelector((state: RootState) => state.userInfoReducers);
	const schema = yup
		.object({
			currentPassword: yup
				.string()
				.required(t("REQUIRED", { field: `${t("CURRENT_PASSWORD")}` })),
			newPassword: yup
				.string()
				.required(t("REQUIRED", { field: `${t("NEW_PASSWORD")}` })),
			verifyPassword: yup
				.string()
				.required(t("REQUIRED", { field: `${t("VERIFY_PASSWORD")}` }))
				.oneOf([yup.ref("newPassword")], t("PASSWORDS_DO_NOT_MATCH")),
		})
		.required();

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});
	const onSubmit = async (data: any) => {
		const swalWithBootstrapButtons = Swal.mixin({
			customClass: {
				confirmButton: "btn btn-secondary",
				cancelButton: "btn btn-light-indigo me-2",
			},
			confirmButtonColor: "red",
			buttonsStyling: false,
		});
		swalWithBootstrapButtons
			.fire({
				reverseButtons: true,
				text: t("ARE_YOU_SURE_YOU_WANT_TO_CHANGE"),
				showCancelButton: true,
				confirmButtonColor: "red",
				cancelButtonColor: "#EAEFF4",
				confirmButtonText: t("OK"),
				cancelButtonText: t("CANCEL"),
			})
			.then(async (_result: any) => {
				if (_result.isConfirmed) {
					globalStore.dispatch(
						UPDATE_PASSWORD_ACTION({
							t,
							currentPassword: data.currentPassword,
							newPassword: data.newPassword,
							async callback() {
								reset();
								Swal.fire({
									title: t
										? t("ANY_SUCCESSFULLY", {
												any: `${t("CHANGE")}`,
											})
										: "",
									icon: "success",
								});
							},
						}) as unknown as UnknownAction,
					);
				}
			});
	};
	return (
		<div className="card">
			<div className="card-header">
				<h4 className="mb-0">{t("CHANGE_PASSWORD")}</h4>
			</div>
			<div className="card-body">
				<form
					className="row d-flex align-items-center"
					onSubmit={handleSubmit(onSubmit)}
				>
					<div className="col-md-12 mb-4">
						<label className="form-label" htmlFor="currentPassword">
							{t("CURRENT_PASSWORD")}
						</label>
						<input
							type="password"
							id="currentPassword"
							className={classNames(
								"form-control",
								errors?.currentPassword ? "is-invalid" : "",
							)}
							{...register("currentPassword")}
						/>
						{errors?.currentPassword?.message ? (
							<div className="invalid-feedback">
								{errors?.currentPassword?.message}
							</div>
						) : (
							<></>
						)}
					</div>
					<div className="col-md-12 mb-4">
						<label className="form-label" htmlFor="newPassword">
							{t("NEW_PASSWORD")}
						</label>
						<input
							type="password"
							id="newPassword"
							className={classNames(
								"form-control",
								errors?.newPassword ? "is-invalid" : "",
							)}
							{...register("newPassword")}
						/>
						{errors?.newPassword?.message ? (
							<div className="invalid-feedback">
								{errors?.newPassword?.message}
							</div>
						) : (
							<></>
						)}
					</div>
					<div className="col-md-12 mb-4">
						<label className="form-label" htmlFor="verifyPassword">
							{t("VERIFY_PASSWORD")}
						</label>
						<input
							type="password"
							id="verifyPassword"
							className={classNames(
								"form-control",
								errors?.verifyPassword ? "is-invalid" : "",
							)}
							{...register("verifyPassword")}
						/>
						{errors?.verifyPassword?.message ? (
							<div className="invalid-feedback">
								{errors?.verifyPassword?.message}
							</div>
						) : (
							<></>
						)}
					</div>

					<div className="col-md-12">
						<KareButton
							text={t("CHANGE")}
							className="btn btn-primary rounded-2 w-100px float-end"
							isLoading={isLoading}
						/>
					</div>
				</form>
			</div>
		</div>
	);
};

export default memo(ChangePasswordForm);
