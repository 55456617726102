// import WrapBody from "@/components/WrapBody";
import React, {
	ReactElement,
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";

export interface IChildren {
	children:
		| string
		| JSX.Element
		| JSX.Element[]
		| ReactElement
		| React.ReactNode;
}

interface IModalArg {
	title?: string | ReactElement;
	body: ReactElement | null;
	footer?: ReactElement | null;
	style?: React.CSSProperties | null;
	state?: boolean;
	closeIcon?: boolean;
}

interface IAppModalContext {
	setModalArg?: () => void;
	modalArg: IModalArg;
	openModal?: (item: IModalArg) => void;
	closeModal?: () => void;
}

export const AppModalContext = createContext({});

const AppModalContextProvider = (props: IChildren) => {
	const [modalArg, setModalArg] = useState<IModalArg>({
		body: null,
		footer: null,
		style: null,
		title: "",
		state: false,
		closeIcon: true,
	});

	const [modalOverlay, setModalOverlay] = useState<any>();
	const [modal, setModal] = useState<any>();
	const [modalCl, setModalCl] = useState<any>();

	useEffect(() => {
		setModalOverlay(window?.document.querySelector("#modal_overlay"));
		setModal(window?.document.querySelector("#modal"));
		setModalCl(modal?.classList);
	}, [modal?.classList, modalArg, modalOverlay?.classList]);

	const closeModal = useCallback(() => {
		console.log("close");
		modalCl?.add("-translate-y-full");
		window?.document?.body?.classList?.remove("overflow-hidden");
		window?.document?.body?.classList?.remove("h-100");
		setModalArg({ title: "", body: null, footer: null, style: null });
		modalCl?.add("opacity-0");
		modalCl?.add("scale-150");
		modalOverlay?.classList.add("d-none");
	}, [modalCl, modalOverlay?.classList]);

	const openModal = useCallback(
		(arg: IModalArg) => {
			modalOverlay?.classList.remove("d-none");
			modalOverlay?.style.setProperty(
				"top",
				`${document.documentElement.scrollTop}px`,
				"important",
			);
			window?.document?.body?.classList?.add("overflow-hidden");
			modalCl?.remove("opacity-0");
			modalCl?.remove("-translate-y-full");
			modalCl?.remove("scale-150");
			setModalArg({
				title: arg.title ?? "",
				body: arg.body ?? null,
				footer: arg.footer ?? null,
				style: arg.style ?? null,
			});
		},
		[modalCl, modalOverlay?.classList],
	);

	const contextValues = useMemo(() => {
		return {
			setModalArg,
			modalArg,
			openModal,
			closeModal,
		};
	}, [modalArg, openModal, closeModal]);

	return (
		<AppModalContext.Provider value={contextValues}>
			{props.children}
			<>
				{/* overlay */}
				<div
					id="modal_overlay"
					style={{
						backgroundColor: "rgba(0, 0, 0, 0.8)",
					}}
					className="dropdown-menu-animate-up position-absolute top-0 right-0 bottom-0 left-0 d-flex d-none h-100 w-100 align-items-start justify-content-center md-align-items-center md-pt-0"
				>
					{/* modal */}
					<>
						<div
							id="modal"
							// className="relative min-h-[auto] min-w-[400px] -translate-y-full scale-150 rounded-[10px] bg-dark shadow-lg sm:w-[100%] md:min-w-[400px] sm:min-w-[400px]"
							className="dropdown-menu-animate-up bg-transparent position-absolute min-height-auto min-width-400 z-3 scale-150 rounded-10 bg-dark shadow-lg w-100 min-width-400"
							style={{
								...modalArg.style,
								top: "50%",
								transform: "translateY(-50%)",
							}}
						>
							<div>
								{/* <button
									onClick={() => closeModal()}
									className="bg-red-500 hover:bg-red-600 absolute right-[50px] top-[22px] z-40 h-10 w-10 rounded-full text-2xl text-black focus:outline-none"
								>
									&times;
								</button> */}
								<div className="bg-transparent relative z-10 w-full p-3">
									{modalArg.body}
								</div>
								{modalArg?.footer && (
									<div className="absolute bottom-0 left-0 flex w-full items-center justify-end gap-3 border-t border-gray px-4 py-3">
										{modalArg?.footer}
									</div>
								)}
							</div>
						</div>
					</>
				</div>
			</>
		</AppModalContext.Provider>
	);
};

export default AppModalContextProvider;

export const useAppModalContext = () =>
	useContext(AppModalContext) as IAppModalContext;
