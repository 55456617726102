import { memo } from "react";

interface IKareLabel {
	label?: string;
	name: string;
	isShowLabelStar?: boolean;
}

const KareLabel = ({ label, name, isShowLabelStar = false }: IKareLabel) => {
	return (
		<>
			<label className="form-label" htmlFor={name}>
				{label}
				{isShowLabelStar && <span className="color-red">*</span>}
			</label>
		</>
	);
};

export default memo(KareLabel);
