import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import "react-toastify/dist/ReactToastify.css";

import "sweetalert2/src/sweetalert2.scss";
import "./App.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { globalStore, persistor } from "./state/store";
import AppModalContextProvider from "./contexts/ModalContext";
import AppRouterContextProvider from "./contexts/RouterContext";
import { ToastContainer } from "react-toastify";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement,
);

root.render(
	<React.StrictMode>
		<Provider store={globalStore}>
			<PersistGate persistor={persistor}>
				<AppModalContextProvider>
					<AppRouterContextProvider>
						<App />
						<ToastContainer stacked limit={3} autoClose={2400} />
					</AppRouterContextProvider>
				</AppModalContextProvider>
			</PersistGate>
		</Provider>
	</React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
