import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocale } from "../../hooks/useLocale";
import SelectCheckBox from "../SelectCheckBox";
import { useSelector } from "react-redux";
import { globalStore, RootState } from "../../state/store";
import { getFileNameWithURL } from "../../common/utils";
import { addNewProductActions } from "../../state/listProduct/addNewProductSlice";
// import { addNewProductActions } from "../../state/listProduct/addNewProductSlice";

interface IProps {
	setFileUpload: (value: any) => void;
	hasNotUploadFile: boolean;
}

const PublicProductInformationAddNew = ({
	setFileUpload,
	hasNotUploadFile,
}: IProps) => {
	const { t } = useLocale();

	const productDetail = useSelector(
		(state: RootState) => state.productDetailReducers.data,
	);

	const [file, setFile] = useState<any>();

	console.log("productDetail", productDetail);

	const schemaProductAddNew = useSelector(
		(state: RootState) => state.addNewProductReducers.data,
	);

	const handleChangeProductName = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		const { name, value } = event.target;
		globalStore.dispatch(addNewProductActions.updateName(value));
	};

	const handleMaxMinAge = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		globalStore.dispatch(
			addNewProductActions.updateMinMaxAge({
				field: name as "minAge" | "maxAge",
				value: value,
			}),
		);
	};

	const handleSelectStatus = (item: string[]) => {
		console.log(item);
		globalStore.dispatch(addNewProductActions.updateStatus(item[0]));
	};

	const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
		const fileUp = event.target.files ? event.target.files[0] : null;
		setFile && setFile(fileUp);
		setFileUpload(fileUp);

		globalStore.dispatch(addNewProductActions.updateFileReadyUpload(fileUp));
	};

	return (
		<div className="card">
			<div className="card-header">
				<h4 className="mb-0">{t("PRODUCT_INFORMATION")}</h4>
			</div>
			<div className="card-body pb-0">
				<div className="row">
					<div className="col-3 justify-content-center align-self-center fw-bolder">
						{t("PRODUCT_NAME")}
					</div>
					<div className="col-9 justify-content-center align-self-center">
						{/* <input className="form-control" defaultValue="KIM HONG DO" /> */}
						<input
							type="text"
							name="name"
							className="form-control"
							value={schemaProductAddNew.name}
							placeholder={`${t("PLEASE_ENTER_PRODUCT_NAME")}`}
							onChange={handleChangeProductName}
						/>
						<div className="invalid-feedback">{t("INVALID_INPUT_NAME")}</div>
					</div>
				</div>
			</div>
			<div className="card-body pb-0">
				<div className="row">
					<div className="col-3 justify-content-center align-self-center fw-bolder">
						{t("AGE_ALLOWED_TO_JOIN")}
					</div>
					<div className="col-9 d-flex justify-content-between align-items-center align-self-center">
						{/* <input className="form-control" defaultValue="KIM HONG DO" /> */}
						<div className="col-5 input-with-note">
							<input
								type="number"
								name="minAge"
								onWheel={(e: any) => e.target.blur()}
								className="form-control"
								value={schemaProductAddNew.minAge || ""}
								placeholder={`${t("MINIMUM_AGE")}`}
								onChange={handleMaxMinAge}
							/>
							<span className="note">{t("YEAR_OLD")}</span>
							<div className="invalid-feedback">{t("INVALID_INPUT_NAME")}</div>
						</div>
						<span className="me-1 ms-1">-</span>
						<div className="col-5 input-with-note">
							<input
								type="number"
								onWheel={(e: any) => e.target.blur()}
								name="maxAge"
								className="form-control"
								value={schemaProductAddNew.maxAge || ""}
								placeholder={`${t("MAXIMUM_AGE")}`}
								onChange={handleMaxMinAge}
							/>
							<span className="note">{t("YEAR_OLD")}</span>
							<div className="invalid-feedback">{t("INVALID_INPUT_NAME")}</div>
						</div>
					</div>
				</div>
			</div>
			<div className="card-body pb-0">
				<div className="row">
					<div className="col-3 justify-content-center align-self-center fw-bolder">
						{t("UPLOAD_SALES_PRICE")}
					</div>
					<div className="col-5 justify-content-center align-self-center">
						<div>
							<div className="custom-file">
								<input
									type="file"
									className="form-control"
									id="inputGroupFile01"
									accept=".csv"
									onChange={handleFileUpload}
								/>
								{hasNotUploadFile && (
									<div className="invalid-feedback block">
										{t("CERTIFICATE_URL_REQUIRED")}
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="card-body">
				<div className="row">
					<div className="col-3 justify-content-center align-self-center fw-bolder">
						{t("STATUS")}
					</div>
					<div className="col-5 justify-content-center align-self-center">
						{/* <input className="form-control" defaultValue="KIM HONG DO" /> */}
						<SelectCheckBox
							selectOnlyOne
							classColor="secondary"
							dataChecked={schemaProductAddNew.status}
							handleOnChange={handleSelectStatus}
							data={["ACTIVE", "INACTIVE"]}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PublicProductInformationAddNew;
