import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IError, IProductItem } from "../../common/define";
import { addAsyncReducers } from "../store";
import { GET_LIST_PRODUCT } from "./actions";

interface IStateData {
	content: IProductItem[];
	pageNumber: number;
	pageSize: number;
	totalElements: number;
	totalPages: number;
}

export interface IState {
	data: IStateData;
	currentPage: number;
	keyword: string;
	countryId: string;
}

export interface IAuth {
	access_token: string;
	access_token_expires_dttm: string;
	refresh_token: string;
	refresh_token_expires_dttm: string;
}

interface IOtherState {
	isLoading: boolean;
}

const initialState: IState & IOtherState & IError = {
	data: {
		content: [],
		pageNumber: 0,
		pageSize: 0,
		totalElements: 0,
		totalPages: 0,
	},
	currentPage: 1,
	keyword: "",
	countryId: "",
	result_code: "",
	result_msg: "",
	code: "",
	isLoading: false,
};

const listProductSlice = createSlice({
	name: "listProductSlice",
	initialState,
	reducers: {
		updateData: (state, action: PayloadAction<IStateData>) => {
			state.data = action.payload;
		},
		setErrorCode: (state, action: PayloadAction<string>) => {
			state.code = action.payload;
			return state;
		},
		updateKeyword: (state, action: PayloadAction<string>) => {
			return {
				...state,
				keyword: action.payload,
			};
		},
		updateCountryId: (state, action: PayloadAction<string>) => {
			return {
				...state,
				countryId: action.payload,
			};
		},
		updateCurrentPage: (state, action: PayloadAction<number>) => {
			return {
				...state,
				currentPage: action.payload,
			};
		},
		reset: () => initialState,
	},
	extraReducers: (builder) => {
		addAsyncReducers(builder, GET_LIST_PRODUCT, initialState);
	},
});

export const listProductActions = listProductSlice.actions;
export const listProductReducers = listProductSlice.reducer;

// export const authSelectors = {
// 	isLoading: (state: RootState) => state.authReducers.isLoading,
// 	code: (state: RootState) => state.authReducers.code,
// };
