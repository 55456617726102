import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { LOGIN_ACTION } from "./actions";
import { RootState } from "../store";
import { IError } from "../../common/define";

export interface IAuth {
	access_token: string;
	access_token_expires_dttm: string;
	refresh_token: string;
	refresh_token_expires_dttm: string;
}

interface IOtherState {
	isLoading: boolean;
}

const initialState: IAuth & IOtherState & IError = {
	access_token: "",
	access_token_expires_dttm: "",
	refresh_token: "",
	refresh_token_expires_dttm: "",
	result_code: "",
	result_msg: "",
	code: "",
	isLoading: false,
};

const authSlice = createSlice({
	name: "authSlice",
	initialState,
	reducers: {
		updateAuth: (_state, action: PayloadAction<any>) => {
			return {
				...initialState,
				...action.payload,
			};
		},
		reset: (_state) => {
			return {
				...initialState,
			};
		},
		// resetToken: (state, action: PayloadAction<string>) => {
		// 	state.access_token = action.payload;
		// },
		setErrorCode: (state, action: PayloadAction<string>) => {
			state.code = action.payload;
			return state;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(LOGIN_ACTION.pending, (state) => {
			state.isLoading = true;
		}),
			builder.addCase(LOGIN_ACTION.fulfilled, (state) => {
				state.isLoading = false;
			}),
			builder.addCase(LOGIN_ACTION.rejected, (state) => {
				state = {
					...initialState,
				};
				// window.open(getTwitterOauthUrl());
			});
	},
});

export const authSelectors = {
	isLoading: (state: RootState) => state.authReducers.isLoading,
	code: (state: RootState) => state.authReducers.code,
	accessToken: (state: RootState) => state.authReducers.access_token,
};

export const authActions = authSlice.actions;
export const authReducers = authSlice.reducer;
