import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import WrapperPage from "../../components/WrapperPage";
import { useLocale } from "../../hooks/useLocale";
import CustomerInformation from "./CustomerInformation";
import HeaderMini from "../../components/HeaderMini";
import PaymentInformation from "./PaymentInformation";
import InsuranceStatus from "./InsuranceStatus";
import InsuranceInfoRegistered from "./InsuranceInfoRegistered";
import { CHILD_LINK, PARENT_LINK, STATUS_TYPE } from "../../common/define";
import useActiveCurrentPage from "../../hooks/useActiveCurrentPage";
import { RootState, globalStore } from "../../state/store";
import {
	GET_CONTRACT_INFO,
	GET_CONTRACT_STATUS_ALLOW_UPDATE,
} from "../../state/contractInfo/actions";
import InsuranceApproved from "./InsuranceApproved";
import { useSelector } from "react-redux";

const ContractViewDetailPage: React.FC = () => {
	const { t } = useLocale();
	const { id } = useParams();
	const { activeLang } = useLocale();
	useEffect(() => {
		globalStore.dispatch(GET_CONTRACT_INFO({ contractId: Number(id) }) as any);
		globalStore.dispatch(
			GET_CONTRACT_STATUS_ALLOW_UPDATE({ id: Number(id) }) as any,
		);
	}, [id]);

	const contractDetails = useSelector(
		(state: RootState) => state.contractInfoReducers.data,
	);

	useActiveCurrentPage(CHILD_LINK.VIEW_CONTRACT, PARENT_LINK.CONTRACT);
	const name =
		activeLang == "ko"
			? contractDetails?.customer?.fullName
			: `${contractDetails?.customer?.firstNameEn} ${contractDetails?.customer?.lastNameEn}`.toUpperCase();
	return (
		<WrapperPage className="container-fluid py-3">
			<HeaderMini
				title={t("CONTRACT_USER_INFORMATION_HEADER", {
					name: name,
					code: contractDetails?.code,
				})}
			/>
			<div className="row">
				<CustomerInformation />
				<PaymentInformation />
				<InsuranceStatus />
				<InsuranceInfoRegistered />
			</div>
		</WrapperPage>
	);
};

export default ContractViewDetailPage;
