import React, { memo, useMemo, useState } from "react";
import { useLocale } from "../../hooks/useLocale";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { NOT_AVAILABLE } from "../../common/define";
import { useNavigate } from "react-router-dom";

const ButtonMemberDetail = () => {
	const { t } = useLocale();
	const navigate = useNavigate();

	const customerInfoStore = useSelector(
		(state: RootState) => state.contractInfoReducers.data?.customer,
	);

	const gotoMemberDetail = () => {
		if (customerInfoStore?.member) {
			if (customerInfoStore?.member?.id) {
				navigate(`/member/${customerInfoStore?.member?.id}`);
			}
		}
	};

	return (
		<div className="d-flex gap-4 align-items-center">
			<span>Y</span>{" "}
			<button onClick={gotoMemberDetail} className="btn btn-success">
				{t("GO_TO_DETAIL")}
			</button>
		</div>
	);
};

const CustomerInformation: React.FC = () => {
	const { t } = useLocale();

	const customer = useSelector(
		(state: RootState) => state.contractInfoReducers.data?.customer,
	);
	const member = useSelector(
		(state: RootState) => state.contractInfoReducers.data?.member,
	);

	return (
		<div className="col-md-5">
			<div className="card">
				<div className="card-header d-flex justify-content-between align-items-center">
					<h4 className="mb-0">{t("CUSTOMER_INFORMATION")}</h4>
					{/* <div className="form-group text-end">
						<button type="button" className="btn btn-success">
							{t("EDIT")}
						</button>
					</div> */}
				</div>
				<div className="card-body text-dark">
					<div className="row mb-4">
						<div className="col-6 justify-content-center align-self-center fw-bolder">
							{t("REGISTRATION_NUMBER")}
						</div>
						<div className="col-6 justify-content-center align-self-center">
							{customer?.code}
						</div>
					</div>
					<div className="row mb-4">
						<div className="col-6 justify-content-center align-self-center fw-bolder">
							{t("MEMBER_STATUS")}
						</div>
						<div className="col-6 justify-content-center align-self-center">
							{customer?.isMember ? <ButtonMemberDetail /> : "N"}
						</div>
					</div>
					<div className="row mb-4">
						<div className="col-6 justify-content-center align-self-center fw-bolder">
							{t("MEMBER_NUMBER")}
						</div>
						<div className="col-6 justify-content-center align-self-center">
							{member?.code}
						</div>
					</div>
					<div className="row mb-4">
						<div className="col-6 justify-content-center align-self-center fw-bolder">
							{t("REGISTRATION_DATE")}
						</div>
						<div className="col-6 justify-content-center align-self-center">
							{customer?.registrationDate}
						</div>
						{/* <div className="col-6 justify-content-center align-self-center">
							<input
								type="text"
								className="form-control"
								placeholder="Name"
								required={true}
							/>
							<div className="invalid-feedback">{t("INVALID_INPUT_NAME")}</div>
						</div> */}
					</div>
					<div className="row mb-4">
						<div className="col-6 justify-content-center align-self-center fw-bolder">
							{t("NAME")} ({t("KOREAN")})
						</div>
						<div className="col-6 justify-content-center align-self-center">
							{customer?.fullName}
						</div>
					</div>
					<div className="row mb-4">
						<div className="col-6 justify-content-center align-self-center fw-bolder">
							{t("NAME")} ({t("ENGLISH")})
						</div>
						<div className="col-3">{customer?.firstNameEn.toUpperCase()}</div>
						<div className="col-3">{customer?.lastNameEn.toUpperCase()}</div>
					</div>

					<div className="row mb-4">
						<div className="col-6 justify-content-center align-self-center fw-bolder">
							{t("RESIDENT_NUMBER")}
						</div>
						<div className="col-6 justify-content-center align-self-center">
							{customer?.idCard}
						</div>
					</div>

					<div className="row mb-4">
						<div className="col-6 justify-content-center align-self-center fw-bolder">
							{t("CONTACT")}
						</div>
						<div className="col-6 justify-content-center align-self-center">
							{customer?.phone}
						</div>
					</div>
					<div className="row mb-4">
						<div className="col-6 justify-content-center align-self-center fw-bolder">
							{t("EMAIL")}
						</div>
						<div className="col-6 justify-content-center align-self-center">
							{customer?.email}
						</div>
					</div>
					<div className="row mb-4">
						<div className="col-6 justify-content-center align-self-center fw-bolder">
							{t("GENDER")}
						</div>
						<div className="col-6 justify-content-center align-self-center">
							{customer?.gender ? t(`${customer?.gender}`) : ""}
						</div>
					</div>
					<div className="row mb-4">
						<div className="col-6 justify-content-center align-self-center fw-bolder">
							{t("FINAL_MEMBER_COUNTRY")}
						</div>
						<div className="col-6 justify-content-center align-self-center">
							{customer?.country?.name || NOT_AVAILABLE}
						</div>
					</div>

					{/* <div className="row">
						<button type="button" className="btn btn-outline-primary">
							{t("EDIT")}
						</button>
					</div> */}
				</div>
			</div>
		</div>
	);
};

export default memo(CustomerInformation);
