import { createAsyncThunk } from "@reduxjs/toolkit";
import { globalStore } from "../store";
import { queryApi } from "../../common/queryAPI";
import { apiRequest } from "../../common/api.request";
import { TYPE } from "../../common/define";
import { configOverseaActions } from "./configOverseaService";

export const GET_CONFIG_OVERSEA_ACTION = createAsyncThunk(
	"configOverseaService/GET_CONFIG_OVERSEA_ACTION",
	async (data: {
		callbackSuccess?: (data?: any) => void;
		callbackFailed?: (data?: any) => void;
	}) => {
		try {
			const { callbackSuccess, callbackFailed } = data;
			const res = await queryApi(apiRequest.adminGetOverseaConfig);

			if (res.type === TYPE.SUCCESS && res.data) {
				globalStore.dispatch(configOverseaActions.updateData(res.data));
				callbackSuccess && callbackSuccess(res.data);
			}

			if (res.type === TYPE.ERROR) {
				callbackFailed && callbackFailed(res.data);
				globalStore.dispatch(
					configOverseaActions.setErrorCode(res.messages[0].code),
				);
			}
		} catch (e) {
			console.log("error", e);
		}
	},
);

export const GET_CONFIG_POST_TRANSIT_ACTION = createAsyncThunk(
	"configOverseaService/GET_CONFIG_POST_TRANSIT_ACTION",
	async (data: {
		callbackSuccess?: (data?: any) => void;
		callbackFailed?: (data?: any) => void;
	}) => {
		try {
			const { callbackSuccess, callbackFailed } = data;
			const res = await queryApi(apiRequest.adminGetPostTransitConfig);

			if (res.type === TYPE.SUCCESS && res.data) {
				// globalStore.dispatch(configOverseaActions.updateData(res.data));
				callbackSuccess && callbackSuccess(res.data);
			}

			if (res.type === TYPE.ERROR) {
				callbackFailed && callbackFailed(res.data);
				// globalStore.dispatch(
				// 	configOverseaActions.setErrorCode(res.messages[0].code),
				// );
			}
		} catch (e) {
			console.log("error", e);
		}
	},
);

export const GET_CONFIG_ACTUAL_LOSS_ACTION = createAsyncThunk(
	"configOverseaService/GET_CONFIG_ACTUAL_LOSS_ACTION",
	async (data: {
		callbackSuccess?: (data?: any) => void;
		callbackFailed?: (data?: any) => void;
	}) => {
		try {
			const { callbackSuccess, callbackFailed } = data;
			const res = await queryApi(
				apiRequest.adminGetActualLossConfig,
				{},
				{
					pageNumber: 0,
					pageSize: 100,
				},
			);

			if (res.type === TYPE.SUCCESS && res.data) {
				// globalStore.dispatch(configOverseaActions.updateData(res.data));
				callbackSuccess && callbackSuccess(res.data.content);
			}

			if (res.type === TYPE.ERROR) {
				callbackFailed && callbackFailed(res.data);
				// globalStore.dispatch(
				// 	configOverseaActions.setErrorCode(res.messages[0].code),
				// );
			}
		} catch (e) {
			console.log("error", e);
		}
	},
);
