import React from "react";
import { useLocale } from "../../../hooks/useLocale";
import WrapperPage from "../../../components/WrapperPage";
import HeaderMini from "../../../components/HeaderMini";
import SearchFullSection from "../../../components/SearchFullSection";
import {
	CHILD_LINK,
	COUNTRY_RESIDENT,
	PARENT_LINK,
	STATUS_TYPE,
} from "../../../common/define";
import useActiveCurrentPage from "../../../hooks/useActiveCurrentPage";
import ContractList from "../view/ContractList";

const ContractPendingPage: React.FC = () => {
	const { t } = useLocale();
	useActiveCurrentPage(CHILD_LINK.PENDING_CONTRACT, PARENT_LINK.CONTRACT);

	return (
		<WrapperPage className="container-fluid py-3">
			<HeaderMini
				title={`${t("CONTRACT_MANAGEMENT")}`}
				breadcrumbs={[
					t("CONTRACT_MANAGEMENT"),
					t("APPLICATION_PENDING_MANAGEMENT"),
				]}
			/>
			<div className="row">
				{/* <SearchFullSection
					optionsSelectCountry={COUNTRY_RESIDENT.map((item) => item.key)}
					optionsPeriod={["VietNam", "Korean", "Meo"]}
					optionsKeyword={["VietNam", "Korean", "Meo"]}
					optionsApplicationProduct={["VietNam", "Korean", "Meo"]}
				/> */}
				<ContractList
					emptyTitle={t("EMPTY_PENDING")}
					status={STATUS_TYPE.APPLICATION_APPLIED}
				/>
			</div>
		</WrapperPage>
	);
};

export default ContractPendingPage;
