import { createAsyncThunk } from "@reduxjs/toolkit";
import { globalStore } from "../store";
import { queryApi } from "../../common/queryAPI";
import { apiRequest } from "../../common/api.request";
import { TYPE } from "../../common/define";
import { listProductPostTransitActions } from "./listProductPostTransitSlice";
import { TFunction } from "i18next";
import { v4 as uuidv4 } from "uuid";

export const GET_LIST_PRODUCT_POST_TRANSIT = createAsyncThunk(
	"listProductPostTransitSlice/GET_LIST_PRODUCT_POST_TRANSIT",
	async (data?: {
		pageNumber?: number;
		pageSize?: number;
		isSearch?: boolean;
	}) => {
		try {
			const { pageNumber, pageSize, isSearch } = data || {};

			const keywordSearch = isSearch
				? globalStore.getState().listProductPostTransitReducers.keyword
				: "";
			const countryIdSearch = isSearch
				? globalStore.getState().listProductPostTransitReducers.countryId
				: "";

			const currentPage = isSearch
				? globalStore.getState().listProductPostTransitReducers.currentPage
				: pageNumber;

			const params = isSearch
				? {
						pageNumber: !currentPage ? currentPage : currentPage - 1,
						pageSize,
						keyword: keywordSearch,
						countryId: countryIdSearch,
					}
				: {
						pageNumber: !currentPage ? currentPage : currentPage - 1,
						pageSize,
					};

			const res = await queryApi(
				apiRequest.adminGetListPostTransit,
				{},
				params,
			);

			if (res.data) {
				globalStore.dispatch(
					listProductPostTransitActions.updateData(res.data),
				);
			}

			if (res.type === TYPE.ERROR) {
				globalStore.dispatch(
					listProductPostTransitActions.setErrorCode(res.messages[0].code),
				);
			}
		} catch (e) {
			console.log("error", e);
		}
	},
);

export const CREATE_POST_TRANSIT_CONFIG = createAsyncThunk(
	"configMedicalSlice/CREATE_POST_TRANSIT_CONFIG",
	async (data: {
		name: string;
		typeValue: string;
		callback?: (data?: number | any) => void;
	}) => {
		try {
			const code = uuidv4();
			const { name, typeValue, callback } = data;
			const res = await queryApi(apiRequest.adminCreatePostTransitConfig, {
				code,
				name,
				typeValue,
			});

			if (res.type === TYPE.SUCCESS) {
				callback && callback();
			}

			if (res.type === TYPE.ERROR) {
				globalStore.dispatch(
					listProductPostTransitActions.setErrorCode(res.messages[0].code),
				);
			}
		} catch (e) {
			console.log("error", e);
		}
	},
);

export const GET_LIST_PRODUCT_POST_TRANSIT_CONFIG = createAsyncThunk(
	"listProductPostTransitSlice/GET_LIST_PRODUCT_POST_TRANSIT_CONFIG",
	async () => {
		try {
			const res = await queryApi(apiRequest.adminGetListPostTransitConfig);

			if (res.data) {
				globalStore.dispatch(
					listProductPostTransitActions.updatePostTransitConfig(res.data),
				);
			}

			if (res.type === TYPE.ERROR) {
				globalStore.dispatch(
					listProductPostTransitActions.setErrorCode(res.messages[0].code),
				);
			}
		} catch (e) {
			console.log("error", e);
		}
	},
);

export const CREATE_PRODUCT_POST_TRANSIT = createAsyncThunk(
	"configActualLossSlice/CREATE_PRODUCT_POST_TRANSIT",
	async (data: {
		t?: TFunction<"translation", undefined>;
		name: string;
		items: {
			code: string;
			name: string;
			price: number | string;
		}[];
		callback?: (data?: number | any) => void;
	}) => {
		try {
			const { name, items, t, callback } = data;
			const res = await queryApi(apiRequest.adminCreateProductPostTransit, {
				name,
				items,
			});

			if (res.type === TYPE.SUCCESS && res.data) {
				console.log("res.data---=====---", res.data);
				callback && callback(res.data.id);
			}

			if (res.type === TYPE.ERROR) {
				globalStore.dispatch(
					listProductPostTransitActions.setErrorCode(res.messages[0].code),
				);
			}
		} catch (e) {
			console.log("error", e);
		}
	},
);
