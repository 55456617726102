import { NOT_AVAILABLE } from "./define";
import _ from "lodash";

export const formatISOToString = (isoDateTime: string | undefined) => {
	if (!isoDateTime)
		return {
			date: NOT_AVAILABLE,
			time: NOT_AVAILABLE,
		};

	const dateObj = new Date(isoDateTime);

	const year = dateObj.getFullYear();
	const month = String(dateObj.getMonth() + 1).padStart(2, "0");
	const day = String(dateObj.getDate()).padStart(2, "0");
	let hours = String(dateObj.getHours());
	const minutes = String(dateObj.getMinutes()).padStart(2, "0");

	const amPm = Number(hours) >= 12 ? "PM" : "AM";

	hours = `${Number(hours) % 12 || 12}`;

	return {
		date: `${year}-${month}-${day}`,
		time: `${hours}:${minutes} ${amPm}`,
	};
};

export const getFileNameWithURL = (url: string) => {
	// const url = "https://kare-common-bucket.s3.amazonaws.com/dev/product/fee/10/fee-file.csv";
	const parts = url.split("/");
	const filename = parts[parts.length - 1];
	return filename;
	// console.log(filename);
};

export const scrollPageToTop = () => {
	window.scrollTo(0, 0);
};

export const getFilenameFromUrl = (url: string) => {
	if (!url) return "";
	const urlObject = new URL(url);
	const pathSegments = urlObject.pathname.split("/");
	return pathSegments[pathSegments.length - 1];
};

export const capitalizeFirstLetter = (str: string) => {
	if (!str) return "";
	return str.charAt(0).toUpperCase() + str.slice(1);
};

export function formatNumber(
	value?: number | undefined,
	digit = 0,
	offsetRate?: number,
	toFixed = false,
) {
	if (value == undefined || value == null || Number.isNaN(value)) {
		return "0";
	}

	if (offsetRate != null) {
		value /= offsetRate;
	}

	const result = Intl.NumberFormat("en-US", {
		minimumFractionDigits: toFixed ? digit : 0,
		maximumFractionDigits: digit,
	}).format(value);

	// Eliminate result like -0.00
	if (Number(result) === 0 && result.startsWith("-"))
		return toFixed ? Number(0).toFixed(digit) : "0";

	return result;
}

export const formatBigNumberToAbbreviation = (value: number, digit = 4) => {
	if (!value || isNaN(value)) return `0`;
	if (_.inRange(Math.abs(value), 1000, 1000000)) {
		return `${parseFloat(value.toFixed(1)).toLocaleString()} 만원`;
	}
	if (_.inRange(Math.abs(value), 1000000, 1000000000)) {
		return `${parseFloat((value / 1000000).toFixed(1))} 백만`;
	}
	if (value >= 1000000000) {
		return `${parseFloat((value / 1000000000).toFixed(1))} 십억`;
	}
	return `${value.toFixed(digit)}`;
};

export const formatMoney = (value: number, digit = 0) => {
	if (value < 1) {
		return formatNumber(Number(value), digit);
	} else {
		return formatBigNumberToAbbreviation(Number(value), digit);
	}
};
