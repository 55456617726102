import React, {
	ReactElement,
	createContext,
	useContext,
	useMemo,
	useState,
} from "react";

export interface IChildren {
	children:
		| string
		| JSX.Element
		| JSX.Element[]
		| ReactElement
		| React.ReactNode;
}

interface IAppRouterContext {
	currentParentLink: string;
	currentChildLink: string;
	setCurrentChildLink: (data: string) => void;
	setCurrentParentLink: (data: string) => void;
}

export const AppRouterContext = createContext({});

const AppRouterContextProvider = (props: IChildren) => {
	const [currentChildLink, setCurrentChildLink] = useState("");
	const [currentParentLink, setCurrentParentLink] = useState("");

	const contextValues = useMemo(() => {
		return {
			currentParentLink,
			currentChildLink,
			setCurrentChildLink,
			setCurrentParentLink,
		};
	}, [currentChildLink]);

	return (
		<AppRouterContext.Provider value={contextValues}>
			{props.children}
		</AppRouterContext.Provider>
	);
};

export default AppRouterContextProvider;

export const useAppRouterContext = () =>
	useContext(AppRouterContext) as IAppRouterContext;
