import React, {
	ChangeEvent,
	forwardRef,
	memo,
	useEffect,
	useImperativeHandle,
	useState,
} from "react";
import { useLocale } from "../../hooks/useLocale";

interface IProps {
	data: string[];
	handleOnChange?: (dataStatus: string[]) => void;
	classColor?: string;
	selectOnlyOne?: boolean;
	dataChecked?: string;
	disabled?: boolean;
}

// Define the ref type
export type ResetRef = {
	reset: () => void;
};

const SelectCheckBox: React.FC<IProps> = ({
	data,
	handleOnChange,
	classColor,
	selectOnlyOne,
	dataChecked,
	disabled,
}) => {
	const { t } = useLocale();
	const [dataStatus, setDataStatus] = useState<string[]>([]);

	const onSelectContractStatus = (e: ChangeEvent<HTMLInputElement>) => {
		const status = e.target.value;
		if (selectOnlyOne) {
			handleOnChange && handleOnChange([status]);
			setDataStatus([status]);
			return;
		}

		if (dataStatus?.includes(status)) {
			const filtered = dataStatus.filter((item) => item !== status);
			handleOnChange && handleOnChange(filtered);
			setDataStatus(filtered);
			return;
		}

		setDataStatus((prev) => {
			handleOnChange && handleOnChange([...prev, status]);
			return [...prev, status];
		});
	};

	return (
		<div className="controls d-flex flex-wrap gap-1">
			{data &&
				data.map((item) => {
					const randomKey = Math.random();
					return (
						<fieldset key={item + randomKey} className="me-4">
							<div className="form-check py-2">
								<input
									disabled={disabled}
									type="checkbox"
									value={`${item}`}
									name="styled_min_checkbox"
									className={`form-check-input ${classColor}`}
									id={`${item + randomKey}`}
									onChange={onSelectContractStatus}
									checked={dataChecked === item || dataStatus.includes(item)}
								/>
								<label
									className="form-check-label"
									htmlFor={`${item + randomKey}`}
								>
									{t(item)}
								</label>
							</div>
						</fieldset>
					);
				})}
			<div className="help-block"></div>
		</div>
	);
};
export default memo(SelectCheckBox);
