import React from "react";
import { useLocale } from "../../../hooks/useLocale";
import ContractList from "./ContractList";
import WrapperPage from "../../../components/WrapperPage";
import HeaderMini from "../../../components/HeaderMini";
import SearchSection from "../../../components/SearchSection";
import SearchFullSection from "../../../components/SearchFullSection";
import {
	CHILD_LINK,
	CONTRACT_STATUS,
	COUNTRY_RESIDENT,
	PARENT_LINK,
} from "../../../common/define";
import useActiveCurrentPage from "../../../hooks/useActiveCurrentPage";

const ContractViewPage: React.FC = () => {
	const { t } = useLocale();

	// const handleSelect = (opt: string) => {
	// 	console.log("opt", opt);
	// };

	// const handleOnChange = (text: string) => {
	// 	console.log("input text", text);
	// };

	// const handleSearch = () => {
	// 	console.log("on search");
	// };

	// const handleReset = () => {
	// 	console.log("on reset");
	// };

	useActiveCurrentPage(CHILD_LINK.VIEW_CONTRACT, PARENT_LINK.CONTRACT);
	return (
		<WrapperPage className="container-fluid py-3">
			<HeaderMini
				title={`${t("CONTRACT_MANAGEMENT")}`}
				breadcrumbs={[t("CONTRACT_MANAGEMENT"), t("VIEW_ENTIRE_CONTRACT")]}
			/>
			<div className="row">
				{/* <SearchFullSection
					optionContractStatus={CONTRACT_STATUS.map((item) => item.key)}
					optionsSelectCountry={COUNTRY_RESIDENT.map((item) => item.key)}
					optionsPeriod={["VietNam", "Korean", "Meo"]}
					optionsKeyword={["VietNam", "Korean", "Meo"]}
					optionsApplicationProduct={["VietNam", "Korean", "Meo"]}
					isShowPaymentAmount
				/> */}
				<ContractList status="" emptyTitle="" />
			</div>
		</WrapperPage>
	);
};

export default ContractViewPage;
