import React from "react";
import WrapperPage from "../../components/WrapperPage";
import { useLocale } from "../../hooks/useLocale";
import CustomerInformation from "./CustomerInformation";
import Notes from "./Notes";
import InsuranceContactDetail from "./InsuranceContactDetail";
import HeaderMini from "../../components/HeaderMini";
import useActiveCurrentPage from "../../hooks/useActiveCurrentPage";
import { PARENT_LINK } from "../../common/define";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";

const CustomerDetailPage: React.FC = () => {
	const { t } = useLocale();
	const { activeLang } = useLocale();
	const customerInfoStore = useSelector(
		(state: RootState) => state.customerInfoReducers.data,
	);

	const isLoading = useSelector(
		(state: RootState) => state.customerInfoReducers.isLoading,
	);

	useActiveCurrentPage(
		PARENT_LINK.INSURANCE_CUSTOMER_MANAGEMENT,
		PARENT_LINK.INSURANCE_CUSTOMER_MANAGEMENT,
	);

	return (
		<WrapperPage isLoading={isLoading} className="container-fluid py-3">
			<HeaderMini
				title={`${activeLang == "ko" ? customerInfoStore?.fullName : `${customerInfoStore?.firstNameEn.toUpperCase()} ${customerInfoStore?.lastNameEn.toUpperCase()}`}${t("CUSTOMER_INFORMATION_HEADER")} (${customerInfoStore?.code})`}
			/>
			<div className="row">
				<CustomerInformation />
				<Notes />
				<InsuranceContactDetail />
			</div>
		</WrapperPage>
	);
};

export default CustomerDetailPage;
