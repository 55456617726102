import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SIGN_UP_ACTION } from "./actions";

export interface IUserSignUp {
	email: string;
	password: string;
	userName: string;
	fullName: string;
	firstNameEn: string;
	lastNameEn: string;
	idCard: string;
	phone: string;
	gender: string;
	dateOfBirth: string;
	countryId: number;
}

interface IOtherState {
	isLoading: boolean;
}

const initialState: IUserSignUp & IOtherState = {
	email: "",
	password: "",
	userName: "",
	fullName: "",
	firstNameEn: "",
	lastNameEn: "",
	idCard: "",
	phone: "",
	gender: "",
	dateOfBirth: "",
	countryId: 0,
	isLoading: false,
};

const userSignUpSlice = createSlice({
	name: "userSignUpSlice",
	initialState,
	reducers: {
		updateUserSignUp: (state, action: PayloadAction<any>) => {
			// console.log("action.payload", action.payload);
			// console.log(
			//   "action.payload======",
			//   (state = {
			//     ...action.payload,
			//   })
			// );
			return {
				...state,
				...action.payload,
			};
		},
		reset: (_state) => initialState,
	},
	// extraReducers: (builder) => {
	//   builder.addCase(SIGN_UP_ACTION.pending, (state) => {
	//     state.isLoading = true;
	//   }),
	//     builder.addCase(SIGN_UP_ACTION.fulfilled, (state) => {
	//       state.isLoading = false;
	//     }),
	//     builder.addCase(SIGN_UP_ACTION.rejected, (state) => {
	//       state = {
	//         ...initialState,
	//       };
	//       // window.open(getTwitterOauthUrl());
	//     });
	// },
});

export const userSignUpActions = userSignUpSlice.actions;
export const userSignUpReducers = userSignUpSlice.reducer;
