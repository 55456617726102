import React from "react";
import headerImg from "../../assets/header-bg.png";

interface IProps {
	title: string;
	text?: string;
	breadcrumbs?: string[];
	className?: string;
}

const HeaderMini: React.FC<IProps> = ({
	title,
	text,
	breadcrumbs,
	className = "",
}) => {
	return (
		<div className="row mb-5">
			<div className="col">
				<div
					className={`rounded d-flex align-items-center justify-content-between px-5 page-header ${className}`}
				>
					<div>
						<h4 className="title">{title}</h4>
						{text && <p className="text">{text}</p>}
						{breadcrumbs?.length && (
							<div className="breadcrumbs">
								{breadcrumbs.map((tx, idx) => (
									<p
										key={"breadcrumbs" + idx}
										className={`${idx === 0 ? "" : "dots"}`}
									>
										{tx}
									</p>
								))}
							</div>
						)}
					</div>
					<img src={headerImg} alt="header img" />
				</div>
			</div>
		</div>
	);
};

export default HeaderMini;
