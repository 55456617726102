import { UnknownAction } from "@reduxjs/toolkit";
import { TFunction } from "i18next";
import React, { memo, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { NOT_AVAILABLE } from "../../../common/define";
import { formatISOToString } from "../../../common/utils";
import { useAppModalContext } from "../../../contexts/ModalContext";
import useEffectOnce from "../../../hooks/useEffectOnce";
import { GET_LIST_CONTRACT_EMAIL_MESSAGE } from "../../../state/contractInfo/actions";
import { RootState, globalStore } from "../../../state/store";

interface IPropsModal {
	t: TFunction<"translation", undefined>;
	contractId?: string;
}

const ModalShipmentHistory: React.FC<IPropsModal> = ({ t, contractId }) => {
	const { closeModal } = useAppModalContext();

	const listItems = useSelector(
		(state: RootState) => state.listContractMessageReducers.data,
	);

	const thead = [t("PROCESSING_DATE"), t("SEND_EMAIL")];

	const tbody = useMemo(() => {
		return listItems
			? listItems.map((item, idx) => (
					<tr key={idx}>
						<td className="text-center">
							{item?.sentDate
								? `${formatISOToString(item?.sentDate).date} ${formatISOToString(item?.sentDate).time}`
								: NOT_AVAILABLE}
							{}
						</td>
						<td className="text-center">
							{item?.toAddresses ?? NOT_AVAILABLE}
						</td>
					</tr>
				))
			: null;
	}, [listItems]);

	const initData = useCallback(async () => {
		await globalStore.dispatch(
			GET_LIST_CONTRACT_EMAIL_MESSAGE({
				id: Number(contractId),
			}) as unknown as UnknownAction,
		);
	}, [contractId]);

	useEffectOnce(initData);

	console.log("listItems", listItems);

	return (
		<div
			className="bg-light p-4 rounded-2 max-w-1000 modal-dialog"
			role="document"
		>
			<div className="modal-content">
				<div className=" mb-4 modal-header justify-content-between d-flex align-items-center ">
					<div>
						<h4 className="modal-title" id="exampleModalLabel1">
							{t("DELIVERY_TITLE")}
						</h4>
					</div>
					<div className="modal-footer">
						<button
							type="button"
							className="btn btn-light-indigo text-dark me-2  "
							onClick={closeModal}
						>
							{t("CLOSE")}
						</button>
					</div>
				</div>

				<div className="modal-body">
					<div className="wrap-table-list col-12">
						<div className="card">
							<div className="card-body text-dark overflow-auto p-0">
								<table className="table mb-0 border table-hover text-nowrap fs-2">
									<thead>
										<tr>
											{thead?.map((tit) => (
												<th
													className="text-center"
													key={"thead" + tit}
													scope="col"
												>
													{tit}
												</th>
											))}
										</tr>
									</thead>
									<tbody>{tbody}</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default memo(ModalShipmentHistory);
