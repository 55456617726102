import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ICountry, IError } from "../../common/define";

interface ICountryStore {
	country: ICountry[];
}

interface IOtherState {
	isLoading: boolean;
}

const initialState: ICountryStore & IOtherState & IError = {
	country: [],
	code: "",
	isLoading: false,
};

const countrySlice = createSlice({
	name: "countrySlice",
	initialState,
	reducers: {
		updateCountry: (state, action: PayloadAction<any>) => {
			console.log("action.payload======", action.payload);
			state.country = action.payload;
		},
		userInfoLogout: (_state) => {
			return initialState;
		},
		setErrorCode: (state, action: PayloadAction<string>) => {
			state.code = action.payload;
			return state;
		},
		reset: () => initialState,
	},
});

export const countryActions = countrySlice.actions;
export const countryReducers = countrySlice.reducer;
