import React, { memo, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocale } from "../../../hooks/useLocale";
import TableList from "../../../components/TableList";
import { useSelector } from "react-redux";
import { globalStore, RootState } from "../../../state/store";
import { GET_LIST_PRODUCT_POST_TRANSIT } from "../../../state/listProductPostTransit/actions";
import { UnknownAction } from "@reduxjs/toolkit";
import useEffectOnce from "../../../hooks/useEffectOnce";
import {
	NOT_AVAILABLE,
	PRODUCT_FEATURES_NAME,
	PostTransitProductFeature,
	ProductFeaturesName,
} from "../../../common/define";
import {
	capitalizeFirstLetter,
	formatMoney,
	getFilenameFromUrl,
} from "../../../common/utils";
import { listProductPostTransitActions } from "../../../state/listProductPostTransit/listProductPostTransitSlice";
import Table from "../../../components/Table";

const PostTransitList: React.FC = () => {
	const { t } = useLocale();
	const navigate = useNavigate();

	const listProductPostTransitStore = useSelector(
		(state: RootState) => state.listProductPostTransitReducers.data.content,
	);

	const totalPagesStore = useSelector(
		(state: RootState) => state.listProductPostTransitReducers.data.totalPages,
	);
	const totalElements = useSelector(
		(state: RootState) =>
			state.listProductPostTransitReducers.data.totalElements,
	);
	const currentPageStore = useSelector(
		(state: RootState) => state.listProductPostTransitReducers.currentPage,
	);

	console.log("currentPageStore", currentPageStore);

	const goToDetail = (idx: number) => {
		navigate(`/product/post-transit/edit/${idx}`);
	};

	const handleChangePage = async (idx: number) => {
		await globalStore.dispatch(
			listProductPostTransitActions.updateCurrentPage(
				idx,
			) as unknown as UnknownAction,
		);

		await globalStore.dispatch(
			GET_LIST_PRODUCT_POST_TRANSIT({
				isSearch: true,
				pageSize: 10,
				pageNumber: idx,
			}) as unknown as UnknownAction,
		);
	};

	const getProductFeature = (
		products: PostTransitProductFeature[],
		code: ProductFeaturesName[keyof ProductFeaturesName],
	) => {
		const productFeature = products.find((item) => item.code === code);
		if (!productFeature?.price) return 0;
		return formatMoney(productFeature?.price);
	};

	const initData = useCallback(async () => {
		await globalStore.dispatch(
			GET_LIST_PRODUCT_POST_TRANSIT({
				pageSize: 10,
				pageNumber: currentPageStore,
			}) as unknown as UnknownAction,
		);
	}, [currentPageStore]);

	useEffectOnce(initData);

	const thead = [
		t("CASE_NO"),
		t("CASE_NAME"),
		t("FILE"),
		t("DOMESTIC_MEDICAL_TRANSPORT"),
		t("MEDICAL_TRANSPORT_TO_NEIGHBORING_COUNTRY"),
		t("EMERGENCY_MEDICAL_TRANSFER_TO_HOME_COUNTRY"),
		t("REPATRIATION_OF_REMAINS"),
	];

	const tbody = useMemo(() => {
		return listProductPostTransitStore?.map((item, idx) => (
			<tr
				role="button"
				key={"item" + idx + item.id}
				onClick={() => goToDetail(item.id)}
			>
				<td className="text-center">{item.code}</td>
				<td className="text-center">{item.name}</td>
				<td className="text-center text-decoration-underline">
					<a onClick={(event) => event.stopPropagation()} href={item.feeFile}>
						{capitalizeFirstLetter(getFilenameFromUrl(item.feeFile))}
					</a>
				</td>
				<td className="text-center">
					{getProductFeature(
						item.productFeatures,
						PRODUCT_FEATURES_NAME.DomesticMedicalTransport,
					)}
				</td>
				<td className="text-center">
					{getProductFeature(
						item.productFeatures,
						PRODUCT_FEATURES_NAME.MedicalTransportToNeighboringCountry,
					)}
				</td>
				<td className="text-center">
					{getProductFeature(
						item.productFeatures,
						PRODUCT_FEATURES_NAME.EmergencyMedicalTransferToHomeCountry,
					)}
				</td>
				<td className="text-center">
					{getProductFeature(
						item.productFeatures,
						PRODUCT_FEATURES_NAME.RepatriationOfRemains,
					)}
				</td>
			</tr>
		));
	}, [listProductPostTransitStore]);

	return (
		<Table
			emptyTitle={t("EMPTY_POST_TRANSIT")}
			title={`${t("TOTAL")} (${totalElements} ${t("CASES")})`}
			thead={thead}
			tbody={tbody}
			countPage={totalPagesStore}
			currentPage={currentPageStore}
			handleChangePage={handleChangePage}
			total={listProductPostTransitStore.length}
		/>
	);
};

export default memo(PostTransitList);
