import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IError, IProductActualLossItem } from "../../common/define";
import { addAsyncReducers } from "../store";
import { GET_LIST_PRODUCT_ACTUAL_LOSS } from "./actions";

export interface IProductActualLoss {
	data: {
		content: IProductActualLossItem[];
		pageNumber: number;
		pageSize: number;
		totalElements: number;
		totalPages: number;
	};
	currentPage: number;
	keyword: string;
	countryId: string;
}

export interface IAuth {
	access_token: string;
	access_token_expires_dttm: string;
	refresh_token: string;
	refresh_token_expires_dttm: string;
}

interface IOtherState {
	isLoading: boolean;
}

const initialState: IProductActualLoss & IOtherState & IError = {
	data: {
		content: [],
		pageNumber: 0,
		pageSize: 0,
		totalElements: 0,
		totalPages: 0,
	},
	currentPage: 1,
	keyword: "",
	countryId: "",
	result_code: "",
	result_msg: "",
	code: "",
	isLoading: false,
};

const listProductActualLossSlice = createSlice({
	name: "listProductActualLossSlice",
	initialState,
	reducers: {
		updateListProductActualLoss: (state, action: PayloadAction<any>) => {
			return {
				...state,
				data: action.payload,
			};
		},
		setErrorCode: (state, action: PayloadAction<string>) => {
			state.code = action.payload;
			return state;
		},
		updateKeyword: (state, action: PayloadAction<string>) => {
			return {
				...state,
				keyword: action.payload,
			};
		},
		updateCountryId: (state, action: PayloadAction<string>) => {
			return {
				...state,
				countryId: action.payload,
			};
		},
		updateCurrentPage: (state, action: PayloadAction<number>) => {
			return {
				...state,
				currentPage: action.payload,
			};
		},
		reset: () => initialState,
	},
	extraReducers: (builder) => {
		addAsyncReducers(builder, GET_LIST_PRODUCT_ACTUAL_LOSS, initialState);
	},
});

export const listProductActualLossActions = listProductActualLossSlice.actions;
export const listProductActualLossReducers = listProductActualLossSlice.reducer;

// export const authSelectors = {
// 	isLoading: (state: RootState) => state.authReducers.isLoading,
// 	code: (state: RootState) => state.authReducers.code,
// };
