import { useEffect } from "react";
import { useAppRouterContext } from "../contexts/RouterContext";

const useActiveCurrentPage = (child: string, parent: string) => {
	const { setCurrentChildLink, setCurrentParentLink } = useAppRouterContext();
	useEffect(() => {
		child && setCurrentChildLink(child);
		parent && setCurrentParentLink(parent);
	}, []);
};

export default useActiveCurrentPage;
