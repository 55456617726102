import { createAsyncThunk } from "@reduxjs/toolkit";
import { globalStore } from "../store";
import { queryApi } from "../../common/queryAPI";
import { apiRequest } from "../../common/api.request";
import { TYPE } from "../../common/define";
import { customerInfoActions } from "./customerInfoSlice";
// import { listCustomerActions } from "./customerInfoSlice";

export const GET_CUSTOMER_INFO = createAsyncThunk(
	"customerInfoSlice/GET_CUSTOMER_INFO",
	async (data?: { id: number }) => {
		try {
			const { id } = data || {};

			const res = await queryApi(apiRequest.adminCustomerInfo, {}, { id });

			if (res.type === TYPE.SUCCESS && res.data) {
				globalStore.dispatch(customerInfoActions.updateCustomerInfo(res.data));
			}

			if (res.type === TYPE.ERROR) {
				globalStore.dispatch(
					customerInfoActions.setErrorCode(res.messages[0].code),
				);
			}
		} catch (e) {
			console.log("error", e);
		}
	},
);
