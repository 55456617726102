import { UnknownAction } from "@reduxjs/toolkit";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IProductPostTransitConfig } from "../../common/define";
import useEffectOnce from "../../hooks/useEffectOnce";
import { useLocale } from "../../hooks/useLocale";
import { GET_LIST_PRODUCT_POST_TRANSIT_CONFIG } from "../../state/listProductPostTransit/actions";
import { RootState, globalStore } from "../../state/store";
import { listProductPostTransitActions } from "../../state/listProductPostTransit/listProductPostTransitSlice";

interface IProps {
	isEdit?: boolean;
}

const RewardPostTransit: React.FC<IProps> = ({ isEdit }) => {
	const { t } = useLocale();

	const { postTransitConfigs, postTransitConfigsFormData } = useSelector(
		(state: RootState) => state.listProductPostTransitReducers,
	);

	const initData = useCallback(async () => {
		await globalStore.dispatch(
			GET_LIST_PRODUCT_POST_TRANSIT_CONFIG() as unknown as UnknownAction,
		);
	}, []);

	const onChangeConfig = (index: number, value: string) => {
		const updatedEntries = postTransitConfigsFormData.map((entry, idx) =>
			idx === index ? { ...entry, price: value } : entry,
		);
		globalStore.dispatch(
			listProductPostTransitActions.updatePostTransitConfigFormData(
				updatedEntries,
			),
		);
	};

	useEffectOnce(initData);

	return (
		<>
			<div className="card-header">
				<h4 className="mb-0">{t("REWARD_INFORMATION")}</h4>
			</div>
			<div className="card-body text-dark">
				{postTransitConfigs?.length
					? postTransitConfigs.map((item, index) => {
							return (
								<div key={index} className="row mb-4">
									<div className="col-3 justify-content-center align-self-center fw-bolder">
										{t(item.code)}
									</div>
									<div className="col-9 justify-content-center align-self-center position-relative">
										<input
											disabled={isEdit}
											onChange={(e) => onChangeConfig(index, e.target.value)}
											value={isEdit ? item.price : undefined}
											type="number"
											onWheel={(e: any) => e.target.blur()}
											className="form-control"
											placeholder={`${t("PLEASE_ENTER_THE_AMOUNT")}`}
											aria-describedby="basic-addon2"
										/>
										<span className="man-amount">{t("MAN_WON")}</span>
									</div>
								</div>
							);
						})
					: null}
			</div>
		</>
	);
};

export default RewardPostTransit;
