import { createAsyncThunk, UnknownAction } from "@reduxjs/toolkit";
import { globalStore } from "../store";
import { queryApi } from "../../common/queryAPI";
import { apiRequest } from "../../common/api.request";
import { TYPE } from "../../common/define";
import { listCustomerNoteActions } from "./listCustomerNoteSlice";
import Swal from "sweetalert2";
import { TFunction } from "i18next";
// import { listCustomerActions } from "./customerInfoSlice";

export const GET_LIST_CUSTOMER_NOTE = createAsyncThunk(
	"listCustomerNoteSlice/GET_LIST_CUSTOMER_NOTE",
	async (data?: { id: number }) => {
		try {
			const { id } = data || {};

			const res = await queryApi(
				apiRequest.adminListCustomerNote,
				{},
				{ id, pageSize: 50 },
			);

			if (res.type === TYPE.SUCCESS && res.data) {
				globalStore.dispatch(
					listCustomerNoteActions.updateListCustomerNote(res.data),
				);
			}

			if (res.type === TYPE.ERROR) {
				globalStore.dispatch(
					listCustomerNoteActions.setErrorCode(res.messages[0].code),
				);
			}
		} catch (e) {
			console.log("error", e);
		}
	},
);

export const ADD_CUSTOMER_NOTE = createAsyncThunk(
	"listCustomerNoteSlice/ADD_CUSTOMER_NOTE",
	async (data?: {
		t?: TFunction<"translation", undefined>;
		note: string;
		customerId: string;
		callback?: () => void;
	}) => {
		try {
			const { t, customerId, callback, note } = data || {};
			const res = await queryApi(apiRequest.adminAddCustomerNote, {
				note,
				customerId: Number(customerId),
			});

			if (res.type === TYPE.SUCCESS) {
				Swal.fire({
					title: t
						? t("ANY_SUCCESSFULLY", {
								any: `${t("ADD")}`,
							})
						: "",
					icon: "success",
				}).then(async (result2) => {
					if (result2.isConfirmed) {
						callback && callback();
					}
				});

				customerId &&
					globalStore.dispatch(
						GET_LIST_CUSTOMER_NOTE({
							id: Number(customerId),
						}) as unknown as UnknownAction,
					);
			}

			if (res.type === TYPE.ERROR) {
				globalStore.dispatch(
					listCustomerNoteActions.setErrorCode(res.messages[0].code),
				);

				Swal.fire({
					title: t ? t(`${res.messages[0].code}`) : "",
					icon: "warning",
				});
			}
		} catch (e) {
			console.log("error", e);
		}
	},
);

export const EDIT_CUSTOMER_NOTE = createAsyncThunk(
	"listCustomerNoteSlice/EDIT_CUSTOMER_NOTE",
	async (data?: {
		t?: TFunction<"translation", undefined>;
		id: number;
		note: string;
		customerId: string;
		callback?: () => void;
	}) => {
		try {
			const { t, id, note, customerId, callback } = data || {};
			const res = await queryApi(apiRequest.adminEditCustomerNote, {
				id,
				note,
			});

			if (res.type === TYPE.SUCCESS && res.data) {
				Swal.fire({
					title: t
						? t("ANY_SUCCESSFULLY", {
								any: `${t("EDIT")}`,
							})
						: "",
					icon: "success",
				}).then(async (result2) => {
					if (result2.isConfirmed) {
						callback && callback();
					}
				});

				customerId &&
					globalStore.dispatch(
						GET_LIST_CUSTOMER_NOTE({
							id: Number(customerId),
						}) as unknown as UnknownAction,
					);
			}

			if (res.type === TYPE.ERROR) {
				globalStore.dispatch(
					listCustomerNoteActions.setErrorCode(res.messages[0].code),
				);

				Swal.fire({
					title: t ? t(`${res.messages[0].code}`) : "",
					icon: "warning",
				});
			}
		} catch (e) {
			console.log("error", e);
		}
	},
);

export const DELETE_CUSTOMER_NOTE = createAsyncThunk(
	"listCustomerNoteSlice/DELETE_CUSTOMER_NOTE",
	async (data?: {
		t?: TFunction<"translation", undefined>;
		id: number;
		customerId: string;
		callback?: () => void;
	}) => {
		try {
			const { t, id, customerId, callback } = data || {};
			const res = await queryApi(apiRequest.adminDeleteCustomerNote, {
				id,
			});

			if (res.type === TYPE.SUCCESS) {
				Swal.fire({
					title: t
						? t("ANY_SUCCESSFULLY", {
								any: `${t("DELETE")}`,
							})
						: "",
					icon: "success",
				}).then(async (result2) => {
					if (result2.isConfirmed) {
						callback && callback();
					}
				});

				customerId &&
					globalStore.dispatch(
						GET_LIST_CUSTOMER_NOTE({
							id: Number(customerId),
						}) as unknown as UnknownAction,
					);
			}

			if (res.type === TYPE.ERROR) {
				globalStore.dispatch(
					listCustomerNoteActions.setErrorCode(res.messages[0].code),
				);

				Swal.fire({
					title: t ? t(`${res.messages[0].code}`) : "",
					icon: "warning",
				});
			}
		} catch (e) {
			console.log("error", e);
		}
	},
);
