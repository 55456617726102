import React, { memo, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocale } from "../../../hooks/useLocale";
import TableList from "../../../components/TableList";
import { UnknownAction } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { RootState, globalStore } from "../../../state/store";
import useEffectOnce from "../../../hooks/useEffectOnce";
import { GET_LIST_CONTRACT } from "../../../state/listContract/actions";
import { listContractActions } from "../../../state/listContract/listContractSlice";
import { NOT_AVAILABLE, STATUS_TYPE } from "../../../common/define";
import { formatMoney } from "../../../common/utils";
import Table from "../../../components/Table";

interface Props {
	status: string;
	emptyTitle: string;
}

const ContractList: React.FC<Props> = ({ status, emptyTitle }) => {
	const { t } = useLocale();
	const navigate = useNavigate();

	const goToDetail = (idx: number) => {
		navigate(`/contract/view/${idx}`);
	};

	const listItems = useSelector(
		(state: RootState) => state.listContractReducers.data.content,
	);

	const totalPagesStore = useSelector(
		(state: RootState) => state.listContractReducers.data.totalPages,
	);
	const totalElements = useSelector(
		(state: RootState) => state.listContractReducers.data.totalElements,
	);
	const currentPageStore = useSelector(
		(state: RootState) => state.listContractReducers.currentPage,
	);

	const handleChangePage = async (idx: number) => {
		await globalStore.dispatch(
			listContractActions.updateCurrentPage(idx) as unknown as UnknownAction,
		);

		await globalStore.dispatch(
			GET_LIST_CONTRACT({
				isSearch: true,
				pageSize: 10,
				pageNumber: idx,
			}) as unknown as UnknownAction,
		);
	};
	const initData = useCallback(async () => {
		await globalStore.dispatch(
			GET_LIST_CONTRACT({
				pageSize: 10,
				pageNumber: currentPageStore,
				statuses: status ? [status] : null,
			}) as unknown as UnknownAction,
		);
	}, [currentPageStore]);

	useEffectOnce(initData);

	const thead = [
		t("REGISTRATION_NO"),
		t("MEMBER_COUNTRY"),
		t("CONTRACT_STATUS"),
		t("DATE_OF_APPLICATION"),
		t("NAME"),
		t("DATE_OF_BIRTH"),
		t("CONTACT"),
		t("EMAIL"),
		t("INSURANCE_FORM_START_DATE"),
		t("INSURANCE_FORM_END_DATE"),
		t("REGISTRATION_PRODUCT"),
		t("TOTAL_PAYMENT_AMOUNT"),
	];

	const tbody = useMemo(() => {
		return listItems?.length ? (
			listItems?.map((item, idx) => (
				<tr
					role="button"
					key={"member" + idx}
					onClick={() => goToDetail(item.id)}
				>
					<td className="text-center">{item?.code}</td>
					<td className="text-center">{item?.country?.nameEn}</td>
					<td className={`text-center`}>
						{item?.status ? (
							<span className={`customer-badge ${item?.status?.toLowerCase()}`}>
								{t(item?.status)}
							</span>
						) : (
							NOT_AVAILABLE
						)}
					</td>
					<td className="text-center">{item?.applyDate}</td>

					<td className="text-center">{item?.fullName || NOT_AVAILABLE}</td>
					<td className="text-center">{item?.dateOfBirth || NOT_AVAILABLE}</td>
					<td className="text-center">{item?.phone || NOT_AVAILABLE}</td>
					<td className="text-center">{item?.email || NOT_AVAILABLE}</td>
					<td className="text-center">
						{item?.insuranceStartDate || NOT_AVAILABLE}
					</td>
					<td className="text-center">
						{item?.insuranceEndDate || NOT_AVAILABLE}
					</td>
					<td className="text-center">
						{item?.product?.name || NOT_AVAILABLE}
					</td>

					<td className="text-center">
						{item?.finalAmount ? formatMoney(item?.finalAmount) : NOT_AVAILABLE}
					</td>
				</tr>
			))
		) : (
			<tr>
				{listItems?.map((item, index) => (
					<td key={index} className="text-center"></td>
				))}
			</tr>
		);
	}, [listItems]);

	return (
		<Table
			title={`${t("TOTAL")} (${totalElements} ${t("CASES")})`}
			thead={thead}
			tbody={tbody}
			countPage={totalPagesStore}
			currentPage={currentPageStore}
			handleChangePage={handleChangePage}
			total={listItems.length}
			emptyTitle={emptyTitle}
		/>
	);
};

export default memo(ContractList);
