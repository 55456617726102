import { createAsyncThunk, UnknownAction } from "@reduxjs/toolkit";
import { globalStore } from "../store";
import { queryApi, uploadFileAPI } from "../../common/queryAPI";
import { apiRequest } from "../../common/api.request";
import { CONFIG, TYPE } from "../../common/define";
import { productActualLossDetailActions } from "./productActualLossSlice";
import Swal from "sweetalert2";
import axios from "axios";
import { toast } from "react-toastify";
// import { listCustomerActions } from "./customerInfoSlice";

export const GET_PRODUCT_ACTUAL_LOSS_DETAIL = createAsyncThunk(
	"productActualLossSlice/GET_PRODUCT_ACTUAL_LOSS_DETAIL",
	async (data?: { id: number }) => {
		try {
			const { id } = data || {};

			const res = await queryApi(apiRequest.adminGetProductInfo, {}, { id });

			if (res.type === TYPE.SUCCESS && res.data) {
				globalStore.dispatch(
					productActualLossDetailActions.updateProductActualLossDetail(
						res.data,
					),
				);
			}

			if (res.type === TYPE.ERROR) {
				globalStore.dispatch(
					productActualLossDetailActions.setErrorCode(res.messages[0].code),
				);
			}
		} catch (e) {
			console.log("error", e);
		}
	},
);

export const UPLOAD_FILE_PRODUCT = createAsyncThunk(
	"productActualLossSlice/UPLOAD_FILE_PRODUCT",
	async (data?: {
		productId: number;
		file: any;
		callbackSuccess?: (data?: any) => void;
		callbackFailed?: (data?: any) => void;
	}) => {
		try {
			console.log("call-UPLOAD_FILE_PRODUCT-UPLOAD_FILE_PRODUCT");
			const { productId, file, callbackSuccess, callbackFailed } = data || {};

			const res = await uploadFileAPI(file, Number(productId));
			console.log("res-upload", res);
			if (res.type === TYPE.SUCCESS && res.messages[0].code) {
				callbackSuccess && callbackSuccess(res.messages[0].code);
			}

			if (res.type === TYPE.ERROR) {
				callbackFailed && callbackFailed(res.messages[0].code);
				toast.error(res.messages[0].code);
				globalStore.dispatch(
					productActualLossDetailActions.setErrorCode(res.messages[0].code),
				);
			}
		} catch (e) {
			console.log("error", e);
		}
	},
);
