import React, { memo, useMemo, useState } from "react";
import { useLocale } from "../../hooks/useLocale";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { NOT_AVAILABLE } from "../../common/define";

const MemberInformation: React.FC = () => {
	const { t } = useLocale();

	const memberInfoStore = useSelector(
		(state: RootState) => state.memberInfoReducers.data,
	);

	console.log("-----memberInfoStore----", memberInfoStore);

	return (
		<div className="col-md-5">
			<div className="card">
				<div className="card-header d-flex justify-content-between align-items-center card-header--no-button">
					<h4 className="mb-0">{t("MEMBER_INFORMATION")}</h4>
					{/* <div className="form-group text-end">
						<button type="button" className="btn btn-success">
							{t("EDIT")}
						</button>
					</div> */}
				</div>
				<div className="card-body text-dark card-body--customer">
					<div className="row py-11px">
						<div className="col-6 justify-content-center align-self-center fw-bolder">
							{t("MEMBER_REGISTRATION_DATE")}
						</div>
						<div className="col-6 justify-content-center align-self-center">
							{memberInfoStore?.registrationDate}
						</div>
					</div>
					<div className="row py-11px">
						<div className="col-6 justify-content-center align-self-center fw-bolder">
							{t("MEMBER_NUMBER")}
						</div>
						<div className="col-6 justify-content-center align-self-center">
							{memberInfoStore?.code}
						</div>
					</div>
					{/* <div className="row mb-4">
						<div className="col-6 justify-content-center align-self-center fw-bolder">
							{t("MEMBER_CLASS")}
						</div>
						<div className="col-6 justify-content-center align-self-center">
							APP
						</div>
					</div> */}

					<div className="row py-11px">
						<div className="col-6 justify-content-center align-self-center fw-bolder">
							{t("NAME")} ({t("KOREAN")})
						</div>
						<div className="col-6 justify-content-center align-self-center text-uppercase">
							{memberInfoStore?.fullName}
						</div>
					</div>
					<div className="row py-11px">
						<div className="col-6 justify-content-center align-self-center fw-bolder">
							{t("NAME")} ({t("ENGLISH")})
						</div>
						<div className="col-3 text-uppercase">
							{memberInfoStore?.firstNameEn}
						</div>
						<div className="col-3 text-uppercase">
							{memberInfoStore?.lastNameEn}
						</div>
					</div>
					<div className="row py-11px">
						<div className="col-6 justify-content-center align-self-center fw-bolder">
							{t("ID_CARD_NUMBER")}
						</div>
						<div className="col-6 justify-content-center align-self-center">
							{memberInfoStore?.idCard.slice(0, 6)}-
							{memberInfoStore?.idCard.slice(6)}
						</div>
					</div>
					<div className="row py-11px">
						<div className="col-6 justify-content-center align-self-center fw-bolder">
							{t("CONTACT")}
						</div>
						<div className="col-6 justify-content-center align-self-center">
							{memberInfoStore?.phone}
						</div>
					</div>
					<div className="row py-11px">
						<div className="col-6 justify-content-center align-self-center fw-bolder">
							{t("EMAIL")}
						</div>
						<div className="col-6 justify-content-center align-self-center">
							{memberInfoStore?.email}
						</div>
					</div>
					<div className="row py-11px">
						<div className="col-6 justify-content-center align-self-center fw-bolder">
							{t("GENDER")}
						</div>
						<div className="col-6 justify-content-center align-self-center">
							{t(`${memberInfoStore?.gender}`)}
						</div>
					</div>
					<div className="row py-11px">
						<div className="col-6 justify-content-center align-self-center fw-bolder">
							{t("COUNTRY_OF_RESIDENCE")}
						</div>
						<div className="col-6 justify-content-center align-self-center">
							{memberInfoStore?.country?.nameEn
								? t(memberInfoStore?.country?.nameEn)
								: NOT_AVAILABLE}
						</div>
					</div>
					{/* <div className="row">
						<button type="button" className="btn btn-outline-primary">
							{t("EDIT")}
						</button>
					</div> */}
				</div>
			</div>
		</div>
	);
};

export default memo(MemberInformation);
