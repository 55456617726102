import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocale } from "../../hooks/useLocale";
import SelectCheckBox from "../SelectCheckBox";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { getFileNameWithURL } from "../../common/utils";
import { NOT_AVAILABLE } from "../../common/define";

interface IProps {
	productNumber: string;
	productName: string;
	minAge: number;
	maxAge: number;
	file: string | undefined;
	status: string;
	onUpload?: (file: any) => void;
	setProductStatus: (value: string) => void;
	productStatus: string;
}

const ProductInformationOnlyView: React.FC<IProps> = ({
	productNumber,
	productName,
	minAge,
	maxAge,
	file,
	status,
	onUpload,
	setProductStatus,
	productStatus,
}) => {
	const { t } = useLocale();
	const navigate = useNavigate();

	const productDetail = useSelector(
		(state: RootState) => state.productDetailReducers.data,
	);

	console.log("productDetail", productDetail);

	const [data, setData] = useState({
		productNumber,
		productName,
		minAge,
		maxAge,
		file,
		status,
	});

	const openLink = () => {
		window.open(data.file, "_blank");
	};

	const handleSelectStatus = (item: string[]) => {
		setData((prev) => ({ ...prev, status: item[0] }));
		setProductStatus(item[0]);
	};

	const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
		const fileUp = event.target.files ? event.target.files[0] : null;
		onUpload && onUpload(fileUp);
	};

	return (
		<div className="card">
			<div className="card-header">
				<h4 className="mb-0">{t("PRODUCT_INFORMATION")}</h4>
			</div>
			<div className="card-body pb-0">
				<div className="row">
					<div className="col-3 justify-content-center align-self-center fw-bolder">
						{t("PRODUCT_NUMBER")}
					</div>
					<div className="col-9 justify-content-center align-self-center">
						{/* <input className="form-control" defaultValue="KIM HONG DO" /> */}
						<input
							type="text"
							className="form-control"
							placeholder={`${data.productNumber}`}
							value={`${data.productNumber}`}
							disabled
						/>
						<div className="invalid-feedback">{t("INVALID_INPUT_NAME")}</div>
					</div>
				</div>
			</div>
			<div className="card-body pb-0">
				<div className="row">
					<div className="col-3 justify-content-center align-self-center fw-bolder">
						{t("PRODUCT_NAME")}
					</div>
					<div className="col-9 justify-content-center align-self-center">
						{/* <input className="form-control" defaultValue="KIM HONG DO" /> */}
						<input
							type="text"
							className="form-control"
							value={data.productName}
							placeholder={`${t("PLEASE_ENTER_PRODUCT_NAME")}`}
							disabled
						/>
						<div className="invalid-feedback">{t("INVALID_INPUT_NAME")}</div>
					</div>
				</div>
			</div>
			<div className="card-body pb-0">
				<div className="row">
					<div className="col-3 justify-content-center align-self-center fw-bolder">
						{t("AGE_ALLOWED_TO_JOIN")}
					</div>
					<div className="col-9 d-flex justify-content-between align-items-center align-self-center">
						{/* <input className="form-control" defaultValue="KIM HONG DO" /> */}
						<div className="col-5 input-with-note">
							<input
								type="text"
								className="form-control"
								value={data.minAge}
								placeholder={`${t("MINIMUM_AGE")}`}
								disabled
							/>
							<span className="note">{t("YEAR_OLD")}</span>
							<div className="invalid-feedback">{t("INVALID_INPUT_NAME")}</div>
						</div>
						<span className="me-1 ms-1">-</span>
						<div className="col-5 input-with-note">
							<input
								type="text"
								className="form-control"
								value={data.maxAge}
								placeholder={`${t("MAXIMUM_AGE")}`}
								disabled
							/>
							<span className="note">{t("YEAR_OLD")}</span>
							<div className="invalid-feedback">{t("INVALID_INPUT_NAME")}</div>
						</div>
					</div>
				</div>
			</div>
			<div className="card-body pb-0">
				<div className="row">
					<div className="col-3 justify-content-center align-self-center fw-bolder">
						{t("UPLOAD_SALES_PRICE")}
					</div>
					<div className="col-5 justify-content-center align-self-center">
						{/* <input className="form-control" defaultValue="KIM HONG DO" /> */}
						<div role="button" onClick={openLink}>
							{/* <input
									type="text"
									className="form-control"
									placeholder={`${t("THERE_ARE_NO_REGISTERED_FILES")}`}
									aria-label="Recipient's username"
									aria-describedby="basic-addon2"
									style={{
										border: "none",
										background: "white",
									}}
									disabled
								/> */}

							{/* type="file" */}

							{/* <div className="custom-file">
								<input
									type="file"
									className="form-control"
									id="inputGroupFile01"
								/>
							</div> */}

							{file ? getFileNameWithURL(file) : NOT_AVAILABLE}
							{/* <label
									htmlFor="file-upload"
									className="custom-file-upload btn btn-primary rounded-2"
								>
									{t("SELECT_FILE")}
								</label>
								<input id="file-upload" type="file" size={60} /> */}
							{/* <button className="btn btn-primary rounded-1" type="button">
									{t("SELECT_FILE")}
								</button> */}
						</div>
					</div>
				</div>
			</div>
			<div className="card-body">
				<div className="row">
					<div className="col-3 justify-content-center align-self-center fw-bolder">
						{t("STATUS")}
					</div>
					<div className="col-5 justify-content-center align-self-center">
						<SelectCheckBox
							selectOnlyOne
							classColor="secondary"
							dataChecked={productStatus}
							handleOnChange={handleSelectStatus}
							data={["ACTIVE", "INACTIVE"]}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProductInformationOnlyView;
