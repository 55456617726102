import { UnknownAction } from "@reduxjs/toolkit";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { swalWithBootstrapRed } from "../../common/define";
import { getFileNameWithURL } from "../../common/utils";
import KareInput from "../../components/KareInput";
import ModalShipmentHistory from "../../components/Modals/ModalShipmentHistory";
import { useAppModalContext } from "../../contexts/ModalContext";
import { useLocale } from "../../hooks/useLocale";
import {
	GET_CONTRACT_INFO,
	RESEND_MAIL,
	SAVE_CERTIFICATE_FILE,
} from "../../state/contractInfo/actions";
import { RootState, globalStore } from "../../state/store";

const InsuranceApproved: React.FC = () => {
	const { t } = useLocale();
	const { openModal } = useAppModalContext();
	const [file, setFile] = useState("");
	const [certificateNumber, setCertificateNumber] = useState("");
	const [insuranceStartDate, setInsuranceStartDate] = useState("");
	const [insuranceEndDate, setInsuranceEndDate] = useState("");
	const [fileUpload, setFileUpload] = useState<File | null>(null);
	const { id } = useParams();

	const [formError, setFormError] = useState({
		certificateNumber: false,
		insuranceStartDate: false,
		insuranceEndDate: false,
		fileUpload: false,
	});

	const contractDetails = useSelector(
		(state: RootState) => state.contractInfoReducers.data,
	);

	const openLink = () => {
		window.open(file, "_blank");
	};
	const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
		const fileUp = event.target.files ? event.target.files[0] : null;
		setFileUpload(fileUp);
		setFormError({
			...formError,
			fileUpload: false,
		});
	};

	const fireAlertSuccess = useCallback(async () => {
		await Swal.fire({
			title: `${t("ANY_SUCCESSFULLY", {
				any: t("ADD"),
			})}`,
			icon: "success",
		});
	}, []);

	const fireAlertResendSuccess = useCallback(async () => {
		await Swal.fire({
			title: `${t("ANY_SUCCESSFULLY", {
				any: t("RESEND_MAIL"),
			})}`,
			icon: "success",
		});
	}, []);

	const handlePostFile = async () => {
		const formData = {
			contractId: id,
			certificateNumber: certificateNumber,
			insuranceStartDate: insuranceStartDate,
			insuranceEndDate: insuranceEndDate,
		};

		let errors = {};

		if (!formData.certificateNumber) {
			errors = { ...errors, certificateNumber: true };
		}
		if (!formData.insuranceStartDate) {
			errors = { ...errors, insuranceStartDate: true };
		}
		if (!formData.insuranceEndDate) {
			errors = { ...errors, insuranceEndDate: true };
		}

		if (!fileUpload) {
			errors = { ...errors, fileUpload: true };
		}

		if (Object.keys(errors).length > 0) {
			setFormError((prev) => ({ ...prev, ...errors }));
			return false;
		}
		await globalStore.dispatch(
			SAVE_CERTIFICATE_FILE({
				formData,
				file: fileUpload,
				callbackSuccess() {
					fireAlertSuccess();
					globalStore.dispatch(
						GET_CONTRACT_INFO({ contractId: Number(id) }) as any,
					);
				},
			}) as unknown as UnknownAction,
		);
	};

	const resendMail = async () => {
		swalWithBootstrapRed
			.fire({
				reverseButtons: true,
				text: t("CONFIRM_RESEND_MAIL"),
				showCancelButton: true,
				confirmButtonColor: "red",
				cancelButtonColor: "#EAEFF4",
				confirmButtonText: t("OK"),
				cancelButtonText: t("CANCEL_TEXT"),
			})
			.then(async ({ isConfirmed }) => {
				if (!isConfirmed) return;
				await globalStore.dispatch(
					RESEND_MAIL({
						id: Number(id),
						callbackSuccess() {
							fireAlertResendSuccess();
						},
					}) as unknown as UnknownAction,
				);
			});
	};

	useEffect(() => {
		if (contractDetails) {
			setCertificateNumber(contractDetails?.certificateNumber);
			setInsuranceStartDate(contractDetails?.insuranceStartDate);
			setInsuranceEndDate(contractDetails?.insuranceEndDate);
			setFile(contractDetails?.certificateFile);
		}
	}, [contractDetails]);

	const openViewShipmentHistory = () => {
		openModal &&
			openModal?.({
				title: "",
				style: {
					zIndex: 9,
					top: "0",
					background: "transparent",
				},
				body: <ModalShipmentHistory contractId={id} t={t} />,
			});
	};

	return (
		<div className="card-body text-dark pb-0">
			<div className="row mb-4">
				<div className="fs-4 d-flex justify-content-between align-items-center align-self-center fw-bolder">
					<p className="mb-0">{t("MEMBER_CERTIFICATION")}</p>
					{!file && (
						<div>
							<button
								onClick={handlePostFile}
								type="button"
								className="justify-content-center w-100 btn mb-1 btn-success d-flex align-items-center"
							>
								{t("SAVE")}
							</button>
						</div>
					)}
				</div>
			</div>
			<div className="row mb-4">
				<div className="col-4 justify-content-center align-self-center fw-bolder">
					{t("CERTIFICATE_FILE")}
				</div>

				{file ? (
					<div
						role="button"
						onClick={openLink}
						className="col-5 text-decoration-underline justify-content-center align-self-center"
					>
						{getFileNameWithURL(file)}
					</div>
				) : (
					<div className="col-5 justify-content-center align-self-center">
						<div>
							<div className="custom-file">
								<input
									onChange={handleFileUpload}
									type="file"
									className="form-control"
									accept=".pdf"
								/>
								{formError.fileUpload && (
									<div className="invalid-feedback block">
										{t("CERTIFICATE_URL_REQUIRED")}
									</div>
								)}
							</div>
						</div>
					</div>
				)}
			</div>
			<div className="row mb-4">
				<div className="col-4 justify-content-center align-self-center fw-bolder">
					{t("CERTIFICATE_NUMBER")}
				</div>
				<div className="col-6 justify-content-center align-self-center">
					{file ? (
						certificateNumber
					) : (
						<KareInput
							name="certificateNumber"
							placeholder={t("ENTER_CERTIFICATE_NUMBER")}
							isInValid={formError.certificateNumber}
							textInValid={`${t("CERTIFICATE_REQUIRED")}`}
							type="number"
							isRequire={true}
							onChange={(e) => {
								setCertificateNumber(e.target.value);

								if (e.target.value) {
									setFormError({
										...formError,
										certificateNumber: false,
									});
								} else {
									setFormError({
										...formError,
										certificateNumber: true,
									});
								}
							}}
						/>
					)}
				</div>
			</div>

			<div className="row mb-4">
				<div className="col-4 justify-content-center align-self-center fw-bolder">
					{t("INSURANCE_FORM_START_DATE")}
				</div>
				<div className="col-6 justify-content-center align-self-center">
					{file ? (
						insuranceStartDate
					) : (
						<KareInput
							name="insuranceStartDate"
							placeholder="YYYY-MM-DD"
							isInValid={formError.insuranceStartDate}
							textInValid={`${t("INSURANCE_START_REQUIRED")}`}
							isRequire={true}
							onChange={(e) => {
								setInsuranceStartDate(e.target.value);

								if (e.target.value) {
									setFormError({
										...formError,
										insuranceStartDate: false,
									});
								} else {
									setFormError({
										...formError,
										insuranceStartDate: true,
									});
								}
							}}
						/>
					)}
				</div>
			</div>

			<div className="row mb-4">
				<div className="col-4 justify-content-center align-self-center fw-bolder">
					{t("INSURANCE_FORM_END_DATE")}
				</div>
				<div className="col-6 justify-content-center align-self-center">
					{file ? (
						insuranceEndDate
					) : (
						<KareInput
							name="insuranceEndDate"
							placeholder="YYYY-MM-DD"
							isInValid={formError.insuranceEndDate}
							textInValid={`${t("INSURANCE_END_REQUIRED")}`}
							isRequire={true}
							onChange={(e) => {
								setInsuranceEndDate(e.target.value);

								if (e.target.value) {
									setFormError({
										...formError,
										insuranceEndDate: false,
									});
								} else {
									setFormError({
										...formError,
										insuranceEndDate: true,
									});
								}
							}}
						/>
					)}
				</div>
			</div>

			{file && (
				<div className="row mb-4">
					<div className="col-4 justify-content-center align-self-center fw-bolder">
						{t("SHIPMENT_DETAIL")}
					</div>
					<div className="col-6 justify-content-center align-self-center">
						<div className="d-flex justify-content-start align-items-center">
							<button
								onClick={resendMail}
								type="button"
								className="me-3 justify-content-center btn mb-1 btn-success d-flex align-items-center"
							>
								{t("RESEND_MAIL")}
							</button>
							<button
								onClick={openViewShipmentHistory}
								className="btn mb-1 d-block btn-outline-secondary waves-effect waves-light"
								type="button"
							>
								<i className="ti ti-circle-check fs-4 me-2"></i>
								{t("VIEW_SHIPMENT_HISTORY")}
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default memo(InsuranceApproved);
