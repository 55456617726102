import React, { memo, useCallback, useMemo } from "react";
import { useLocale } from "../../hooks/useLocale";
import { useNavigate } from "react-router-dom";
import TableList from "../../components/TableList";
import { globalStore, RootState } from "../../state/store";
import { GET_LIST_CUSTOMER } from "../../state/listCustomer/actions";
import { UnknownAction } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import useEffectOnce from "../../hooks/useEffectOnce";
import { listCustomerActions } from "../../state/listCustomer/listCustomerSlice";
import { NOT_AVAILABLE } from "../../common/define";

const CustomerList: React.FC = () => {
	const { t } = useLocale();
	const navigate = useNavigate();

	const listCustomerStore = useSelector(
		(state: RootState) => state.listCustomerReducers.data.content,
	);
	const totalPagesStore = useSelector(
		(state: RootState) => state.listCustomerReducers.data.totalPages,
	);
	const totalElements = useSelector(
		(state: RootState) => state.listCustomerReducers.data.totalElements,
	);
	const currentPageStore = useSelector(
		(state: RootState) => state.listCustomerReducers.currentPage,
	);
	const pageNumberStore = useSelector(
		(state: RootState) => state.listCustomerReducers.data.pageNumber,
	);

	console.log("currentPageStore++++=====", currentPageStore);
	console.log("pageNumberStore++++=====", pageNumberStore);

	const goToDetail = (idx: number) => {
		navigate(`/customer/${idx}`);
	};

	const handleChangePage = async (idx: number) => {
		console.log("onChangePage/////", idx);

		await globalStore.dispatch(
			listCustomerActions.updateCurrentPage(idx) as unknown as UnknownAction,
		);

		await globalStore.dispatch(
			GET_LIST_CUSTOMER({
				isSearch: true,
				pageSize: 10,
				pageNumber: idx,
			}) as unknown as UnknownAction,
		);
	};

	const initData = useCallback(async () => {
		await globalStore.dispatch(
			GET_LIST_CUSTOMER({
				pageSize: 10,
				pageNumber: currentPageStore,
			}) as unknown as UnknownAction,
		);
	}, [currentPageStore]);

	// useEffect(() => {
	// 	initData()
	// }, [initData])

	useEffectOnce(initData);

	const thead = [
		t("REGISTRATION_NO"),
		t("COUNTRY_OF_RESIDENCE"),
		t("REGISTRATION_DATE"),
		t("NAME"),
		t("DATE_OF_BIRTH"),
		t("CONTACT"),
		t("EMAIL"),
		t("CONTRACT_QUANTITY"),
		t("MEMBER_STATUS"),
		t("TOTAL_PAYMENT_AMOUNT"),
	];

	const tbody = useMemo(() => {
		return listCustomerStore.length ? (
			listCustomerStore.map((item, idx) => (
				<tr
					role="button"
					key={"item" + idx}
					onClick={() => goToDetail(item.id)}
				>
					<td className="text-center">{item.code ?? NOT_AVAILABLE}</td>
					<td className="text-center">
						{item.country ? item.country.name : NOT_AVAILABLE}
					</td>
					<td className="text-center">
						{item.registrationDate ?? NOT_AVAILABLE}
					</td>
					<td className="text-center">{item.fullName ?? NOT_AVAILABLE}</td>
					<td className="text-center">{item.dateOfBirth ?? NOT_AVAILABLE}</td>
					<td className="text-center">{item.phone ?? NOT_AVAILABLE}</td>
					<td className="text-center">{item.email ?? NOT_AVAILABLE}</td>
					<td className="text-center">
						{item.contractQuantity ?? NOT_AVAILABLE}
					</td>
					<td className="text-center">{item.isMember ? "Y" : "N"}</td>
					<td className="text-center">
						{item.contractFinalAmount ?? NOT_AVAILABLE}
					</td>
				</tr>
			))
		) : (
			<tr>
				{thead.map((item, idx) => (
					<td key={item + idx} className="text-center"></td>
				))}
			</tr>
		);
	}, [listCustomerStore]);

	return (
		<TableList
			title={`${t("TOTAL")} (${totalElements} ${t("CASES")})`}
			thead={thead}
			tbody={tbody}
			countPage={totalPagesStore}
			currentPage={currentPageStore}
			handleChangePage={handleChangePage}
		/>
	);
};

export default memo(CustomerList);
