import React, { useCallback, useEffect, useState } from "react";
import WrapperPage from "../../components/WrapperPage";
import { useLocale } from "../../hooks/useLocale";
import HeaderMini from "../../components/HeaderMini";
import { CHILD_LINK, PARENT_LINK } from "../../common/define";
import useActiveCurrentPage from "../../hooks/useActiveCurrentPage";
import { RootState, globalStore } from "../../state/store";
import {
	CREATE_CONFIG_ACTUAL_LOSS,
	GET_CONFIG_ACTUAL_LOSS,
} from "../../state/configActualLoss/actions";
import {
	CREATE_POST_TRANSIT_CONFIG,
	GET_LIST_PRODUCT_POST_TRANSIT_CONFIG,
} from "../../state/listProductPostTransit/actions";
import { useSelector } from "react-redux";
import { UnknownAction } from "@reduxjs/toolkit";
import SettingInsuredItemsList from "../../components/Setting/InsuredItems/list";
import {
	CREATE_CONFIG_MEDICAL,
	GET_CONFIG_MEDICAL,
} from "../../state/setting/insuredItems/medical/actions";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import classNames from "classnames";
import _ from "lodash";
import Swal from "sweetalert2";

const SettingInsuredItemsPage: React.FC = () => {
	const { t } = useLocale();

	const INSURE_TYPE_LIST = [
		{
			key: "MEDICAL_SERVICES",
			tab: 0,
			listAPI: GET_CONFIG_MEDICAL,
			createAPI: CREATE_CONFIG_MEDICAL,
			type: "MEDICAL",
		},
		{
			key: "ACTUAL_LOSS_INSURANCE",
			tab: 1,
			listAPI: GET_CONFIG_ACTUAL_LOSS,
			createAPI: CREATE_CONFIG_ACTUAL_LOSS,
			type: "ACTUAL_LOSS",
		},
		{
			key: "POST_TRANSIT_INSURANCE",
			tab: 2,
			listAPI: GET_LIST_PRODUCT_POST_TRANSIT_CONFIG,
			createAPI: CREATE_POST_TRANSIT_CONFIG,
			type: "POST_TRANSIT",
		},
	];

	const actualLossConfigs = useSelector(
		(state: RootState) => state.configActualLossReducers.data,
	);

	const postTransitConfigs = useSelector(
		(state: RootState) =>
			state.listProductPostTransitReducers.postTransitConfigs,
	);

	const medicalConfigs = useSelector(
		(state: RootState) => state.configMedicalReducers.data,
	);

	const [tab, setTab] = useState(INSURE_TYPE_LIST[0]);
	const [isGetList, setIsGetList] = useState(false);

	useActiveCurrentPage(CHILD_LINK.INSURED_ITEMS, PARENT_LINK.SETTING);

	const initConfig = useCallback(async () => {
		await globalStore.dispatch(tab.listAPI() as unknown as UnknownAction);
	}, [tab, isGetList]);

	useEffect(() => {
		initConfig();
	}, [tab, isGetList]);

	const schema = yup
		.object({
			name: yup.string().required(t("REQUIRED", { field: t("INSURED_ITEMS") })),
			typeValue: yup
				.string()
				.required(t("REQUIRED", { field: t("TYPE_OF_INPUT") })),
		})
		.required();

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: { typeValue: "NUMBER" },
	});

	const onSubmit = async (data: any) => {
		const swalWithBootstrapButtons = Swal.mixin({
			customClass: {
				confirmButton: "btn btn-secondary",
				cancelButton: "btn btn-light-indigo me-2",
			},
			confirmButtonColor: "red",
			buttonsStyling: false,
		});
		swalWithBootstrapButtons
			.fire({
				reverseButtons: true,
				text: "Are you sure you want to create?",
				showCancelButton: true,
				confirmButtonColor: "red",
				cancelButtonColor: "#EAEFF4",
				confirmButtonText: "Ok",
			})
			.then(async (_result: any) => {
				if (_result.isConfirmed) {
					console.log("data-created-item-id", _result);
					await globalStore.dispatch(
						tab.createAPI({
							name: data.name,
							typeValue: data.typeValue,
							async callback() {
								Swal.fire({
									title: t
										? t("ANY_SUCCESSFULLY", {
												any: `${t("ADD")}`,
											})
										: "",
									icon: "success",
								}).then(async (result2) => {
									if (result2.isConfirmed) {
										reset();
										setIsGetList(!isGetList);
									}
								});
							},
						}) as unknown as UnknownAction,
					);
				}
			});
	};

	return (
		<WrapperPage className="container-fluid py-3">
			<HeaderMini
				title={`${t("INSURED_ITEMS")}`}
				breadcrumbs={[t("SETTING"), t("INSURED_ITEMS")]}
			/>
			<div id="table-tab">
				<div className="left-side">
					<h5 className="border-bottom pb-3 px-3">{t("INSURE_TYPE")}</h5>
					<div className="wrap border-bottom">
						{INSURE_TYPE_LIST.map((item) => (
							<button
								key={item.tab}
								onClick={() => setTab(item)}
								className={`setting-tab btn btn-light text-dark w-100 d-flex align-items-center mb-3 ${item.key === tab.key ? "active-btn" : ""}`}
							>
								<i className="ti ti-file fs-6 me-2" />
								{t(item.key)}
							</button>
						))}
					</div>
				</div>
				<div className="right-side">
					<h5 className="border-bottom pb-3 px-3">
						{t("ADD_NEW_INSURED_ITEMS")}
					</h5>
					<div className="wrap">
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className="mb-4">
								<label className="form-label" htmlFor="name">
									{t("INSURED_ITEMS")}
								</label>
								<input
									type="text"
									className={classNames(
										"form-control",
										errors?.name ? "is-invalid" : "",
									)}
									id="name"
									placeholder={t("PLEASE_ENTER_YOUR_DETAILS")}
									{...register("name")}
								/>
							</div>

							<div className="mb-4">
								<label className="form-label" htmlFor="validationDefault03">
									{t("TYPE_OF_INPUT")}
								</label>
								<div className="controls d-flex flex-wrap gap-1">
									<fieldset className="me-4">
										<div className="form-check py-2">
											<input
												type="radio"
												value="NUMBER"
												id="number"
												className={`form-check-input secondary`}
												{...register("typeValue")}
											/>
											<label className="form-check-label" htmlFor="number">
												{t("INSURED_NUMBER")}
											</label>
										</div>
									</fieldset>

									<fieldset className="me-4">
										<div className="form-check py-2">
											<input
												type="radio"
												value="BOOLEAN"
												id="boolean"
												className={`form-check-input secondary`}
												{...register("typeValue")}
											/>
											<label className="form-check-label" htmlFor="boolean">
												{t("PROVIDED_UNPROVIDED")}
											</label>
										</div>
									</fieldset>
								</div>
							</div>
							<div className="d-flex justify-content-end">
								<button className="btn btn-success" type="submit">
									{t("ADD")}
								</button>
							</div>
						</form>

						{tab.tab == 0 && medicalConfigs?.length ? (
							<SettingInsuredItemsList items={medicalConfigs} />
						) : (
							<></>
						)}

						{tab.tab == 1 && actualLossConfigs?.length ? (
							<SettingInsuredItemsList items={actualLossConfigs} />
						) : (
							<></>
						)}

						{tab.tab == 2 && postTransitConfigs?.length ? (
							<SettingInsuredItemsList items={postTransitConfigs} />
						) : (
							<></>
						)}
					</div>
				</div>
			</div>
		</WrapperPage>
	);
};

export default SettingInsuredItemsPage;
