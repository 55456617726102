import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IError } from "../../common/define";

export interface ICustomerNote {
	id: number;
	customer: {
		id: number;
		fullName: string;
		fullNameEn: string;
		idCard: string;
		code: string;
		email: string;
		phone: string;
		dateOfBirth: string;
		registrationDate: string;
	};
	note: string;
	createdBy: {
		id: number;
		code: string;
		phone: string;
		role: string;
		username: string;
		email: string;
		fullName: string;
		idCard: string;
		authorities: string;
		gender: string;
	};
	createdDate: string;
}

export interface IListCustomerNote {
	data: {
		content: ICustomerNote[];
		pageNumber: number;
		pageSize: number;
		totalElements: number;
		totalPages: number;
	};
}

export interface IAuth {
	access_token: string;
	access_token_expires_dttm: string;
	refresh_token: string;
	refresh_token_expires_dttm: string;
}

interface IOtherState {
	isLoading: boolean;
}

const initialState: IListCustomerNote & IOtherState & IError = {
	data: {
		content: [],
		pageNumber: 0,
		pageSize: 0,
		totalElements: 0,
		totalPages: 0,
	},
	result_code: "",
	result_msg: "",
	code: "",
	isLoading: false,
};

const listCustomerNoteSlice = createSlice({
	name: "listCustomerNoteSlice",
	initialState,
	reducers: {
		updateListCustomerNote: (state, action: PayloadAction<any>) => {
			return {
				...state,
				data: action.payload,
			};
		},
		setErrorCode: (state, action: PayloadAction<string>) => {
			state.code = action.payload;
			return state;
		},
		reset: () => initialState,
	},
	// extraReducers: (builder) => {
	// 	builder.addCase(LOGIN_ACTION.pending, (state) => {
	// 		state.isLoading = true;
	// 	}),
	// 		builder.addCase(LOGIN_ACTION.fulfilled, (state) => {
	// 			state.isLoading = false;
	// 		}),
	// 		builder.addCase(LOGIN_ACTION.rejected, (state) => {
	// 			state = {
	// 				...initialState,
	// 			};
	// 			// window.open(getTwitterOauthUrl());
	// 		});
	// },
});

export const listCustomerNoteActions = listCustomerNoteSlice.actions;
export const listCustomerNoteReducers = listCustomerNoteSlice.reducer;

// export const authSelectors = {
// 	isLoading: (state: RootState) => state.authReducers.isLoading,
// 	code: (state: RootState) => state.authReducers.code,
// };
