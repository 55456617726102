import React, { memo, useMemo } from "react";
import { NOT_AVAILABLE } from "../../common/define";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { formatMoney } from "../../common/utils";

const TableTotalAmount: React.FC = () => {
	const actualLossPrice = useSelector(
		(state: RootState) =>
			state.contractInfoReducers.data?.actualLossPrice.price,
	);
	const postTransitPrice = useSelector(
		(state: RootState) =>
			state.contractInfoReducers.data?.postTransitPrice.price,
	);
	const serviceAmount = useSelector(
		(state: RootState) => state.contractInfoReducers.data?.serviceAmount,
	);
	const productPrice = useSelector(
		(state: RootState) => state.contractInfoReducers.data?.productPrice.price,
	);

	const thead = ["실손보험료", "이후송보험료", "서비스료", "총결제금액"];

	const tbody = useMemo(() => {
		return (
			<tr>
				<td className="text-center">
					{actualLossPrice ? formatMoney(actualLossPrice) : NOT_AVAILABLE}
				</td>
				<td className="text-center">
					{postTransitPrice ? formatMoney(postTransitPrice) : NOT_AVAILABLE}
				</td>
				<td className="text-center">
					{serviceAmount ? formatMoney(serviceAmount) : NOT_AVAILABLE}
				</td>
				<td className="text-center">
					{productPrice ? formatMoney(productPrice) : NOT_AVAILABLE}
				</td>
			</tr>
		);
	}, [actualLossPrice, postTransitPrice, serviceAmount, productPrice]);

	return (
		<div className="row">
			<table className="table border-radius text-nowrap fs-2">
				<thead>
					<tr>
						{thead?.map((tit) => (
							<th className="text-center" key={"thead" + tit} scope="col">
								{tit}
							</th>
						))}
					</tr>
				</thead>
				<tbody>{tbody}</tbody>
			</table>
		</div>
	);
};

export default memo(TableTotalAmount);
