import React, { useEffect } from "react";
import { useLocale } from "../../hooks/useLocale";
import SelectCheckBox from "../SelectCheckBox";
import { globalStore, RootState } from "../../state/store";
import { GET_CONFIG_OVERSEA_ACTION } from "../../state/configOverseaService/actions";
import { UnknownAction } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { NOT_AVAILABLE } from "../../common/define";
import { addNewProductActions } from "../../state/listProduct/addNewProductSlice";

const ProductInfoOverseaAddNew = () => {
	const { t } = useLocale();

	const config = useSelector(
		(state: RootState) => state.configOverseaReducers.data,
	);

	const [currentConfig, setCurrentConfig] = React.useState(
		config?.map((item) => ({
			id: item.id,
			code: item.code,
			name: item.name,
			isChecked: true,
		})),
	);

	const handleSelectStatus = (e: any, item: any) => {
		const newConfig =
			currentConfig &&
			currentConfig.map((configItem) => {
				if (configItem.id === item.id) configItem.isChecked = e[0] === "YES";

				return configItem;
			});

		newConfig &&
			globalStore.dispatch(
				addNewProductActions.updateItem(newConfig) as unknown as UnknownAction,
			);

		setCurrentConfig(newConfig);
	};

	const initConfig = () => {
		globalStore.dispatch(
			GET_CONFIG_OVERSEA_ACTION({
				callbackSuccess(data) {
					const newConfig = data?.map((item: any) => ({
						id: item.id,
						code: item.code,
						name: item.name,
						isChecked: true,
					}));

					globalStore.dispatch(
						addNewProductActions.updateItem(
							newConfig,
						) as unknown as UnknownAction,
					);
				},
			}) as unknown as UnknownAction,
		);
	};

	useEffect(() => {
		initConfig();
	}, []);

	return (
		<>
			<div className="card-header">
				<h4 className="mb-0">{t("REWARD_INFORMATION")}</h4>
			</div>
			<div className="card-body text-dark">
				<div className="row mb-1">
					<div className="fs-4 d-flex justify-content-between align-items-center align-self-center fw-bolder">
						<p className="mb-0">{t("OVERSEAS_MEDICAL_SUPPORT_SERVICE")}</p>
					</div>
				</div>
				{currentConfig && currentConfig?.length > 0
					? currentConfig.map((item) => (
							<div className="row mb-2">
								<div className="col-4 justify-content-center align-self-center fw-bolder">
									{t(item.name)}
								</div>
								<div className="col-5 justify-content-center align-self-center">
									<SelectCheckBox
										selectOnlyOne
										dataChecked={item.isChecked ? "YES" : "NO"}
										classColor="secondary"
										handleOnChange={(e) => handleSelectStatus(e, item)}
										data={["YES", "NO"]}
									/>
								</div>
							</div>
						))
					: NOT_AVAILABLE}
			</div>
		</>
	);
};

export default ProductInfoOverseaAddNew;
