import {
	ActionReducerMapBuilder,
	AsyncThunk,
	combineReducers,
	configureStore,
} from "@reduxjs/toolkit";

import storage from "redux-persist/lib/storage";
import {
	FLUSH,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
	REHYDRATE,
	persistReducer,
	persistStore,
} from "redux-persist";
import { userSignUpReducers } from "./userSignUp/userSignUpSlice";
import { countryReducers } from "./country/countrySlice";
import { authReducers } from "./auth/authSlice";
import { userInfoReducers } from "./userInfo/userInfoSlice";
import { listCustomerReducers } from "./listCustomer/listCustomerSlice";
import { customerInfoReducers } from "./customerInfo/customerInfoSlice";
import { listCustomerNoteReducers } from "./listCustomerNote/listCustomerNoteSlice";
import { memberInfoReducers } from "./memberInfo/memberInfoSlice";
import { listMemberReducers } from "./listMember/listMemberSlice";
import { listProductActualLossReducers } from "./listProductActualLoss/listProductActualLossSlice";
import { productActualLossDetailReducers } from "./productActualLoss/productActualLossSlice";
import { productPostTransitDetailReducers } from "./productPostTransit/productPostTransitSlice";
import { configActualLossReducers } from "./configActualLoss/configActualLossSlice";
import { listProductPostTransitReducers } from "./listProductPostTransit/listProductPostTransitSlice";
import { listContractReducers } from "./listContract/listContractSlice";
import { contractInfoReducers } from "./contractInfo/contractInfoSlice";
// import { listProductReducers } from "./listProduct/listProducSlice";
import { configMedicalReducers } from "./setting/insuredItems/medical/configMedicalSlice";
import { contractStatusReducers } from "./contractInfo/contractStatusSlice";
import { listContractMessageReducers } from "./contractInfo/contractMessage";
import { configOverseaReducers } from "./configOverseaService/configOverseaService";
import { listProductReducers } from "./listProduct/listProducSlice";
import { addNewProductReducers } from "./listProduct/addNewProductSlice";
import { productDetailReducers } from "./listProduct/productDetailSlice";
// import { addNewProductReducers } from "./listProduct/addNewProductSlice";

const persistConfig = {
	key: "root",
	version: 1,
	storage,
	blacklist: ["txSliceReducers"],
};

const rootReducer = combineReducers({
	userSignUpReducers,
	countryReducers,
	authReducers,
	userInfoReducers,
	listCustomerReducers,
	customerInfoReducers,
	listCustomerNoteReducers,
	memberInfoReducers,
	listMemberReducers,
	listProductActualLossReducers,
	productActualLossDetailReducers,
	configActualLossReducers,
	listProductPostTransitReducers,
	productPostTransitDetailReducers,
	listContractReducers,
	contractInfoReducers,
	listProductReducers,
	configMedicalReducers,
	contractStatusReducers,
	configOverseaReducers,
	addNewProductReducers,
	productDetailReducers,
	listContractMessageReducers,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware = (getDefaultMiddleware: any) =>
	getDefaultMiddleware({
		serializableCheck: {
			ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
		},
	});

let store;

store = configureStore({
	reducer: persistedReducer,
	middleware,
});

if (typeof window === "undefined") {
	store = configureStore({
		reducer: rootReducer,
		middleware,
	});
}

export const globalStore = store;
export const persistor = persistStore(store);
// Infer the type of makeStore
export type AppStore = typeof store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export function addAsyncReducers<State>(
	builder: ActionReducerMapBuilder<any>,
	action: AsyncThunk<any, any, any>,
	initialState: any,
) {
	builder.addCase(action.pending, (state) => {
		state.isLoading = true;
	});
	builder.addCase(action.fulfilled, (state) => {
		state.isLoading = false;
	});
	builder.addCase(action.rejected, (state) => {
		return {
			...initialState,
		};
	});
}
