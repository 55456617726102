import React, { memo } from "react";

export type TStatusColorClass =
	| "applicationApplied"
	| "terminationRequested"
	| "applicationRejected"
	| "terminationRejected"
	| "applicationApproved"
	| "terminationCompleted"
	| "contractInProgress"
	| "expired"
	| "cancellationRequested"
	| "cancellationRejected"
	| "cancellationCompleted"
	| undefined;

interface IProps {
	colorClass?: string;
	textStatus?: string;
}

const TextStatus: React.FC<IProps> = ({
	textStatus = "Status",
	colorClass = "applicationApplied",
}) => {
	return (
		<p className={`${colorClass} textStatus badge rounded-1 mb-0`}>
			{textStatus}
		</p>
	);
};

export default memo(TextStatus);
