import { UnknownAction } from "@reduxjs/toolkit";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CHILD_LINK, NOT_AVAILABLE, PARENT_LINK } from "../../../common/define";
import HeaderMini from "../../../components/HeaderMini";
import ProductInformationOnlyView from "../../../components/ProductInformationOnlyView";
import WrapperPage from "../../../components/WrapperPage";
import useActiveCurrentPage from "../../../hooks/useActiveCurrentPage";
import { useLocale } from "../../../hooks/useLocale";
import {
	GET_PRODUCT_DETAIL,
	UPDTE_PRODUCT_STATUS,
} from "../../../state/listProduct/actions";
import { globalStore, RootState } from "../../../state/store";
// import ProductRewardInfoOnlyView from "../../../components/ProductRewardInfoOnlyView";
import Swal from "sweetalert2";
import ProductInfoActualOnlyView from "../../../components/ProductInfoActualOnlyView";
import ProductInfoPostTransitOnlyView from "../../../components/ProductInfoPostTransitOnlyView";
import ProductRewardInfoOnlyView from "../../../components/ProductRewardInfoOnlyView";

const ViewProductDetailPage: React.FC = () => {
	const { t } = useLocale();
	const { id } = useParams();

	console.log("id-get-info", id);

	const fireAlertSuccess = useCallback(async () => {
		await Swal.fire({
			title: `${t("ANY_SUCCESSFULLY", {
				any: t("EDIT"),
			})}`,
			icon: "success",
		});
	}, []);

	const productDetail = useSelector(
		(state: RootState) => state.productDetailReducers.data,
	);

	const [productStatus, setProductStatus] = useState(
		productDetail?.status ? t(productDetail?.status) : NOT_AVAILABLE,
	);

	const initProductDetail = () => {
		id &&
			globalStore.dispatch(
				GET_PRODUCT_DETAIL({ id: Number(id) }) as unknown as UnknownAction,
			);
	};

	const updateStatus = async () => {
		await globalStore.dispatch(
			UPDTE_PRODUCT_STATUS({
				submitData: {
					id,
					status: productStatus,
				},
				callbackSuccess() {
					fireAlertSuccess();
				},
			}) as unknown as UnknownAction,
		);
	};

	useEffect(() => {
		initProductDetail();
	}, [id]);

	useEffect(() => {
		if (productDetail) {
			setProductStatus(productDetail.status);
		}
	}, [productDetail?.status]);

	useActiveCurrentPage(CHILD_LINK.VIEW_PRODUCT, PARENT_LINK.PRODUCT);

	return (
		<WrapperPage className="container-fluid py-3">
			<HeaderMini
				title={`${t("PRODUCT_ID_INFORMATION", {
					id: productDetail?.code,
				})}`}
				breadcrumbs={[t("PRODUCT_MANAGEMENT"), t("SALES_PRODUCT_MANAGEMENT")]}
			/>

			<ProductInformationOnlyView
				productNumber={`${productDetail?.code || NOT_AVAILABLE}`}
				productName={productDetail?.name || NOT_AVAILABLE}
				minAge={productDetail?.minAge || 0}
				maxAge={productDetail?.maxAge || 0}
				file={productDetail?.feeFile ? productDetail.feeFile : undefined}
				status={
					productDetail?.status ? t(productDetail?.status) : NOT_AVAILABLE
				}
				productStatus={productStatus}
				setProductStatus={setProductStatus}
			/>

			<div className="card">
				<ProductRewardInfoOnlyView />
				<ProductInfoPostTransitOnlyView />
				<ProductInfoActualOnlyView />
			</div>

			<div className="p-3">
				<div>
					<div className="text-end">
						<button
							type="submit"
							className="btn bg-danger-subtle text-danger ms-6 rounded-pill me-4"
						>
							{t("CANCEL")}
						</button>
						<button
							type="submit"
							onClick={updateStatus}
							className="btn btn-success rounded-pill px-4"
						>
							{t("SAVE")}
						</button>
					</div>
				</div>
			</div>
		</WrapperPage>
	);
};

export default ViewProductDetailPage;
