import React, { memo, useCallback } from "react";
import { useSelector } from "react-redux";
import { NOT_AVAILABLE, swalWithBootstrapRed } from "../../common/define";
import { useLocale } from "../../hooks/useLocale";
import { RootState, globalStore } from "../../state/store";
import { getFileNameWithURL } from "../../common/utils";
import { RESEND_MAIL } from "../../state/contractInfo/actions";
import { useParams } from "react-router-dom";
import { useAppModalContext } from "../../contexts/ModalContext";
import { UnknownAction } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import ModalShipmentHistory from "../../components/Modals/ModalShipmentHistory";

const InsuranceNonApproved: React.FC = () => {
	const { t } = useLocale();
	const { id } = useParams();
	const { openModal } = useAppModalContext();

	const contractDetails = useSelector(
		(state: RootState) => state.contractInfoReducers.data,
	);
	const fireAlertResendSuccess = useCallback(async () => {
		await Swal.fire({
			title: `${t("ANY_SUCCESSFULLY", {
				any: t("RESEND_MAIL"),
			})}`,
			icon: "success",
		});
	}, []);
	const resendMail = async () => {
		swalWithBootstrapRed
			.fire({
				reverseButtons: true,
				text: t("CONFIRM_RESEND_MAIL"),
				showCancelButton: true,
				confirmButtonColor: "red",
				cancelButtonColor: "#EAEFF4",
				confirmButtonText: t("OK"),
				cancelButtonText: t("CANCEL_TEXT"),
			})
			.then(async ({ isConfirmed }) => {
				if (!isConfirmed) return;
				await globalStore.dispatch(
					RESEND_MAIL({
						id: Number(id),
						callbackSuccess() {
							fireAlertResendSuccess();
						},
					}) as unknown as UnknownAction,
				);
			});
	};
	const openViewShipmentHistory = () => {
		openModal &&
			openModal?.({
				title: "",
				style: {
					zIndex: 9,
					top: "0",
					background: "transparent",
				},
				body: <ModalShipmentHistory contractId={id} t={t} />,
			});
	};

	return (
		<div className="card-body text-dark ">
			<div className="row mb-4">
				<div className="fs-4 d-flex justify-content-between align-items-center align-self-center fw-bolder">
					<p className="mb-0">{t("MEMBER_CERTIFICATION")}</p>
				</div>
			</div>
			<div className="row mb-4">
				<div className="col-4 justify-content-center align-self-center fw-bolder">
					{t("CERTIFICATE_FILE")}
				</div>
				<div className="text-decoration-underline col-6 justify-content-center align-self-center">
					{contractDetails?.certificateFile ? (
						<div
							role="button"
							onClick={() =>
								window.open(contractDetails?.certificateFile, "_blank")
							}
							className="col-5 text-decoration-underline justify-content-center align-self-center"
						>
							{getFileNameWithURL(contractDetails?.certificateFile)}
						</div>
					) : (
						NOT_AVAILABLE
					)}
				</div>
			</div>
			<div className="row mb-4">
				<div className="col-4 justify-content-center align-self-center fw-bolder">
					{t("CERTIFICATE_NUMBER")}
				</div>
				<div className="col-6 justify-content-center align-self-center">
					{contractDetails?.certificateNumber || NOT_AVAILABLE}
				</div>
			</div>

			<div className="row mb-4">
				<div className="col-4 justify-content-center align-self-center fw-bolder">
					{t("INSURANCE_FORM_START_DATE")}
				</div>
				<div className="col-6 justify-content-center align-self-center">
					{contractDetails?.insuranceStartDate || NOT_AVAILABLE}
				</div>
			</div>

			<div className="row mb-4">
				<div className="col-4 justify-content-center align-self-center fw-bolder">
					{t("INSURANCE_FORM_END_DATE")}
				</div>
				<div className="col-6 justify-content-center align-self-center">
					{contractDetails?.insuranceEndDate || NOT_AVAILABLE}
				</div>
			</div>

			{/* <div className="row ">
				<div className="col-4 justify-content-center align-self-center fw-bolder">
					{t("SHIPMENT_DETAIL")}
				</div>
				<div className="col-6 justify-content-center align-self-center">
					{NOT_AVAILABLE}
				</div>
			</div> */}
			{contractDetails?.certificateFile && (
				<div className="row">
					<div className="col-4 justify-content-center align-self-center fw-bolder">
						{t("SHIPMENT_DETAIL")}
					</div>
					<div className="col-6 justify-content-center align-self-center">
						<div className="d-flex justify-content-start align-items-center">
							<button
								onClick={resendMail}
								type="button"
								className="me-3 justify-content-center btn mb-1 btn-success d-flex align-items-center"
							>
								{t("RESEND_MAIL")}
							</button>
							<button
								onClick={openViewShipmentHistory}
								className="btn mb-1 d-block btn-outline-secondary waves-effect waves-light"
								type="button"
							>
								<i className="ti ti-circle-check fs-4 me-2"></i>
								{t("VIEW_SHIPMENT_HISTORY")}
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default memo(InsuranceNonApproved);
