import React, { memo, useCallback, useEffect } from "react";
import { useLocale } from "../../hooks/useLocale";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { globalStore, RootState } from "../../state/store";
import { LOGOUT_ACTION } from "../../state/auth/actions";
import { UnknownAction } from "@reduxjs/toolkit";
import { UserIcon } from "../Icons/user";
interface WrapperProps {
	className?: string;
}

const Header: React.FC<WrapperProps> = ({ className }) => {
	const navigate = useNavigate();
	const { activeLang, switchLanguage } = useLocale();

	const accessToken = useSelector(
		(state: RootState) => state.authReducers.access_token,
	);

	console.log("accessToken+++", accessToken);

	const toggleLangue = useCallback(() => {
		switchLanguage(activeLang === "en" ? "ko" : "en");
	}, [activeLang]);

	const goToSignIn = () => {
		navigate("/sign-in");
	};

	const goToMyPage = () => {
		navigate("/my-page");
	};

	const handleLogout = () => {
		switchLanguage("ko");
		globalStore.dispatch(
			LOGOUT_ACTION({
				callbackSuccess() {
					navigate("/sign-in");
				},
			}) as unknown as UnknownAction,
		);
	};

	useEffect(() => {
		if (!accessToken.length) {
			navigate("/sign-in");
		}
	}, [accessToken]);

	return (
		<header id="header-common" className={`${className}`}>
			<div className="side left-side">
				{/* <i className="ti ti-menu-2"></i> */}
			</div>
			<div className="side right-side">
				{process.env.REACT_APP_ENV === "development" && (
					<i onClick={toggleLangue} className="ti ti-world"></i>
				)}

				<button className="btn" onClick={goToMyPage}>
					<UserIcon />
				</button>
				<button
					onClick={handleLogout}
					className="relative w-100 btn btn-danger py-2 w-100 btn btn-success"
				>
					Logout
				</button>
				{/* <i onClick={goToSignIn} className="ti ti-user-circle"></i> */}
			</div>
		</header>
	);
};

export default memo(Header);
