import React, { useCallback, useState } from "react";
import { useLocale } from "../../../hooks/useLocale";
import WrapperPage from "../../../components/WrapperPage";
import HeaderMini from "../../../components/HeaderMini";
import { CHILD_LINK, PARENT_LINK } from "../../../common/define";
import { useNavigate, useParams } from "react-router-dom";
import InsuranceInformation from "../../../components/InsuranceInformation";
import RewardInformationActualLoss from "../../../components/RewardInformationActualLoss";
import useActiveCurrentPage from "../../../hooks/useActiveCurrentPage";
import { globalStore, RootState } from "../../../state/store";
import {
	GET_PRODUCT_ACTUAL_LOSS_DETAIL,
	UPLOAD_FILE_PRODUCT,
} from "../../../state/productActualLoss/actions";
import { UnknownAction } from "@reduxjs/toolkit";
import useEffectOnce from "../../../hooks/useEffectOnce";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { scrollPageToTop } from "../../../common/utils";

const swalWithBootstrapButtons = Swal.mixin({
	customClass: {
		confirmButton: "btn btn-secondary",
		cancelButton: "btn btn-light-indigo me-2",
	},
	confirmButtonColor: "red",
	buttonsStyling: false,
});

const EditActualLossPage: React.FC = () => {
	const { t } = useLocale();
	const { id } = useParams();
	const navigate = useNavigate();

	const SWAL_ADD_NEW_PRODUCT = {
		reverseButtons: true,
		text: t("SURE_TO_EDIT"),
		showCancelButton: true,
		confirmButtonColor: "red",
		cancelButtonColor: "#EAEFF4",
		confirmButtonText: t("OK"),
		cancelButtonText: t("CANCEL_TEXT"),
	};

	const product = useSelector(
		(state: RootState) => state.productActualLossDetailReducers.data,
	);
	const productLoading = useSelector(
		(state: RootState) => state.productActualLossDetailReducers.isLoading,
	);

	const [fileUpload, setFileUpload] = useState();

	const initProductActualLossDetail = async () => {
		await globalStore.dispatch(
			GET_PRODUCT_ACTUAL_LOSS_DETAIL({
				id: Number(id),
			}) as unknown as UnknownAction,
		);
	};

	const fireAlertSuccess = useCallback(async () => {
		await Swal.fire({
			title: `${t("ANY_SUCCESSFULLY", {
				any: t("EDIT"),
			})}`,
			icon: "success",
		});
	}, []);

	const fireAlertFailed = useCallback(async (code: string) => {
		await Swal.fire({
			title: `${t(code)}`,
			icon: "error",
		});
	}, []);

	const onUpload = (data: any) => {
		console.log("get-file", data);
		setFileUpload(data);
	};

	const onSaveProduct = async () => {
		if (!product.id || !fileUpload) return;

		swalWithBootstrapButtons
			.fire(SWAL_ADD_NEW_PRODUCT)
			.then(async ({ isConfirmed }) => {
				if (!isConfirmed) return;
				await globalStore.dispatch(
					UPLOAD_FILE_PRODUCT({
						productId: product.id!,
						file: fileUpload,
						callbackSuccess() {
							initProductActualLossDetail();
							fireAlertSuccess();
						},
						callbackFailed(data) {
							fireAlertFailed(data);
						},
					}) as unknown as UnknownAction,
				);
			});
	};

	useEffectOnce(initProductActualLossDetail);
	useActiveCurrentPage(CHILD_LINK.ACTUAL_LOSS_PRODUCT, PARENT_LINK.PRODUCT);

	return (
		<WrapperPage isLoading={productLoading} className="container-fluid py-3">
			<HeaderMini
				title={`${t("EDIT_ACTUAL_LOSS_ID", {
					id: product.code,
				})}`}
				breadcrumbs={[t("PRODUCT_MANAGEMENT"), t("ACTUAL_LOSS_INSURANCE")]}
			/>

			<InsuranceInformation
				id={product.code}
				name={product.name}
				file={product.feeFile}
				productId={product.id}
				isDisabled={true}
				onUpload={onUpload}
			/>

			{product.productFeatures.length > 0 && (
				<div className="card">
					<RewardInformationActualLoss
						data={product.productFeatures}
						isDisabled={true}
					/>
				</div>
			)}

			{!product?.feeFile && (
				<div className="p-3">
					<div>
						<div className="text-end">
							<button
								type="submit"
								className="btn bg-danger-subtle text-danger ms-6 rounded-pill me-4"
							>
								{t("CANCEL")}
							</button>
							<button
								onClick={onSaveProduct}
								type="submit"
								className="btn btn-success rounded-pill px-4"
							>
								{t("SAVE")}
							</button>
						</div>
					</div>
				</div>
			)}
		</WrapperPage>
	);
};

export default EditActualLossPage;
