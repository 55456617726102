import React, { memo, useCallback, useEffect, useState } from "react";
import { useLocale } from "../../hooks/useLocale";
import { useParams } from "react-router-dom";
import { globalStore, RootState } from "../../state/store";
import {
	GET_MEMBER_NOTE,
	UPDATE_MEMBER_NOTE,
} from "../../state/memberInfo/actions";
import { UnknownAction } from "@reduxjs/toolkit";
import useEffectOnce from "../../hooks/useEffectOnce";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

const Notes: React.FC = () => {
	const { t } = useLocale();
	const { id } = useParams();

	console.log("memberid note-----", id);

	const memberNoteStore = useSelector(
		(state: RootState) => state.memberInfoReducers.note,
	);

	console.log("ieieieieiie-0sosos-00----", memberNoteStore);

	const [inputValue, setInputValue] = useState(memberNoteStore);
	console.log("inputValueieieieie", inputValue);

	const initMemberNote = useCallback(async () => {
		await globalStore.dispatch(
			GET_MEMBER_NOTE({ id: Number(id) }) as unknown as UnknownAction,
		);
	}, [id]);

	const onChangeNote = (e: any) => {
		const value = e.target.value;
		setInputValue(value);
	};

	const onSaveNote = () => {
		if (id) {
			const swalWithBootstrapButtons = Swal.mixin({
				customClass: {
					confirmButton: "btn btn-secondary",
					cancelButton: "btn btn-light-indigo me-2",
				},
				confirmButtonColor: "red",
				buttonsStyling: false,
			});
			swalWithBootstrapButtons
				.fire({
					reverseButtons: true,
					text: t("SURE_TO_EDIT"),
					showCancelButton: true,
					confirmButtonColor: "red",
					cancelButtonColor: "#EAEFF4",
					confirmButtonText: t("OK"),
					cancelButtonText: t("CANCEL_TEXT"),
				})
				.then(async (_result) => {
					if (_result.isConfirmed) {
						await globalStore.dispatch(
							UPDATE_MEMBER_NOTE({
								t,
								memberId: Number(id),
								note: inputValue,
							}) as unknown as UnknownAction,
						);
					}
				});
		}
	};

	useEffect(() => {
		setInputValue(memberNoteStore);
	}, [memberNoteStore]);

	useEffectOnce(initMemberNote);

	return (
		<div className="col-md-7">
			<div className="card">
				<div
					style={{
						height: 82.59,
					}}
					className="card-header hide-border d-flex justify-content-between align-items-center card-header--no-button"
				>
					<h4 className="mb-0">{t("NOTES")}</h4>
					<div>
						<button
							onClick={onSaveNote}
							type="button"
							className="btn btn-success"
						>
							{t("SAVE")}
						</button>
					</div>
				</div>
				<div style={{ paddingTop: 0 }} className="card-body text-dark">
					<div className="row note-has-grid">
						<textarea
							value={inputValue}
							onChange={onChangeNote}
							className="form-control h-400px"
							placeholder={`${t("PLACEHOLDER_NOTES")}`}
						></textarea>
					</div>
				</div>
			</div>
		</div>
	);
};

export default memo(Notes);
