import { UnknownAction } from "@reduxjs/toolkit";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { CHILD_LINK, PARENT_LINK } from "../../../common/define";
import HeaderMini from "../../../components/HeaderMini";
import InsuranceInformationPostTransit from "../../../components/InsuranceInformationPostTransit";
import RewardPostTransit from "../../../components/RewardPostTransit";
import WrapperPage from "../../../components/WrapperPage";
import useActiveCurrentPage from "../../../hooks/useActiveCurrentPage";
import useEffectOnce from "../../../hooks/useEffectOnce";
import { useLocale } from "../../../hooks/useLocale";
import { listProductPostTransitActions } from "../../../state/listProductPostTransit/listProductPostTransitSlice";
import {
	GET_PRODUCT_POST_TRANSIT_DETAIL,
	UPDATE_PRODUCT_POST_TRANSIT,
} from "../../../state/productPostTransit/actions";
import { RootState, globalStore } from "../../../state/store";
import useAddNewPostTransit from "../addNewPostTransit/useAddNewPostTransit";

const swalWithBootstrapButtons = Swal.mixin({
	customClass: {
		confirmButton: "btn btn-secondary",
		cancelButton: "btn btn-light-indigo me-2",
	},
	confirmButtonColor: "red",
	buttonsStyling: false,
});

const EditPostTransit: React.FC = () => {
	const { t } = useLocale();
	const [fileUpload, setFileUpload] = useState();
	const navigate = useNavigate();
	const SWAL_ADD_NEW_PRODUCT = {
		reverseButtons: true,
		text: t("SURE_TO_EDIT"),
		showCancelButton: true,
		confirmButtonColor: "red",
		cancelButtonColor: "#EAEFF4",
		confirmButtonText: t("OK"),
		cancelButtonText: t("CANCEL_TEXT"),
	};
	const { id } = useParams();

	const { handleChangeCaseName, setCaseName, caseName } =
		useAddNewPostTransit();

	const fireAlertSuccess = useCallback(async () => {
		await Swal.fire({
			title: `${t("ANY_SUCCESSFULLY", {
				any: t("EDIT"),
			})}`,
			icon: "success",
		});
	}, []);

	const fireAlertFailed = useCallback(async (code: string) => {
		await Swal.fire({
			title: `${t(code)}`,
			icon: "error",
		});
	}, []);

	const product = useSelector(
		(state: RootState) => state.productPostTransitDetailReducers.data,
	);

	const initProductPostTransitDetail = async () => {
		await globalStore.dispatch(
			GET_PRODUCT_POST_TRANSIT_DETAIL({
				id: Number(id),
			}) as unknown as UnknownAction,
		);
	};

	const onUpload = (data: any) => {
		console.log("get-file", data);
		setFileUpload(data);
	};

	useEffectOnce(initProductPostTransitDetail);

	const onSaveProduct = async () => {
		if (!product.id) return;

		swalWithBootstrapButtons
			.fire(SWAL_ADD_NEW_PRODUCT)
			.then(async ({ isConfirmed }) => {
				if (!isConfirmed) return;
				await globalStore.dispatch(
					UPDATE_PRODUCT_POST_TRANSIT({
						productId: product.id!,
						name: caseName,
						callbackSuccess() {
							initProductPostTransitDetail();
							fireAlertSuccess();
						},
						callbackFailed(data) {
							fireAlertFailed(data);
						},
					}) as unknown as UnknownAction,
				);
			});
	};

	useEffect(() => {
		if (product.name) {
			setCaseName(product.name);
		}
	}, [product?.name]);

	useEffect(() => {
		if (product.productFeatures.length > 0) {
			globalStore.dispatch(
				listProductPostTransitActions.updatePostTransitConfig(
					product.productFeatures.map((item) => {
						return {
							...item,
							price: item.price ?? 0,
						};
					}),
				),
			);
		}
	}, [product?.productFeatures]);

	useActiveCurrentPage(CHILD_LINK.POST_TRANSIT_PRODUCT, PARENT_LINK.PRODUCT);

	return (
		<WrapperPage className="container-fluid py-3">
			<HeaderMini
				title={`${t("EDIT_POST_TRANSIT_INSURANCE_ID", {
					id,
				})}`}
				breadcrumbs={[t("PRODUCT_MANAGEMENT"), t("POST_TRANSIT_INSURANCE")]}
			/>

			<InsuranceInformationPostTransit
				id={product?.code}
				name={caseName}
				file={product?.feeFile}
				onUpload={onUpload}
				onChange={handleChangeCaseName}
			/>

			{product?.productFeatures.length > 0 && (
				<div className="card">
					<RewardPostTransit isEdit={true} />
				</div>
			)}

			<div className="p-3">
				<div>
					<div className="text-end">
						<button
							onClick={() => navigate(-1)}
							className="btn bg-danger-subtle text-danger ms-6 rounded-pill me-4"
						>
							{t("CANCEL")}
						</button>
						<button
							onClick={onSaveProduct}
							type="submit"
							className="btn btn-success rounded-pill px-4"
						>
							{t("SAVE")}
						</button>
					</div>
				</div>
			</div>
		</WrapperPage>
	);
};

export default EditPostTransit;
