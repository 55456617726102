import React, { memo, useState } from "react";
import { useLocale } from "../../hooks/useLocale";
import SelectCheckBox from "../SelectCheckBox";
import { IConfigActualLoss, IProductFeature } from "../../common/define";

interface IProps {
	data?: IProductFeature[];
	isDisabled?: boolean;
	onChange?: (data: any) => void;
}

// INJURY_DEATH_AND_AFTER_EFFECT
// OVERSEAS_TREATMENT_FOR_INJURY
// OVERSEAS_TREATMENT_FOR_DISEASE
// INJURY_BENEFIT
// INJURY_NON_BENEFIT
// DOMESTIC_ILLNESS_AND_OUTPATIENT
// DOMESTIC_NON_COVERED
// DOMESTIC_NON_BENEFIT
// DOMESTIC_MEDICAL_EXPENSES
// LIABILITY
// SERVE_AND_REPATRIATION
// AIRCRAFT_HIJACKING_COLLATERAL

const RewardInformationActualLoss: React.FC<IProps> = ({
	data,
	isDisabled,
	onChange,
}) => {
	const { t } = useLocale();

	const handleSelectStatus = (dataSelected: string[]) => {
		console.log("dataSelected", dataSelected);
	};

	return (
		<>
			<div className="card-header">
				<h4 className="mb-0">{t("REWARD_INFORMATION")}</h4>
			</div>
			<div className="card-body text-dark">
				{data &&
					data.map((item) => (
						<div className="row mb-4">
							<div className="col-3 justify-content-center align-self-center fw-bolder">
								{t(item.name)}
							</div>
							<div className="col-9 justify-content-center align-self-center position-relative">
								<input
									disabled={isDisabled}
									type="number"
									onWheel={(e: any) => e.target.blur()}
									value={item.price}
									className="form-control"
									placeholder={`${t("PLEASE_ENTER_THE_AMOUNT")}`}
									aria-describedby="basic-addon2"
								/>
								<span className="man-amount">{t("MAN_WON")}</span>
							</div>
						</div>
					))}
			</div>
		</>
	);
};

export default memo(RewardInformationActualLoss);
