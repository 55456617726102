import React from "react";
import "./App.scss";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/home";
import CustomerPage from "./pages/customer";
import CustomerDetailPage from "./pages/customerDetail";
import MemberPage from "./pages/member";
import MemberDetailPage from "./pages/memberDetail";

import ContractViewPage from "./pages/contract/view";
import ContractReapplicationPage from "./pages/contract/reapplication";
import ContractCancellationPage from "./pages/contract/cancellation";
import ContractViewDetailPage from "./pages/contractViewDetail";
import ContractPendingPage from "./pages/contract/pendingApplication";
import ContractEarlyTerminationPage from "./pages/contract/earlyTermination";

import ProductPostTransitInsurance from "./pages/product/postTransit";
import SaleProductManagementPage from "./pages/product/view";
import ActualLossInsurancePage from "./pages/product/actual";
import AddNewPostTransitPage from "./pages/product/addNewPostTransit";

import SettingPage from "./pages/setting";
import SettingInsuredItemsPage from "./pages/settingInsuredItems";
import SignInPage from "./pages/signin";
import MyPage from "./pages/myPage";
import AddNewProductPage from "./pages/product/addNewProduct";
import EditProductPage from "./pages/product/editProduct";
import ViewProductDetailPage from "./pages/product/viewProductDetail";
import AddNewActualLossPage from "./pages/product/addNewActualLoss";
import EditActualLossPage from "./pages/product/editActualLoss";
import EditPostTransit from "./pages/product/editPostTransit";
// import ViewProductDetailPage from "./pages/product/viewProductDetail";

const RouterApp: React.FC = () => {
	return (
		<Router>
			<Routes>
				<Route path="/" element={<MemberPage />} />
				<Route path="sign-in" element={<SignInPage />} />
				<Route path="customer">
					<Route index={true} element={<CustomerPage />} />
					<Route path=":id" element={<CustomerDetailPage />} />
				</Route>
				<Route path="member">
					<Route index={true} element={<MemberPage />} />
					<Route path=":id" element={<MemberDetailPage />} />
				</Route>
				<Route path="contract">
					<Route index element={<ContractViewPage />} />
					<Route path="view" element={<ContractViewPage />} />
					<Route path="view/:id" element={<ContractViewDetailPage />} />
					<Route path="pending" element={<ContractPendingPage />} />
					<Route path="reapplication" element={<ContractReapplicationPage />} />
					<Route path="cancellation" element={<ContractCancellationPage />} />
					<Route
						path="early-termination"
						element={<ContractEarlyTerminationPage />}
					/>
				</Route>
				<Route path="product">
					<Route index element={<SaleProductManagementPage />} />
					<Route path="addNewProduct" element={<AddNewProductPage />} />
					<Route path="editProduct/:id" element={<EditProductPage />} />
					<Route
						path="product-detail/:id"
						element={<ViewProductDetailPage />}
					/>
					<Route path="actual" element={<ActualLossInsurancePage />} />
					<Route path="postTransit" element={<ProductPostTransitInsurance />} />
					<Route path="actual/add" element={<AddNewActualLossPage />} />
					<Route path="post-transit/add" element={<AddNewPostTransitPage />} />
					<Route path="actual/edit/:id" element={<EditActualLossPage />} />
					<Route path="post-transit/edit/:id" element={<EditPostTransit />} />
				</Route>
				<Route path="/setting">
					<Route index={true} element={<SettingPage />} />
					<Route path="insured-items" element={<SettingInsuredItemsPage />} />
				</Route>
				<Route path="/my-page">
					<Route index={true} element={<MyPage />} />
				</Route>
			</Routes>
		</Router>
	);
};

export default RouterApp;
