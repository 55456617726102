import React, { CSSProperties, memo, ReactNode, useState } from "react";
import SideBar from "../SideBar";
import Header from "../Header";
import BeatLoader from "react-spinners/BeatLoader";

interface WrapperProps {
	children: ReactNode;
	className?: string;
	isLoading?: boolean;
}

const override: CSSProperties = {
	display: "block",
	margin: "0 auto",
	borderColor: "red",
	color: "#00be77",
	// background: "#00be77",
};

const WrapperPage: React.FC<WrapperProps> = ({
	children,
	className,
	isLoading,
}) => {
	// let [color, setColor] = useState("#ffffff");
	// let [loading, setLoading] = useState(true);

	return (
		<div className="d-flex">
			<SideBar />
			<div
				style={{
					paddingLeft: 32,
					marginLeft: 0,
					marginRight: 0,
					paddingRight: 32,
				}}
				id="wrapper-page"
				className={className}
			>
				<Header />
				{children}
				{!!isLoading && (
					<div id="kare-spinner">
						<div className="spinner">
							<BeatLoader
								color={"#00be77"}
								loading={!!isLoading}
								cssOverride={override}
								size={32}
								aria-label="Loading Spinner"
								data-testid="loader"
							/>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default memo(WrapperPage);
