import React from "react";
import { useLocale } from "../../../hooks/useLocale";
import { VALUE_TYPE_LIST } from "../../../utils/constant";

export interface Props {
	items: any[];
}

const SettingInsuredItemsList = ({ items }: Props) => {
	const { t } = useLocale();

	return (
		<div className="row border-top mt-4">
			<p className="mt-4">
				{t("ALL")} ({items?.length})
			</p>

			<ul>
				<>
					{items.map((item: any) => {
						const valueType = VALUE_TYPE_LIST.find(
							(type) => type.value == item.typeValue,
						);
						return (
							<li key={item.id} className="mb-4">
								<strong className="fw-bolder ">{item.name}</strong> (
								{valueType ? t(valueType.label) : ""})
							</li>
						);
					})}
				</>
			</ul>
		</div>
	);
};

export default SettingInsuredItemsList;
