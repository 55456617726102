import { createAsyncThunk, UnknownAction } from "@reduxjs/toolkit";
import { globalStore } from "../store";
import { queryApi } from "../../common/queryAPI";
import { apiRequest } from "../../common/api.request";
import { TYPE } from "../../common/define";
import { memberInfoActions } from "./memberInfoSlice";
import { TFunction } from "i18next";
import Swal from "sweetalert2";

export const GET_MEMBER_INFO = createAsyncThunk(
	"memberInfoSlice/GET_MEMBER_INFO",
	async (data?: { id: number }) => {
		try {
			const { id } = data || {};

			const res = await queryApi(apiRequest.adminGetMemberInfo, {}, { id });

			if (res.type === TYPE.SUCCESS && res.data) {
				globalStore.dispatch(memberInfoActions.updateMemberInfo(res.data));
			}

			if (res.type === TYPE.ERROR) {
				globalStore.dispatch(
					memberInfoActions.setErrorCode(res.messages[0].code),
				);
			}
		} catch (e) {
			console.log("error", e);
		}
	},
);

export const UPDATE_MEMBER_NOTE = createAsyncThunk(
	"memberInfoSlice/UPDATE_MEMBER_NOTE",
	async (data?: {
		memberId: number;
		note: string;
		t?: TFunction<"translation", undefined>;
		callback?: () => void;
	}) => {
		try {
			const { memberId, note, t, callback } = data || {};

			const res = await queryApi(apiRequest.adminUpdateMemberNote, {
				memberId,
				note,
			});

			if (res.type === TYPE.SUCCESS) {
				t &&
					Swal.fire({
						title: t
							? t("ANY_SUCCESSFULLY", {
									any: `${t("EDIT")}`,
								})
							: "",
						icon: "success",
					}).then(async (result2) => {
						if (result2.isConfirmed) {
							callback && callback();
						}
					});
				globalStore.dispatch(
					memberInfoActions.updateMemberInfoNote(res.data.note),
				);
			}

			if (res.type === TYPE.ERROR) {
				globalStore.dispatch(
					memberInfoActions.setErrorCode(res.messages[0].code),
				);
			}
		} catch (e) {
			console.log("error", e);
		}
	},
);

export const GET_MEMBER_NOTE = createAsyncThunk(
	"memberInfoSlice/GET_MEMBER_NOTE",
	async (data?: { id: number }) => {
		try {
			const { id } = data || {};

			const res = await queryApi(
				apiRequest.adminGetMemberNote,
				{},
				{ memberId: id },
			);

			if (res.type === TYPE.SUCCESS) {
				if (res.data.note === undefined) {
					// check if member do not have any note before
					// then create one
					id &&
						globalStore.dispatch(
							UPDATE_MEMBER_NOTE({
								memberId: id,
								note: "",
							}) as unknown as UnknownAction,
						);
				} else {
					// if member already have note
					// update it
					console.log("ieieieieieieiei");
					globalStore.dispatch(
						memberInfoActions.updateMemberInfoNote(res.data.note),
					);
				}
			}

			if (res.type === TYPE.ERROR) {
				globalStore.dispatch(
					memberInfoActions.setErrorCode(res.messages[0].code),
				);
			}
		} catch (e) {
			console.log("error", e);
		}
	},
);
