import React, { useEffect } from "react";
import { useLocale } from "../../../hooks/useLocale";
import WrapperPage from "../../../components/WrapperPage";
import HeaderMini from "../../../components/HeaderMini";
import { CHILD_LINK, PARENT_LINK } from "../../../common/define";
import { useNavigate } from "react-router-dom";
import ActualLossList from "./ActualLossList";
import useActiveCurrentPage from "../../../hooks/useActiveCurrentPage";
import { globalStore, RootState } from "../../../state/store";
import { GET_LIST_PRODUCT_ACTUAL_LOSS } from "../../../state/listProductActualLoss/actions";
import { UnknownAction } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

const ActualLossInsurancePage: React.FC = () => {
	const { t } = useLocale();
	const navigate = useNavigate();

	const goToAddSaleProduct = () => {
		navigate("/product/actual/add");
	};

	const isLoading = useSelector(
		(state: RootState) => state.listProductActualLossReducers.isLoading,
	);

	useActiveCurrentPage(CHILD_LINK.ACTUAL_LOSS_PRODUCT, PARENT_LINK.PRODUCT);

	// const initListProductActualLoss = () => {
	// 	globalStore.dispatch(
	// 		GET_LIST_PRODUCT_ACTUAL_LOSS() as unknown as UnknownAction,
	// 	);
	// };

	return (
		<WrapperPage isLoading={isLoading} className="container-fluid py-3">
			<HeaderMini
				title={`${t("ACTUAL_LOSS_INSURANCE")}`}
				breadcrumbs={[t("PRODUCT_MANAGEMENT"), t("ACTUAL_LOSS_INSURANCE")]}
			/>
			<div className="d-flex justify-content-end mb-5">
				<button
					onClick={goToAddSaleProduct}
					type="button"
					className="justify-content-center btn mb-1 btn-rounded btn-outline-success d-flex align-items-center"
				>
					<i className="ti ti-edit fs-4 me-2"></i>
					{t("ADD_ACTUAL_LOSS_INSURANCE")}
				</button>
			</div>
			<div className="row">
				<ActualLossList />
			</div>
		</WrapperPage>
	);
};

export default ActualLossInsurancePage;
