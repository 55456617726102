import React, { useCallback, useMemo, useState } from "react";
import WrapperPage from "../../components/WrapperPage";
import { useLocale } from "../../hooks/useLocale";
import HeaderMini from "../../components/HeaderMini";
import MemberList from "./MemberList";
import SearchSection from "../../components/SearchSection";
import { ICountry, PARENT_LINK } from "../../common/define";
import useActiveCurrentPage from "../../hooks/useActiveCurrentPage";
import { useSelector } from "react-redux";
import { globalStore, RootState } from "../../state/store";
import { listMemberActions } from "../../state/listMember/listMemberSlice";
import { GET_LIST_MEMBER } from "../../state/listMember/actions";
import { UnknownAction } from "@reduxjs/toolkit";
import { GET_COUNTRY_ACTION } from "../../state/country/actions";
import useEffectOnce from "../../hooks/useEffectOnce";

const MemberPage: React.FC = () => {
	const { t } = useLocale();

	const isLoading = useSelector(
		(state: RootState) => state.listMemberReducers.isLoading,
	);
	const country = useSelector(
		(state: RootState) => state.countryReducers.country,
	);

	const [selectCountry, setSelectCountry] = useState<ICountry | undefined>(
		country[0],
	);
	const [keywordSearch, setKeywordSearch] = useState("");

	const handleSelect = (opt: string) => {
		const rs = country
			? country.find((item) => item.nameEn === opt)
			: undefined;
		setSelectCountry(rs);
	};

	const handleOnChange = (text: string) => {
		console.log("input text", text);
		setKeywordSearch(text);
	};

	const handleSearch = async () => {
		const countryId = selectCountry?.id.toString() ?? "";
		const keyword = keywordSearch ?? "";

		await globalStore.dispatch(
			listMemberActions.updateCountryId(countryId) as unknown as UnknownAction,
		);

		await globalStore.dispatch(
			listMemberActions.updateKeyword(keyword) as unknown as UnknownAction,
		);

		await globalStore.dispatch(
			GET_LIST_MEMBER({
				isSearch: true,
				pageNumber: 0,
				pageSize: 10,
			}) as unknown as UnknownAction,
		);
	};

	const handleReset = async () => {
		setSelectCountry(country[0]);
		handleSelect(country[0].nameEn);
		handleOnChange("");
		// await globalStore.dispatch(
		// 	listMemberActions.updateCurrentPage(0) as unknown as UnknownAction,
		// );
		await globalStore.dispatch(
			listMemberActions.updateCountryId("") as unknown as UnknownAction,
		);
		await globalStore.dispatch(
			listMemberActions.updateKeyword("") as unknown as UnknownAction,
		);
		await globalStore.dispatch(
			listMemberActions.updateCurrentPage(1) as unknown as UnknownAction,
		);
		await globalStore.dispatch(
			GET_LIST_MEMBER({
				isSearch: false,
				pageSize: 10,
			}) as unknown as UnknownAction,
		);
	};

	const initCountry = useCallback(async () => {
		await globalStore.dispatch(
			listMemberActions.updateCurrentPage(1) as unknown as UnknownAction,
		);
		globalStore.dispatch(GET_COUNTRY_ACTION() as unknown as UnknownAction);
	}, []);

	const options = useMemo(() => {
		return ["All", ...country.map((item) => item.nameEn)];
	}, [country]);

	useEffectOnce(initCountry);

	useActiveCurrentPage(
		PARENT_LINK.MEMBER_MANAGEMENT,
		PARENT_LINK.MEMBER_MANAGEMENT,
	);

	return (
		<WrapperPage isLoading={isLoading} className="container-fluid py-3">
			<HeaderMini
				title={`${t("MEMBER_MANAGEMENT")}`}
				breadcrumbs={[t("MEMBER_MANAGEMENT"), t("MEMBER_LIST")]}
			/>
			<div className="row">
				{/* <SearchSection
					handleSearch={handleSearch}
					handleReset={handleReset}
					handleOnChange={handleOnChange}
					handleSelect={handleSelect}
					options={options}
				/> */}
				<MemberList />
			</div>
		</WrapperPage>
	);
};

export default MemberPage;
