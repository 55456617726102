import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQueryParams } from "./useQueryParams";
import { SUPPORTED_LANGUAGES } from "../configs";

export function useLocale() {
	const { lang } = useQueryParams();
	const { i18n, t } = useTranslation();
	const activeLang =
		localStorage.getItem("siteLanguage") ||
		i18n.language ||
		SUPPORTED_LANGUAGES[0];

	const switchLanguage = (language: string) => {
		console.log("switchLanguage", language);
		localStorage.setItem("siteLanguage", language);
		i18n.changeLanguage(language);
	};

	useEffect(() => {
		if (lang && SUPPORTED_LANGUAGES.includes(lang)) {
			switchLanguage(lang);
		}
	}, [lang]);

	return {
		i18n,
		t,
		switchLanguage: switchLanguage,
		activeLang: activeLang,
	};
}
