import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IError, IMember } from "../../common/define";
import { addAsyncReducers } from "../store";
import { GET_MEMBER_INFO } from "./actions";

export interface ICustomer {
	code: number;
	id: number;
	fullName: string;
	fullNameEn: string;
	idCard: string;
	email: string;
	isMember: boolean;
	registrationDate: string;
	phone: string;
	member: IMember;
	members: IMember[];
	country?: {
		id: number;
		name: string;
		nameEn: string;
		code: string;
	};
}

export interface IMemberInfo {
	data: IMember | null;
	note: string;
}

export interface IAuth {
	access_token: string;
	access_token_expires_dttm: string;
	refresh_token: string;
	refresh_token_expires_dttm: string;
}

interface IOtherState {
	isLoading: boolean;
}

const initialState: IMemberInfo & IOtherState & IError = {
	data: null,
	note: "",
	result_code: "",
	result_msg: "",
	code: "",
	errorCode: "",
	isLoading: false,
};

const memberInfoSlice = createSlice({
	name: "memberInfoSlice",
	initialState,
	reducers: {
		updateMemberInfo: (state, action: PayloadAction<IMember>) => {
			return {
				...state,
				data: action.payload,
			};
		},
		setErrorCode: (state, action: PayloadAction<string>) => {
			state.errorCode = action.payload;
			return state;
		},
		updateMemberInfoNote: (state, action: PayloadAction<string>) => {
			console.log("iieieieieiei-rereress", action.payload);
			state.note = action.payload;
		},
		reset: () => initialState,
	},
	extraReducers: (builder) => {
		addAsyncReducers(builder, GET_MEMBER_INFO, initialState);
	},
});

export const memberInfoActions = memberInfoSlice.actions;
export const memberInfoReducers = memberInfoSlice.reducer;

// export const authSelectors = {
// 	isLoading: (state: RootState) => state.authReducers.isLoading,
// 	code: (state: RootState) => state.authReducers.code,
// };
