import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IConfigMedical, IError } from "../../../../common/define";
import { addAsyncReducers } from "../../../store";
import { GET_CONFIG_MEDICAL } from "./actions";

export interface IConfigMedicalStore {
	data: IConfigMedical[] | null;
}

export interface IAuth {
	access_token: string;
	access_token_expires_dttm: string;
	refresh_token: string;
	refresh_token_expires_dttm: string;
}

interface IOtherState {
	isLoading: boolean;
}

const initialState: IConfigMedicalStore & IOtherState & IError = {
	data: null,
	result_code: "",
	result_msg: "",
	code: "",
	isLoading: false,
};

const configMedicalSlice = createSlice({
	name: "configMedicalSlice",
	initialState,
	reducers: {
		updateConfigMedical: (state, action: PayloadAction<IConfigMedical[]>) => {
			return {
				...state,
				data: action.payload,
			};
		},
		setErrorCode: (state, action: PayloadAction<string>) => {
			state.code = action.payload;
			return state;
		},
		reset: () => initialState,
	},
	extraReducers: (builder) => {
		addAsyncReducers(builder, GET_CONFIG_MEDICAL, initialState);
	},
});

export const configMedicalActions = configMedicalSlice.actions;
export const configMedicalReducers = configMedicalSlice.reducer;

// export const authSelectors = {
// 	isLoading: (state: RootState) => state.authReducers.isLoading,
// 	code: (state: RootState) => state.authReducers.code,
// };
