import React from "react";
import { useLocale } from "../../../hooks/useLocale";
import WrapperPage from "../../../components/WrapperPage";
import HeaderMini from "../../../components/HeaderMini";
import { CHILD_LINK, PARENT_LINK } from "../../../common/define";
import { useNavigate } from "react-router-dom";
import PostTransitList from "./PostTransitList";
import useActiveCurrentPage from "../../../hooks/useActiveCurrentPage";
import { RootState, globalStore } from "../../../state/store";
import { listProductPostTransitActions } from "../../../state/listProductPostTransit/listProductPostTransitSlice";
import { useSelector } from "react-redux";

const PostTransitInsurancePage: React.FC = () => {
	const { t } = useLocale();
	const navigate = useNavigate();

	const productLoading = useSelector(
		(state: RootState) => state.productPostTransitDetailReducers.isLoading,
	);

	const goToAddSaleProduct = () => {
		navigate("/product/post-transit/add");
	};

	useActiveCurrentPage(CHILD_LINK.POST_TRANSIT_PRODUCT, PARENT_LINK.PRODUCT);

	return (
		<WrapperPage isLoading={productLoading} className="container-fluid py-3">
			<HeaderMini
				title={`${t("POST_TRANSIT_INSURANCE")}`}
				breadcrumbs={[t("PRODUCT_MANAGEMENT"), t("POST_TRANSIT_INSURANCE")]}
			/>
			<div className="d-flex justify-content-end mb-5">
				<button
					onClick={goToAddSaleProduct}
					type="button"
					className="justify-content-center btn mb-1 btn-rounded btn-outline-success d-flex align-items-center"
				>
					<i className="ti ti-edit fs-4 me-2"></i>
					{t("ADD_POST_TRANSIT_INSURANCE")}
				</button>
			</div>
			<div className="row">
				<PostTransitList />
			</div>
		</WrapperPage>
	);
};

export default PostTransitInsurancePage;
