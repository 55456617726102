import React, { memo, ReactNode, useState } from "react";
import { useLocale } from "../../hooks/useLocale";

function printNumbers(n: number) {
	if (!n || n <= 0) {
		return [];
	}

	const numbers = Array.from({ length: n }, (_, i) => i + 1);
	return numbers;
}

interface IProps {
	title: string;
	thead?: string[];
	titleBtns?: ReactNode[];
	tbody?: ReactNode;
	additionBody?: ReactNode;
	countPage?: number;
	currentPage?: number;
	total?: number;
	emptyTitle?: string;
	handleChangePage?: (idx: number) => void;
}

const Table: React.FC<IProps> = ({
	title,
	thead,
	tbody,
	additionBody,
	countPage = 5,
	currentPage,
	handleChangePage,
	total = 0,
	emptyTitle,
	titleBtns,
}) => {
	const { t } = useLocale();

	const onChangePage = (page: number) => {
		// setCurrentPage(page);
		handleChangePage && handleChangePage(page);
	};

	return (
		<div className="wrap-table-list col-12">
			<div className="card">
				<div className="card-header">
					<div className="d-flex justify-content-between align-items-center">
						<h4>{title}</h4>
						<div>{titleBtns && titleBtns.map((item) => item)}</div>
					</div>
				</div>
				{additionBody}
				<div className="card-body list text-dark overflow-auto">
					<table
						className={`table ${total > 0 ? "border table-hover" : "table--no-border"} text-nowrap fs-2`}
					>
						{total > 0 ? (
							<thead>
								<tr>
									{thead?.map((tit) => (
										<th className="text-center" key={"thead" + tit} scope="col">
											{tit}
										</th>
									))}
								</tr>
							</thead>
						) : (
							<></>
						)}

						<tbody>
							{total > 0 ? (
								tbody
							) : (
								<tr>
									<td
										style={{
											fontSize: 14,
											color: "#2A3547",
										}}
										className="text-center"
									>
										{emptyTitle ? emptyTitle : t("EMPTY_DATA")}
									</td>
								</tr>
							)}
						</tbody>
					</table>
					{countPage > 1 && (
						<div className="row">
							<nav aria-label="...">
								<ul className="pagination pagination-sm d-flex justify-content-center">
									{printNumbers(countPage).map((page) => (
										<li
											key={"pagi" + page}
											onClick={() => onChangePage(page)}
											className={`page-item ${page === currentPage ? "active" : ""}`}
											aria-current="page"
										>
											<span role="button" className="page-link">
												{page}
											</span>
										</li>
									))}
								</ul>
							</nav>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default memo(Table);
