import { UnknownAction } from "@reduxjs/toolkit";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { apiRequest } from "../../common/api.request";
import { NOT_AVAILABLE, swalWithBootstrapRed } from "../../common/define";
import { queryApi } from "../../common/queryAPI";
import { formatISOToString } from "../../common/utils";
import TableList from "../../components/TableList";
import { useLocale } from "../../hooks/useLocale";
import { UPDATE_CONTRACT_STATUS_ACTION } from "../../state/contractInfo/actions";
import { globalStore, RootState } from "../../state/store";

const InsuranceStatus: React.FC = () => {
	const { t } = useLocale();

	const { id } = useParams();

	const [statuses, setStatuses] = useState<string[]>();

	const contractDetails = useSelector(
		(state: RootState) => state.contractInfoReducers.data,
	);

	const history = useSelector(
		(state: RootState) => state.contractInfoReducers.data?.history,
	);

	const [note, setNote] = useState("");
	const [selectedStatus, setSelectedStatus] = useState("");

	const handleSelectOption = (data: string) => {
		setSelectedStatus(data);
	};
	const handleUpdateStatus = useCallback(async () => {
		swalWithBootstrapRed
			.fire({
				reverseButtons: true,
				text: t("SURE_TO_CHANGE"),
				showCancelButton: true,
				confirmButtonColor: "red",
				cancelButtonColor: "#EAEFF4",
				confirmButtonText: t("OK"),
				cancelButtonText: t("CANCEL_TEXT"),
			})
			.then(async ({ isConfirmed }) => {
				if (!isConfirmed || !selectedStatus) return;
				await globalStore.dispatch(
					UPDATE_CONTRACT_STATUS_ACTION({
						id: Number(id),
						note: note,
						status: selectedStatus,
						callbackSuccess(data) {
							setNote("");
						},
					}) as unknown as UnknownAction,
				);
				window.location.reload();
			});
	}, [note, selectedStatus]);

	const getStatuses = async () => {
		const res = await queryApi(
			apiRequest.adminGetContractStatusAllowUpdate,
			{},
			{ contractId: id },
		);
		if (res) {
			if (contractDetails) {
				const concatedData = [contractDetails?.status].concat(res.data);
				setStatuses(concatedData);
				setSelectedStatus(contractDetails?.status);
			}
		}
	};

	const onChangeNote = (e: any) => setNote(e.target.value);

	const thead = [t("PROCESSING_DATE"), t("PROCESSING_STATUS"), t("NOTES")];

	const tbody = useMemo(() => {
		return history && history.length > 0 ? (
			history.map((contract, idx) => (
				<tr className="" key={"contract" + idx}>
					<td className="text-center">
						{contract?.createdDate
							? `${formatISOToString(contract?.createdDate).date} ${formatISOToString(contract?.createdDate).time}`
							: NOT_AVAILABLE}
					</td>
					<td className="text-center">
						{contract.status ? (
							<span
								className={`customer-badge ${contract.status.toLowerCase()}`}
							>
								{t(contract.status)}
							</span>
						) : (
							NOT_AVAILABLE
						)}
					</td>
					<td className="text-center">{contract.note}</td>
				</tr>
			))
		) : (
			<tr>
				{thead.map((item, idx) => (
					<td key={item + idx} className="text-center">
						{NOT_AVAILABLE}
					</td>
				))}
			</tr>
		);
	}, [history]);

	const AddBody = () => {
		return (
			<div className="card-body text-dark mb-2">
				<div className="row mb-4">
					<div className="col-2 justify-content-center align-self-center fw-bolder">
						{t("STATUS")}
					</div>
					{statuses && (
						<div className="col-4 justify-content-center align-self-center">
							<div className="justify-content-center align-self-center">
								<select
									onChange={(e: any) => handleSelectOption(e.target.value)}
									className="form-select"
								>
									{statuses.map((opt, index) => (
										<option
											selected={opt === selectedStatus}
											key={index}
											value={opt}
										>
											{t(opt)}
										</option>
									))}
								</select>
							</div>
						</div>
					)}
				</div>
				<div className="row">
					<div className="col-2 justify-content-center align-self-center fw-bolder">
						{t("NOTES")}
					</div>
					<div className="col-10 justify-content-center align-self-center">
						<input
							type="text"
							className="w-100 form-control"
							placeholder={t("INPUT_NOTE")}
							onChange={onChangeNote}
							required={true}
						/>
					</div>
				</div>
			</div>
		);
	};

	const titleBtns = [
		<button
			onClick={handleUpdateStatus}
			type="button"
			disabled={selectedStatus === contractDetails?.status || !selectedStatus}
			className={`justify-content-center btn mb-1 ${selectedStatus === contractDetails?.status || !selectedStatus ? "btn-dark" : "btn-success"} d-flex align-items-center`}
		>
			{t("CHANGE")}
		</button>,
	];

	useEffect(() => {
		if (id) {
			getStatuses();
		}
	}, [id, contractDetails]);

	return (
		<div className="row">
			<TableList
				titleBtns={titleBtns}
				title={t("INSURANCE_STATUS")}
				thead={thead}
				tbody={tbody}
				countPage={0}
				additionBody={<AddBody />}
			/>
		</div>
	);
};

export default InsuranceStatus;
