import React, { useEffect } from "react";
import { useLocale } from "../../../hooks/useLocale";
import WrapperPage from "../../../components/WrapperPage";
import HeaderMini from "../../../components/HeaderMini";
import ProductList from "./ProductList";
import { CHILD_LINK, PARENT_LINK } from "../../../common/define";
import { useNavigate } from "react-router-dom";
import useActiveCurrentPage from "../../../hooks/useActiveCurrentPage";
import { globalStore } from "../../../state/store";
import { GET_LIST_PRODUCT } from "../../../state/listProduct/actions";
import { UnknownAction } from "@reduxjs/toolkit";

const SaleProductManagementPage: React.FC = () => {
	const { t } = useLocale();
	const navigate = useNavigate();

	const goToAddSaleProduct = () => {
		navigate("addNewProduct");
	};

	const initProductList = () => {
		globalStore.dispatch(GET_LIST_PRODUCT({}) as unknown as UnknownAction);
	};

	useEffect(() => {
		initProductList();
	}, []);

	useActiveCurrentPage(CHILD_LINK.VIEW_PRODUCT, PARENT_LINK.PRODUCT);

	return (
		<WrapperPage className="container-fluid py-3">
			<HeaderMini
				title={`${t("SALES_PRODUCT_MANAGEMENT")}`}
				breadcrumbs={[t("PRODUCT_MANAGEMENT"), t("SALES_PRODUCT_MANAGEMENT")]}
			/>
			<div className="d-flex justify-content-end mb-5">
				<button
					onClick={goToAddSaleProduct}
					type="button"
					className="justify-content-center btn mb-1 btn-rounded btn-outline-success d-flex align-items-center"
				>
					<i className="ti ti-edit fs-4 me-2"></i>
					{t("ADD_SALE_PRODUCT")}
				</button>
			</div>
			<div className="row">
				<ProductList />
			</div>
		</WrapperPage>
	);
};

export default SaleProductManagementPage;
