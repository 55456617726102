import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IContractItem, IError } from "../../common/define";
import { addAsyncReducers } from "../store";
import { GET_LIST_CONTRACT_BY_MEMBER } from "./actions";
// import { GET_LIST_PRODUCT_ACTUAL_LOSS } from "./actions";

export interface IProductActualLoss {
	data: {
		content: IContractItem[];
		pageNumber: number;
		pageSize: number;
		totalElements: number;
		totalPages: number;
	};
	currentId: number | undefined;
	currentPage: number;
	keyword: string;
	countryId: string;
}

export interface IAuth {
	access_token: string;
	access_token_expires_dttm: string;
	refresh_token: string;
	refresh_token_expires_dttm: string;
}

interface IOtherState {
	isLoading: boolean;
}

const initialState: IProductActualLoss & IOtherState & IError = {
	data: {
		content: [],
		pageNumber: 0,
		pageSize: 0,
		totalElements: 0,
		totalPages: 0,
	},
	currentId: undefined,
	currentPage: 1,
	keyword: "",
	countryId: "",
	result_code: "",
	result_msg: "",
	code: "",
	isLoading: false,
};

const listContractSlice = createSlice({
	name: "listContractSlice",
	initialState,
	reducers: {
		updateData: (state, action: PayloadAction<any>) => {
			return {
				...state,
				data: action.payload,
			};
		},
		setErrorCode: (state, action: PayloadAction<string>) => {
			state.code = action.payload;
			return state;
		},
		updateKeyword: (state, action: PayloadAction<string>) => {
			return {
				...state,
				keyword: action.payload,
			};
		},
		updateCountryId: (state, action: PayloadAction<string>) => {
			return {
				...state,
				countryId: action.payload,
			};
		},
		updateCurrentPage: (state, action: PayloadAction<number>) => {
			return {
				...state,
				currentPage: action.payload,
			};
		},
		updateCurrentId: (state, action: PayloadAction<number>) => {
			return {
				...state,
				currentId: action.payload,
			};
		},
		reset: () => initialState,
	},
	extraReducers: (builder) => {
		addAsyncReducers(builder, GET_LIST_CONTRACT_BY_MEMBER, initialState);
	},
});

export const listContractActions = listContractSlice.actions;
export const listContractReducers = listContractSlice.reducer;

// export const authSelectors = {
// 	isLoading: (state: RootState) => state.authReducers.isLoading,
// 	code: (state: RootState) => state.authReducers.code,
// };
