import React, { memo } from "react";
import { useLocale } from "../../../hooks/useLocale";
import KareButton from "../../KareButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";
import classNames from "classnames";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { RootState, globalStore } from "../../../state/store";
import {
	EDIT_USER_INFO_ACTION,
	GET_USER_INFO_ACTION,
} from "../../../state/userInfo/actions";
import { UnknownAction } from "@reduxjs/toolkit";

const UserUpdateForm: React.FC = () => {
	const { t } = useLocale();
	const isLoading = useSelector(
		(state: RootState) => state.userInfoReducers.isLoading,
	);
	const user = useSelector((state: RootState) => state.userInfoReducers);
	const schema = yup
		.object({
			email: yup
				.string()
				.required(t("REQUIRED", { field: `${t("EMAIL")}` }))
				.email(),
			fullName: yup
				.string()
				.required(t("REQUIRED", { field: `${t("FULL_NAME")}` })),
			position: yup
				.string()
				.required(t("REQUIRED", { field: `${t("POSITION")}` })),
			phone: yup.string().required(t("REQUIRED", { field: `${t("PHONE")}` })),
		})
		.required();
	const defaultValues = {
		email: user.email,
		fullName: user.fullName,
		position: user.position,
		phone: user.phone,
	};
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: defaultValues,
	});
	const onSubmit = async (data: any) => {
		const swalWithBootstrapButtons = Swal.mixin({
			customClass: {
				confirmButton: "btn btn-secondary",
				cancelButton: "btn btn-light-indigo me-2",
			},
			confirmButtonColor: "red",
			buttonsStyling: false,
		});
		swalWithBootstrapButtons
			.fire({
				reverseButtons: true,
				text: t("ARE_YOU_SURE_YOU_WANT_TO_SAVE"),
				showCancelButton: true,
				confirmButtonColor: "red",
				cancelButtonColor: "#EAEFF4",
				confirmButtonText: t("OK"),
				cancelButtonText: t("CANCEL"),
			})
			.then(async (_result: any) => {
				if (_result.isConfirmed) {
					globalStore.dispatch(
						EDIT_USER_INFO_ACTION({
							t,
							fullName: data.fullName,
							position: data.position,
							email: data.email,
							phone: data.phone,
							async callback() {
								Swal.fire({
									title: t
										? t("ANY_SUCCESSFULLY", {
												any: `${t("SAVE")}`,
											})
										: "",
									icon: "success",
								});
							},
						}) as unknown as UnknownAction,
					);
				}
			});
	};
	return (
		<div className="card">
			<div className="card-header">
				<h4 className="mb-0">{t("ACCOUNT_INFORMATION")}</h4>
			</div>
			<div className="card-body">
				<form
					className="row d-flex align-items-center"
					onSubmit={handleSubmit(onSubmit)}
				>
					<div className="col-md-6 mb-4">
						<label className="form-label" htmlFor="fullName">
							{t("FULL_NAME")}
						</label>
						<input
							type="text"
							id="fullName"
							className={classNames(
								"form-control",
								errors?.fullName ? "is-invalid" : "",
							)}
							{...register("fullName")}
						/>
					</div>
					<div className="col-md-6 mb-4">
						<label className="form-label" htmlFor="position">
							{t("POSITION")}
						</label>
						<input
							type="text"
							id="position"
							className={classNames(
								"form-control",
								errors?.position ? "is-invalid" : "",
							)}
							{...register("position")}
						/>
					</div>
					<div className="col-md-6 mb-4">
						<label className="form-label" htmlFor="email">
							{t("EMAIL")}
						</label>
						<input
							type="text"
							id="email"
							className={classNames(
								"form-control",
								errors?.email ? "is-invalid" : "",
							)}
							{...register("email")}
						/>
					</div>
					<div className="col-md-6 mb-4">
						<label className="form-label" htmlFor="phone">
							{t("CONTACT")}
						</label>
						<input
							type="text"
							id="phone"
							className={classNames(
								"form-control",
								errors?.phone ? "is-invalid" : "",
							)}
							{...register("phone")}
						/>
					</div>
					<div className="col-md-12">
						<KareButton
							text={t("SAVE")}
							className="btn btn-primary rounded-2 w-100px float-end"
							isLoading={isLoading}
						/>
					</div>
				</form>
			</div>
		</div>
	);
};

export default memo(UserUpdateForm);
