import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IConfigActualLoss, IError } from "../../common/define";
import { addAsyncReducers } from "../store";
import { GET_CONFIG_ACTUAL_LOSS } from "./actions";

export interface IConfigActualLossStore {
	data: IConfigActualLoss[] | null;
}

export interface IAuth {
	access_token: string;
	access_token_expires_dttm: string;
	refresh_token: string;
	refresh_token_expires_dttm: string;
}

interface IOtherState {
	isLoading: boolean;
}

const initialState: IConfigActualLossStore & IOtherState & IError = {
	data: null,
	result_code: "",
	result_msg: "",
	code: "",
	isLoading: false,
};

const configActualLossSlice = createSlice({
	name: "configActualLossSlice",
	initialState,
	reducers: {
		updateConfigActualLoss: (
			state,
			action: PayloadAction<IConfigActualLoss[]>,
		) => {
			return {
				...state,
				data: action.payload,
			};
		},
		setErrorCode: (state, action: PayloadAction<string>) => {
			state.code = action.payload;
			return state;
		},
		reset: () => initialState,
	},
	extraReducers: (builder) => {
		addAsyncReducers(builder, GET_CONFIG_ACTUAL_LOSS, initialState);
	},
});

export const configActualLossActions = configActualLossSlice.actions;
export const configActualLossReducers = configActualLossSlice.reducer;

// export const authSelectors = {
// 	isLoading: (state: RootState) => state.authReducers.isLoading,
// 	code: (state: RootState) => state.authReducers.code,
// };
