import React, { memo, useCallback, useMemo } from "react";
import { useLocale } from "../../hooks/useLocale";
import { useNavigate } from "react-router-dom";
import TableList from "../../components/TableList";
import { useSelector } from "react-redux";
import { globalStore, RootState } from "../../state/store";
import { listMemberActions } from "../../state/listMember/listMemberSlice";
import { UnknownAction } from "@reduxjs/toolkit";
import { GET_LIST_MEMBER } from "../../state/listMember/actions";
import useEffectOnce from "../../hooks/useEffectOnce";
import { NOT_AVAILABLE } from "../../common/define";

const MemberList: React.FC = () => {
	const { t } = useLocale();
	const navigate = useNavigate();

	const listMemberStore = useSelector(
		(state: RootState) => state.listMemberReducers.data.content,
	);
	const totalPagesStore = useSelector(
		(state: RootState) => state.listMemberReducers.data.totalPages,
	);
	const totalElements = useSelector(
		(state: RootState) => state.listMemberReducers.data.totalElements,
	);
	const currentPageStore = useSelector(
		(state: RootState) => state.listMemberReducers.currentPage,
	);

	const goToDetail = (idx: number) => {
		navigate(`/member/${idx}`);
	};

	const handleChangePage = async (idx: number) => {
		await globalStore.dispatch(
			listMemberActions.updateCurrentPage(idx) as unknown as UnknownAction,
		);

		await globalStore.dispatch(
			GET_LIST_MEMBER({
				isSearch: true,
				pageSize: 10,
				pageNumber: idx,
			}) as unknown as UnknownAction,
		);
	};

	const initData = useCallback(async () => {
		await globalStore.dispatch(
			GET_LIST_MEMBER({
				pageSize: 10,
				pageNumber: currentPageStore,
			}) as unknown as UnknownAction,
		);
	}, [currentPageStore]);

	const thead = [
		t("MEMBER_NO"),
		t("COUNTRY_OF_RESIDENCE"),
		t("MEMBER_REGISTRATION_DATE"),
		t("NAME"),
		t("DATE_OF_BIRTH"),
		t("CONTACT"),
		t("EMAIL"),
		t("TOTAL_NUMBER_OF_CONTRACTS"),
	];

	const tbody = useMemo(() => {
		return listMemberStore.length ? (
			listMemberStore.map((member, idx) => (
				<tr
					role="button"
					key={"member" + idx + member.id}
					onClick={() => goToDetail(member.id)}
				>
					<td className="text-center">{member.code ?? NOT_AVAILABLE}</td>
					<td className="text-center">
						{member.country?.nameEn ?? NOT_AVAILABLE}
					</td>
					<td className="text-center">
						{member.registrationDate ?? NOT_AVAILABLE}
					</td>
					<td className="text-center">{member.fullName}</td>
					<td className="text-center">{member.birthday ?? NOT_AVAILABLE}</td>
					<td className="text-center">{member.phone ?? NOT_AVAILABLE}</td>
					<td className="text-center">{member.email ?? NOT_AVAILABLE}</td>
					<td className="text-center">
						{member.contractQuantity ?? NOT_AVAILABLE}
					</td>
				</tr>
			))
		) : (
			<tr>
				{thead.map((item, idx) => (
					<td key={item + idx} className="text-center"></td>
				))}
			</tr>
		);
	}, [listMemberStore]);

	useEffectOnce(initData);

	return (
		<TableList
			title={`${t("TOTAL")} (${totalElements} ${t("CASES")})`}
			thead={thead}
			tbody={tbody}
			countPage={totalPagesStore}
			currentPage={currentPageStore}
			handleChangePage={handleChangePage}
		/>
	);
};

export default memo(MemberList);
