import { memo } from "react";

interface IKareButton {
	onClick?: () => void;
	text: string;
	isLoading?: boolean;
	disabled?: boolean;
	className?: string;
}

const KareButton = ({
	onClick,
	text,
	isLoading = false,
	disabled = false,
	className = "",
}: IKareButton) => {
	return (
		<button
			onClick={onClick}
			role="button"
			className={`relative w-100 btn btn-success bg-green py-2 ${className}`}
			disabled={disabled || isLoading}
		>
			{!isLoading ? (
				text
			) : (
				<div
					className="spinner-border spinner-border-sm text-light"
					role="status"
				></div>
			)}
		</button>
	);
};

export default memo(KareButton);
