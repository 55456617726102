import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ICountry, ICustomer, IError } from "../../common/define";
import { addAsyncReducers } from "../store";
import { GET_LIST_CUSTOMER } from "./actions";

export interface IListCustomer {
	data: {
		content: ICustomer[];
		pageNumber: number;
		pageSize: number;
		totalElements: number;
		totalPages: number;
	};
	currentPage: number;
	keyword: string;
	countryId: string;
}

export interface IAuth {
	access_token: string;
	access_token_expires_dttm: string;
	refresh_token: string;
	refresh_token_expires_dttm: string;
}

interface IOtherState {
	isLoading: boolean;
}

const initialState: IListCustomer & IOtherState & IError = {
	data: {
		content: [],
		pageNumber: 0,
		pageSize: 0,
		totalElements: 0,
		totalPages: 0,
	},
	currentPage: 1,
	keyword: "",
	countryId: "",
	result_code: "",
	result_msg: "",
	code: "",
	isLoading: false,
};

const listCustomerSlice = createSlice({
	name: "listCustomerSlice",
	initialState,
	reducers: {
		updateListCustomer: (state, action: PayloadAction<any>) => {
			return {
				...state,
				data: action.payload,
			};
		},
		setErrorCode: (state, action: PayloadAction<string>) => {
			state.code = action.payload;
			return state;
		},
		updateKeyword: (state, action: PayloadAction<string>) => {
			return {
				...state,
				keyword: action.payload,
			};
		},
		updateCountryId: (state, action: PayloadAction<string>) => {
			return {
				...state,
				countryId: action.payload,
			};
		},
		updateCurrentPage: (state, action: PayloadAction<number>) => {
			return {
				...state,
				currentPage: action.payload,
			};
		},
		reset: () => initialState,
	},
	extraReducers: (builder) => {
		addAsyncReducers(builder, GET_LIST_CUSTOMER, initialState);
	},
});

export const listCustomerActions = listCustomerSlice.actions;
export const listCustomerReducers = listCustomerSlice.reducer;

// export const authSelectors = {
// 	isLoading: (state: RootState) => state.authReducers.isLoading,
// 	code: (state: RootState) => state.authReducers.code,
// };
