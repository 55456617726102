import { TFunction } from "i18next";
import React, { memo, useState } from "react";
import { useAppModalContext } from "../../../contexts/ModalContext";
import Swal from "sweetalert2";
import { ICustomerNote } from "../../../state/listCustomerNote/listCustomerNoteSlice";
import { formatISOToString } from "../../../common/utils";
import { globalStore } from "../../../state/store";
import {
	DELETE_CUSTOMER_NOTE,
	EDIT_CUSTOMER_NOTE,
} from "../../../state/listCustomerNote/actions";
import { UnknownAction } from "@reduxjs/toolkit";

interface IPropsModal {
	t: TFunction<"translation", undefined>;
	item?: ICustomerNote;
	customerId?: string;
}

const ModalEditCustomerNotes: React.FC<IPropsModal> = ({
	t,
	item,
	customerId,
}) => {
	const { closeModal } = useAppModalContext();

	const [inputValue, setInputValue] = useState(item?.note);

	const confirmDelete = () => {
		const swalWithBootstrapButtons = Swal.mixin({
			customClass: {
				confirmButton: "btn btn-secondary",
				cancelButton: "btn btn-light-indigo me-2",
			},
			confirmButtonColor: "red",
			buttonsStyling: false,
		});
		swalWithBootstrapButtons
			.fire({
				reverseButtons: true,
				text: t("DELETE_CONFIRM"),
				showCancelButton: true,
				confirmButtonColor: "red",
				cancelButtonColor: "#EAEFF4",
				confirmButtonText: t("ON_OK"),
				cancelButtonText: t("ON_CANCEL"),
			})
			.then(async (_result) => {
				if (inputValue && customerId && item) {
					await globalStore.dispatch(
						DELETE_CUSTOMER_NOTE({
							t: t,
							id: item?.id,
							customerId,
							callback: closeModal,
						}) as unknown as UnknownAction,
					);
				}
			});
	};

	const confirmEdit = () => {
		const swalWithBootstrapButtons = Swal.mixin({
			customClass: {
				confirmButton: "btn btn-secondary",
				cancelButton: "btn btn-light-indigo me-2",
			},
			confirmButtonColor: "red",
			buttonsStyling: false,
		});
		swalWithBootstrapButtons
			.fire({
				reverseButtons: true,
				text: t("SURE_TO_EDIT"),
				showCancelButton: true,
				confirmButtonColor: "red",
				cancelButtonColor: "#EAEFF4",
				confirmButtonText: t("OK"),
				cancelButtonText: t("CANCEL_TEXT"),
			})
			.then(async (_result) => {
				if (_result.isConfirmed && inputValue && customerId && item) {
					await globalStore.dispatch(
						EDIT_CUSTOMER_NOTE({
							t: t,
							id: item?.id,
							note: inputValue,
							customerId,
							callback: closeModal,
						}) as unknown as UnknownAction,
					);
				}
			});
	};

	const onChange = (e: any) => {
		const value = e.target.value;
		setInputValue(value);
	};

	const timeFormat = formatISOToString(item?.createdDate);

	return (
		<div
			className="bg-light p-4 rounded-2 max-w-1000 modal-dialog"
			role="document"
		>
			<div className="modal-content">
				<div className="modal-header d-flex align-items-center">
					<h4 className="modal-title" id="exampleModalLabel1">
						{t("INSURANCE_CUSTOMER_NOTES")}
					</h4>
					{/* <button
						type="button"
						className="btn-close"
						data-bs-dismiss="modal"
						aria-label="Close"
					></button> */}
				</div>

				<div className="d-flex justify-content-between align-items-center mt-6 mb-4">
					<div className="d-flex justify-content-start ">
						<div className="fw-bolder font-bolder pe-5">
							{t("CREATION_DATE")}
						</div>
						<div className="pe-9">{timeFormat.date}</div>
						<div className="">{timeFormat.time}</div>
					</div>
					<div className="text-end">
						<button
							onClick={confirmDelete}
							type="button"
							className="btn btn-danger"
						>
							{t("DELETE")}
						</button>
					</div>
				</div>

				<div className="modal-body">
					<form>
						<textarea
							onChange={onChange}
							value={inputValue}
							style={{
								border: "1px solid #dfe5ef",
							}}
							className="w-100 h-300 rounded-4 p-2"
						/>
					</form>
				</div>
				<div className="modal-footer mt-4">
					<button
						type="button"
						className="btn btn-light-indigo text-dark me-2 "
						onClick={closeModal}
					>
						{t("CANCEL")}
					</button>
					<button
						onClick={confirmEdit}
						type="button"
						className="btn btn-success"
					>
						{t("SAVE")}
					</button>
				</div>
			</div>
		</div>
	);
};

export default memo(ModalEditCustomerNotes);
