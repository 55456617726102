import Swal from "sweetalert2";

export const CONFIG = {
	BASE_URL: process.env.REACT_APP_BASE_URL, //"https://kare-api.blue-dragon-develop.com/api/v1",
};

export const swalWithBootstrapRed = Swal.mixin({
	customClass: {
		confirmButton: "btn btn-secondary",
		cancelButton: "btn btn-light-indigo me-2",
	},
	confirmButtonColor: "red",
	buttonsStyling: false,
});

export const STATUS_TYPE = {
	APPLICATION_APPLIED: "APPLICATION_APPLIED",
	TERMINATION_REQUESTED: "TERMINATION_REQUESTED",
	APPLICATION_REJECTED: "APPLICATION_REJECTED",
	TERMINATION_REJECTED: "TERMINATION_REJECTED",
	APPLICATION_APPROVED: "APPLICATION_APPROVED",
	CONTRACT_IN_PROGRESS: "CONTRACT_IN_PROGRESS",
	EXPIRED: "EXPIRED",
	CANCELLATION_REQUESTED: "CANCELLATION_REQUESTED",
	CANCELLATION_REJECTED: "CANCELLATION_REJECTED",
	CANCELLATION_COMPLETED: "CANCELLATION_COMPLETED",
};

export interface IContractStatus {
	key: string;
	class: string;
}

export const CONTRACT_STATUS = [
	{
		key: "APPLICATION_APPLIED",
		class: "applicationApplied",
	},
	{
		key: "TERMINATION_REQUESTED",
		class: "terminationRequested",
	},
	{
		key: "APPLICATION_REJECTED",
		class: "applicationRejected",
	},
	{
		key: "TERMINATION_REJECTED",
		class: "terminationRejected",
	},
	{
		key: "APPLICATION_APPROVED",
		class: "applicationApproved",
	},
	{
		key: "TERMINATION_COMPLETED",
		class: "terminationCompleted",
	},
	{
		key: "CONTRACT_IN_PROGRESS",
		class: "contractInProgress",
	},
	{
		key: "EXPIRED",
		class: "expired",
	},
	{
		key: "CANCELLATION_REQUESTED",
		class: "cancellationRequested",
	},
	{
		key: "CANCELLATION_REJECTED",
		class: "cancellationRejected",
	},
	{
		key: "CANCELLATION_COMPLETED",
		class: "cancellationCompleted",
	},
];

export interface ICOUNTRY_RESIDENT {
	key: string;
}

export const COUNTRY_RESIDENT = [
	{
		key: "VIETNAM",
	},
	{
		key: "USA",
	},
	{
		key: "THAILAND",
	},
];

export const CHILD_LINK = {
	VIEW_CONTRACT: "VIEW_CONTRACT",
	PENDING_CONTRACT: "PENDING_CONTRACT",
	CANCELLING_CONTRACT: "CANCELLING_CONTRACT",
	EARLY_TERMINATION_CONTRACT: "EARLY_TERMINATION_CONTRACT",
	VIEW_PRODUCT: "VIEW_PRODUCT",
	ACTUAL_LOSS_PRODUCT: "ACTUAL_LOSS_PRODUCT",
	POST_TRANSIT_PRODUCT: "POST_TRANSIT_PRODUCT",
	COUNTRY_MANAGEMENT: "COUNTRY_MANAGEMENT",
	INSURED_ITEMS: "INSURED_ITEMS",
};

export const PARENT_LINK = {
	MEMBER_MANAGEMENT: "MEMBER_MANAGEMENT",
	INSURANCE_CUSTOMER_MANAGEMENT: "INSURANCE_CUSTOMER_MANAGEMENT",
	CONTRACT: "CONTRACT",
	PRODUCT: "PRODUCT",
	SETTING: "SETTING",
};

export const ERROR_CODE = {
	ERR_TOKEN_EXPIRED: "ERR_TOKEN_EXPIRED",
	ERR_ACCESS_DENIED: "ERR_ACCESS_DENIED",
	ERR_PASSWORD_IS_INVALID: "ERR_PASSWORD_IS_INVALID",
};

export interface IError {
	result_code?: string;
	result_msg?: string;
	code: string;
	errorCode?: string;
}

export interface ICountry {
	id: number;
	name: string;
	nameEn: string;
	code: string;
}

export enum TYPE {
	SUCCESS = "SUCCESS",
	ERROR = "ERROR",
}

export interface IResponseData {
	data: any;
	type: TYPE.ERROR | TYPE.SUCCESS;
	messages: any;
}

export const DEFAULT = {
	pageNumber: 0,
	pageSize: 10,
};

export interface IMember {
	id: number;
	code: string;
	phone: string;
	birthday: string;
	username: string;
	email: string;
	fullName: string;
	firstNameEn: string;
	lastNameEn: string;
	idCard: string;
	gender: string;
	registrationDate: string;
	contractFinalAmount: number;
	contractQuantity: number;
	country: {
		id: number;
		name: string;
		nameEn: string;
		code: string;
	};
}

export interface IProductActualLossItem {
	id: number;
	name: string;
	code: string;
	feeFile: string;
}

export interface IProductActualLossConfig {
	id: number;
	name: string;
	code: string;
	feeFile: string;
	status: string;
	country: ICountry;
	lastApplyDate: string;
}

export interface IProductPrice {
	id: number;
	age: number;
	gender: number;
	price: number;
}

export interface IProductFeature {
	id: number;
	code: string;
	name: string;
	price: number;
	typeValue?: string;
}

export interface IConfigActualLoss {
	id: number;
	name: string;
	code: string;
	typeValue: string;
	price?: number;
}

export interface IConfigMedical {
	id: number;
	name: string;
	code: string;
	typeValue: string;
	price?: number;
}

export interface IConfigOverseaService {
	id: number;
	name: string;
	code: string;
	type: string;
	typeValue: string;
	displayOrder: number;
	isMain: boolean;
	isPreview: boolean;
}

export interface PostTransitProductFeature {
	id: number;
	code: string;
	name: string;
	price: number;
}

export interface IProductPostTransitItem {
	id: number;
	name: string;
	code: string;
	feeFile: string;
	productFeatures: PostTransitProductFeature[];
}

export interface IProductPostTransitConfig {
	id?: number;
	name: string;
	code: string;
	type?: string;
	typeValue?: "NUMBER";
	displayOrder?: number;
	isMain?: boolean;
	isPreview?: boolean;
	price: number | string;
}
export interface IAgent {
	id: number;
	code: string;
	role: string;
	username: string;
	email: string;
	fullName: string;
	idCard: string;
	gender: string;
}

interface IProductFeatBoolean {
	id: number;
	code: string;
	name: string;
	isChecked: boolean;
}

interface IProductFeat {
	id: number;
	code: string;
	name: string;
	price: number;
}

interface IHistoryItem {
	id: number;
	status: string;
	createdDate: string;
	note: string;
}

export interface ICustomer {
	code: number;
	id: number;
	fullName: string;
	fullNameEn: string;
	firstNameEn: string;
	lastNameEn: string;
	idCard: string;
	email: string;
	isMember: boolean;
	registrationDate: string;
	contractFinalAmount: number | undefined;
	contractQuantity: number | undefined;
	dateOfBirth: string;
	phone: string;
	gender: string;
	country?: ICountry;
	member: IMember;
}

export interface IContractDetail {
	id: number;
	code: string;
	status: string;
	fullName: string;
	email: string;
	phone: string;
	applyDate: string;
	insuranceStartDate: string;
	insuranceEndDate: string;
	expectedEndDate: string;
	expectedStartDate: string;
	finalAmount: number;
	certificateFile: string;
	certificateNumber: string;
	agent: IAgent;
	country: ICountry;
	customer: ICustomer;
	member: IMember;
	serviceAmount: number;
	payment: {
		id: number;
		goodName: string;
		moid: string;
		tid: string;
		price: number;
		payMethod: string;
		buyerName: string;
		createdDate: string;
	};
	product: {
		id: number;
		name: string;
		code: string;
		feeFile: string;
		minAge: number;
		maxAge: number;
		productFeatures: IProductFeatBoolean[];
		postTransitInsurance: {
			id: number;
			name: string;
			code: string;
			feeFile: string;
			productFeatures: IProductFeat[];
		};
		actualLossInsurance: {
			id: number;
			name: string;
			code: string;
			feeFile: string;
			productFeatures: IProductFeat[];
		};
		status: string;
	};
	productPrice: {
		id: number;
		age: number;
		gender: number;
		price: number;
	};
	actualLossPrice: {
		id: number;
		age: number;
		gender: number;
		price: number;
	};
	postTransitPrice: {
		id: number;
		age: number;
		gender: number;
		price: number;
	};
	history: IHistoryItem[];
	// transactions:? ITransaction[];
}

export interface IProductItem {
	id: number;
	name: string;
	code: string;
	feeFile: string;
	status: string;
	country: ICountry;
	lastApplyDate: string;
	createdDate: string;
}

export interface IProductDetailInfo {
	id: number;
	name: string;
	code: string;
	feeFile: string;
	minAge: number;
	maxAge: number;
	productFeatures: IProductFeatBoolean[];
	postTransitInsurance: {
		id: number;
		name: string;
		code: string;
		feeFile: string;
		productFeatures: IProductFeat[];
	};
	actualLossInsurance: {
		id: number;
		name: string;
		code: string;
		feeFile: string;
		productFeatures: IProductFeat[];
	};
	status: string;
}

export const NOT_AVAILABLE = "";

export const PRODUCT_FEATURES_NAME = {
	DomesticMedicalTransport: "DOMESTIC_MEDICAL_TRANSPORT",
	MedicalTransportToNeighboringCountry:
		"MEDICAL_TRANSFER_TO_NEIGHBORING_COUNTRIES",
	EmergencyMedicalTransferToHomeCountry:
		"EMERGENCY_MEDICAL_TRANSPORT_TO_HOME_COUNTRY",
	RepatriationOfRemains: "REPATRIATION_OF_REMAINS",
} as const;

export type ProductFeaturesName = typeof PRODUCT_FEATURES_NAME;

interface Agent {
	// Add properties here if the agent object has any
}

interface Product {
	id: number;
	name: string;
	code: string;
}

export interface IContractItem {
	id: number;
	agent: Agent;
	code: string;
	status: string;
	fullName: string;
	idCard: string;
	email: string;
	phone: string;
	applyDate: string;
	insuranceStartDate: string;
	insuranceEndDate: string;
	finalAmount: number;
	certificateFile: string;
	certificateNumber: string;
	country: ICountry;
	product: Product;
	dateOfBirth: string;
	payment: {
		payMethod: string;
	};
}

export interface IShipmentMessage {
	sentDate: string;
	toAddresses: string;
}
