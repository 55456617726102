import { createAsyncThunk } from "@reduxjs/toolkit";
import { globalStore } from "../store";
import { queryApi } from "../../common/queryAPI";
import { apiRequest } from "../../common/api.request";
import { TYPE } from "../../common/define";
import { listProductActualLossActions } from "./listProductActualLossSlice";
// import { listMemberActions } from "./listProductActualLossSlice";

export const GET_LIST_PRODUCT_ACTUAL_LOSS = createAsyncThunk(
	"listProductActualLossSlice/GET_LIST_PRODUCT_ACTUAL_LOSS",
	async (data?: {
		pageNumber?: number;
		pageSize?: number;
		isSearch?: boolean;
	}) => {
		try {
			const { pageNumber, pageSize, isSearch } = data || {};

			const keywordSearch = isSearch
				? globalStore.getState().listProductActualLossReducers.keyword
				: "";
			const countryIdSearch = isSearch
				? globalStore.getState().listProductActualLossReducers.countryId
				: "";

			const currentPage = isSearch
				? globalStore.getState().listProductActualLossReducers.currentPage
				: 0;

			const params = isSearch
				? {
						pageNumber: !currentPage ? currentPage : currentPage - 1,
						pageSize,
						keyword: keywordSearch,
						countryId: countryIdSearch,
					}
				: {
						pageNumber: !currentPage ? currentPage : currentPage - 1,
						pageSize,
					};

			const res = await queryApi(
				apiRequest.adminGetListProductActualLoss,
				{},
				params,
			);

			if (res.data) {
				globalStore.dispatch(
					listProductActualLossActions.updateListProductActualLoss(res.data),
				);
			}

			if (res.type === TYPE.ERROR) {
				globalStore.dispatch(
					listProductActualLossActions.setErrorCode(res.messages[0].code),
				);
			}
		} catch (e) {
			console.log("error", e);
		}
	},
);
