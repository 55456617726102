import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocale } from "../../hooks/useLocale";
import { StringLiteral } from "typescript";
import KareInput from "../KareInput";
import { getFileNameWithURL } from "../../common/utils";
import useAddNewPostTransit from "../../pages/product/addNewPostTransit/useAddNewPostTransit";

interface IProps {
	id?: string;
	name?: string;
	file: string | any;
	onChange?: (data: string) => void;
	onUpload?: (file: any) => void;
	errorName?: string;
	isDisabled?: boolean;
	hasNotUploadFile?: boolean;
}

const InsuranceInformationPostTransit: React.FC<IProps> = ({
	id,
	name,
	file,
	errorName,
	isDisabled,
	onUpload,
	onChange,
	hasNotUploadFile,
}) => {
	const { t } = useLocale();
	const navigate = useNavigate();

	const handleOnChange = (e: any) => {
		const value = e.target.value;
		onChange && onChange(value);
	};

	const openLink = () => {
		window.open(file, "_blank");
	};

	const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
		const fileUp = event.target.files ? event.target.files[0] : null;
		onUpload && onUpload(fileUp);
	};

	return (
		<div className="card">
			<div className="card-header">
				<h4 className="mb-0">{t("INSURANCE_INFORMATION")}</h4>
			</div>
			{id && (
				<div className="card-body pb-0">
					<div className="row">
						<div className="col-3 justify-content-center align-self-center fw-bolder">
							{t("POST_TRANSIT_CASE_NUMBER")}
						</div>
						<div className="col-9 justify-content-center align-self-center">
							{/* <input className="form-control" defaultValue="KIM HONG DO" /> */}
							<input
								type="text"
								className="form-control"
								placeholder={`${id}`}
								disabled
							/>
							<div className="invalid-feedback">{t("INVALID_INPUT_NAME")}</div>
						</div>
					</div>
				</div>
			)}
			<div className="card-body pb-0">
				<div className="row">
					<div className="col-3 justify-content-center align-self-center fw-bolder">
						{t("POST_TRANSIT_CASE_NAME")}
					</div>
					<div className="col-9 justify-content-center align-self-center">
						<KareInput
							name={"caseName"}
							placeholder={t("POST_TRANSIT_LOSS_CASE_NAME")}
							isInValid={name?.length === 0 && name !== "undefined"}
							textInValid={errorName}
							isRequire={true}
							disabled={isDisabled}
							value={name}
							onChange={handleOnChange}
						/>
					</div>
				</div>
			</div>

			<div className="card-body">
				<div className="row">
					<div className="col-3 justify-content-center align-self-center fw-bolder">
						{t("INSURANCE_FEE_FILE")}
					</div>

					{file ? (
						<div
							role="button"
							onClick={openLink}
							className="col-5 justify-content-center align-self-center"
						>
							{getFileNameWithURL(file)}
						</div>
					) : !id ? (
						<div className="col-5 justify-content-center align-self-center">
							<div>
								<div className="custom-file">
									<input
										onChange={handleFileUpload}
										type="file"
										className="form-control"
										accept=".csv"
									/>

									{hasNotUploadFile && (
										<div className="invalid-feedback block">
											{t("CERTIFICATE_URL_REQUIRED")}
										</div>
									)}
								</div>
							</div>
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default InsuranceInformationPostTransit;
