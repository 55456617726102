import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IError, IShipmentMessage } from "../../common/define";
import { GET_PRODUCT_ACTUAL_LOSS_DETAIL } from "../productActualLoss/actions";
import { addAsyncReducers } from "../store";

export interface IContractMessage {
	data: IShipmentMessage[];
	currentPage: number;
	keyword: string;
	countryId: string;
}

interface IOtherState {
	isLoading: boolean;
}

const initialState: IContractMessage & IOtherState & IError = {
	data: [],
	currentPage: 1,
	keyword: "",
	countryId: "",
	result_code: "",
	result_msg: "",
	code: "",
	isLoading: false,
};

const contractMessageSlice = createSlice({
	name: "contractMessageSlice",
	initialState,
	reducers: {
		updateData: (state, action: PayloadAction<any>) => {
			return {
				...state,
				data: action.payload,
			};
		},
		setErrorCode: (state, action: PayloadAction<string>) => {
			state.code = action.payload;
			return state;
		},
		reset: () => initialState,
	},
	extraReducers: (builder) => {
		addAsyncReducers(builder, GET_PRODUCT_ACTUAL_LOSS_DETAIL, initialState);
	},
});

export const listContractMessageActions = contractMessageSlice.actions;
export const listContractMessageReducers = contractMessageSlice.reducer;
