import { useLocale } from "../../hooks/useLocale";
import LogoSignIn from "../../assets/kare_login_img.svg";
import KareInput from "../../components/KareInput";
import { useEffect, useState } from "react";
import { emailRegex } from "../../common/common";
import KareButton from "../../components/KareButton";
import { globalStore, RootState } from "../../state/store";
import { LOGIN_ACTION } from "../../state/auth/actions";
import { UnknownAction } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { TYPE } from "../../common/define";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { userInfoSelectors } from "../../state/userInfo/userInfoSlice";

const SignInPage = () => {
	const navigate = useNavigate();
	const { t } = useLocale();
	const [isShowError, setIsShowError] = useState(false);
	const fullName = useSelector(userInfoSelectors.fullName);

	const [userInfo, setUserInfo] = useState({
		userName: "",
		password: "",
	});

	const [userInfoError, setUserInfoError] = useState({
		userName: false,
		password: false,
	});

	const userInfoStore = useSelector(
		(state: RootState) => state.userInfoReducers,
	);

	// console.log(userInfoA);

	function checkError(rs: any, field: string) {
		if (!rs) {
			setUserInfoError((prev) => ({ ...prev, [field]: true }));
		} else {
			setUserInfoError((prev) => ({ ...prev, [field]: false }));
		}
	}

	function validateField(field: string, value: any, regex: any) {
		const rs = value.match(regex);
		checkError(rs, field);
	}

	const handleInput = (e: any) => {
		setIsShowError(false);
		const field = e.target.name;
		const value = e.target.value;
		setUserInfo((prev) => ({ ...prev, [field]: value }));

		if (field === "userName") {
			validateField(field, value, emailRegex);
		}

		if (field === "password") {
			if (!value.length) {
				setUserInfoError((prev) => ({ ...prev, [field]: true }));
			} else {
				setUserInfoError((prev) => ({ ...prev, [field]: false }));
			}
		}
	};

	const onLogin = async () => {
		if (!userInfo.password.length) {
			setUserInfoError((prev) => ({ ...prev, password: true }));
		}
		if (!userInfo.userName.length) {
			setUserInfoError((prev) => ({ ...prev, userName: true }));
		}

		if (!userInfo.password.length || !userInfo.userName.length) {
			return;
		}

		await globalStore.dispatch(
			LOGIN_ACTION({
				body: userInfo,
				t,
				setIsShowError,
			}) as unknown as UnknownAction,
		);
	};

	useEffect(() => {
		if (fullName?.length > 0) {
			navigate("/");
		}
	}, [fullName]);

	console.log("userInfoError", userInfoError);

	return (
		<div id="sign-in-page">
			<div className="left">
				<img className="logo-sign-in" src={LogoSignIn} />
			</div>
			<div className="right">
				<div className="sign-in-section">
					<h4
						style={{
							color: "#00BE77",
							marginBottom: 30,
							fontSize: 40,
							fontWeight: 700,
						}}
						className="fs-9 fw-bolder"
					>
						Kare
					</h4>

					<div className="mb-4 w-100">
						<KareInput
							label={`${t("ID")}`}
							name={"userName"}
							placeholder={t("PLEASE_ENTER_YOUR_ANY", {
								any: `${t("ID")} (${t("EMAIL")})`,
							})}
							isInValid={userInfoError.userName}
							textInValid={`${t("PLEASE_RE_ENTER_YOUR_ANY", {
								any: `${t("ID")} (${t("EMAIL")})`,
							})}`}
							isRequire={true}
							onChange={handleInput}
						/>
					</div>
					<div className="mb-4 w-100">
						<KareInput
							label={`${t("PASSWORD")}`}
							name={"password"}
							isPassword
							placeholder={t("PLEASE_ENTER_YOUR_ANY", {
								any: t("PASSWORD"),
							})}
							isInValid={userInfoError.password}
							textInValid={`${t("PLEASE_RE_ENTER_YOUR_ANY", {
								any: t("PASSWORD"),
							})}`}
							isRequire={true}
							onChange={handleInput}
						/>
					</div>
					{isShowError && (
						<div
							style={{ marginTop: -15, marginBottom: 30 }}
							className=" app-badge danger"
						>
							{t("INVALID_LOGIN_ACCOUNT")}
						</div>
					)}
					<KareButton
						text={t("LOG_IN")}
						onClick={onLogin}
						className="w-100 btn btn-success"
					/>
				</div>
			</div>
		</div>
	);
};

export default SignInPage;
