import React, { memo, ReactNode, useState } from "react";
import { useLocale } from "../../hooks/useLocale";
import { globalStore, RootState } from "../../state/store";
import { listCustomerActions } from "../../state/listCustomer/listCustomerSlice";
import { useSelector } from "react-redux";

function printNumbers(n: number) {
	if (!n || n <= 0) {
		console.log("Invalid input. Please enter a positive number.");
		return [];
	}

	const numbers = Array.from({ length: n }, (_, i) => i + 1);
	// console.log(numbers.join(" "));
	console.log(numbers);
	return numbers;
}

interface IProps {
	title: string;
	thead?: string[];
	titleBtns?: ReactNode[];
	tbody?: ReactNode;
	additionBody?: ReactNode;
	countPage?: number;
	currentPage?: number;
	handleChangePage?: (idx: number) => void;
}

const TableList: React.FC<IProps> = ({
	title,
	thead,
	tbody,
	additionBody,
	countPage = 5,
	currentPage,
	handleChangePage,
	titleBtns,
}) => {
	const { t } = useLocale();

	const onChangePage = (page: number) => {
		// setCurrentPage(page);
		console.log("page+++", page);
		handleChangePage && handleChangePage(page);
	};

	console.log("currentPage", currentPage);

	return (
		<div className="wrap-table-list col-12">
			<div className="card">
				<div className="card-header">
					<div className="d-flex justify-content-between align-items-center">
						<h4>{title}</h4>
						<div>{titleBtns && titleBtns.map((item) => item)}</div>
					</div>
				</div>
				{additionBody}
				<div className="card-body list text-dark overflow-auto">
					<table className="table border table-hover text-nowrap fs-2">
						<thead>
							<tr>
								{thead?.map((tit) => (
									<th className="text-center" key={"thead" + tit} scope="col">
										{tit}
									</th>
								))}
							</tr>
						</thead>
						<tbody>{tbody}</tbody>
					</table>
					{countPage > 1 && (
						<div className="row">
							<nav aria-label="...">
								<ul className="pagination pagination-sm d-flex justify-content-center">
									{printNumbers(countPage).map((page) => (
										<li
											key={"pagi" + page}
											onClick={() => onChangePage(page)}
											className={`page-item ${page === currentPage ? "active" : ""}`}
											aria-current="page"
										>
											<span role="button" className="page-link">
												{page}
											</span>
										</li>
									))}
								</ul>
							</nav>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default memo(TableList);
