import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocale } from "../../hooks/useLocale";
import { StringLiteral } from "typescript";
import { getFileNameWithURL } from "../../common/utils";
import KareInput from "../KareInput";

interface IProps {
	id?: string;
	name?: string;
	errorName?: string;
	file?: string | any;
	isDisabled?: boolean;
	productId?: number;
	onUpload?: (file: any) => void;
	onChange?: (data: string) => void;
	hasNotUploadFile?: boolean;
}

const InsuranceInformation: React.FC<IProps> = ({
	id,
	name,
	errorName,
	file,
	isDisabled,
	productId,
	onUpload,
	onChange,
	hasNotUploadFile,
}) => {
	const { t } = useLocale();

	const handleOnChange = (e: any) => {
		const value = e.target.value;
		onChange && onChange(value);
	};

	const openLink = () => {
		window.open(file, "_blank");
	};

	const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
		const fileUp = event.target.files ? event.target.files[0] : null;
		onUpload && onUpload(fileUp);
	};

	return (
		<div className="card">
			<div className="card-header">
				<h4 className="mb-0">{t("INSURANCE_INFORMATION")}</h4>
			</div>
			{id && (
				<div className="card-body pb-0">
					<div className="row">
						<div className="col-3 justify-content-center align-self-center fw-bolder">
							{t("ACTUAL_LOSS_CASE_NUMBER")}
						</div>
						<div className="col-9 justify-content-center align-self-center">
							{/* <input className="form-control" defaultValue="KIM HONG DO" /> */}
							<input
								type="text"
								className="form-control"
								placeholder={`${id}`}
								disabled
							/>
							<div className="invalid-feedback">{t("INVALID_INPUT_NAME")}</div>
						</div>
					</div>
				</div>
			)}
			<div className="card-body pb-0">
				<div className="row">
					<div className="col-3 justify-content-center align-self-start fw-bolder">
						{t("ACTUAL_LOSS_CASE_NAME")}
					</div>
					<div className="col-9 justify-content-center align-self-center">
						<KareInput
							name={"caseName"}
							placeholder={t("PLEASE_ENTER_YOUR_ANY", {
								any: `${t("ACTUAL_LOSS_CASE_NAME")}`,
							})}
							isInValid={name?.length === 0 && name !== undefined}
							textInValid={errorName}
							isRequire={true}
							disabled={isDisabled}
							value={name}
							onChange={handleOnChange}
						/>
					</div>
				</div>
			</div>

			<div className="card-body">
				<div className="row">
					<div className="col-3 justify-content-center align-self-center fw-bolder">
						{t("INSURANCE_FEE_FILE")}
					</div>

					{file ? (
						<div
							role="button"
							onClick={openLink}
							className="col-5 justify-content-center align-self-center"
						>
							{getFileNameWithURL(file)}
						</div>
					) : (
						<div className="col-5 justify-content-center align-self-center">
							<div>
								<div className="custom-file">
									<input
										onChange={handleFileUpload}
										type="file"
										className="form-control"
										accept=".csv"
									/>
									{hasNotUploadFile && (
										<div className="invalid-feedback block">
											{t("CERTIFICATE_URL_REQUIRED")}
										</div>
									)}
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default InsuranceInformation;
