import React, { memo, useEffect, useMemo } from "react";
import { useLocale } from "../../hooks/useLocale";
import TextStatus from "../../components/TextStatus";
import TableList from "../../components/TableList";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { globalStore, RootState } from "../../state/store";
import { GET_LIST_CONTRACT_BY_CUSTOMER } from "../../state/listContract/actions";
import { UnknownAction } from "@reduxjs/toolkit";
import { listContractActions } from "../../state/listContract/listContractSlice";
import { NOT_AVAILABLE } from "../../common/define";
import { formatMoney } from "../../common/utils";

const contractDummy = [
	{
		contractNo: 900004,
		name: "KIM HONG DO",
		contractApplicationDate: "2023.12.12",
		product: "Plan A",
		startDate: "2023.01.01",
		endDate: "2026.01.01",
		registrationPeriod: "365 days",
		paymentAmount: "484,494",
		agency: "agency 1",
		paymentInformation: "Payment information",
		certificateOfMember: "Certificate of member",

		// contractNo: 900003,
		// name: "PARK MI SUN",
		// status: "APPLICATION_REJECTED",
		// appliedOn: "2024-12-12",
		// product: "Plan A",
		// duration: 365,
		// amount: 482000,
		// startOn: "2024-01-01",
		// endOn: "2024-12-31",
		// agency: "agency",
		// agent: "agent",
		// residentNumber: "800000-2000000",
	},
	{
		contractNo: 900004,
		name: "KIM HONG DO",
		contractApplicationDate: "2023.12.12",
		product: "Plan A",
		startDate: "2023.01.01",
		endDate: "2026.01.01",
		registrationPeriod: "365 days",
		paymentAmount: "484,494",
		agency: "agency 1",
		paymentInformation: "Payment information",
		certificateOfMember: "Certificate of member",
	},
	{
		contractNo: 900004,
		name: "KIM HONG DO",
		contractApplicationDate: "2023.12.12",
		product: "Plan A",
		startDate: "2023.01.01",
		endDate: "2026.01.01",
		registrationPeriod: "365 days",
		paymentAmount: "484,494",
		agency: "agency 1",
		paymentInformation: "Payment information",
		certificateOfMember: "Certificate of member",
	},
	{
		contractNo: 900004,
		name: "KIM HONG DO",
		contractApplicationDate: "2023.12.12",
		product: "Plan A",
		startDate: "2023.01.01",
		endDate: "2026.01.01",
		registrationPeriod: "365 days",
		paymentAmount: "484,494",
		agency: "agency 1",
		paymentInformation: "Payment information",
		certificateOfMember: "Certificate of member",
	},
];

const InsuranceContactDetail: React.FC = () => {
	const { t } = useLocale();
	const { id } = useParams();

	console.log("id-InsuranceContactDetail", id);

	const navigate = useNavigate();

	// const goToDetail = (idx: number) => {
	// 	history(`/member/${idx}`);
	// };

	const listContract = useSelector(
		(state: RootState) => state.listContractReducers.data.content,
	);
	const listContractData = useSelector(
		(state: RootState) => state.listContractReducers.data,
	);

	const totalPagesStore = useSelector(
		(state: RootState) => state.listContractReducers.data.totalPages,
	);
	// const totalElements = useSelector(
	// 	(state: RootState) => state.listContractReducers.data.totalElements,
	// );
	const currentPageStore = useSelector(
		(state: RootState) => state.listContractReducers.currentPage,
	);

	console.log("listContract", listContract);
	console.log("listContractData", listContractData);

	const initListContract = () => {
		globalStore.dispatch(listContractActions.updateCurrentId(Number(id)));
		globalStore.dispatch(listContractActions.updateCurrentPage(1));
		globalStore.dispatch(
			GET_LIST_CONTRACT_BY_CUSTOMER({
				id: Number(id),
			}) as unknown as UnknownAction,
		);
	};

	const goToDetail = (idx: number) => {
		navigate(`/member/${idx}`);
	};

	const handleChangePage = async (idx: number) => {
		console.log("onChangePage", idx);

		await globalStore.dispatch(
			listContractActions.updateCurrentPage(idx) as unknown as UnknownAction,
		);

		await globalStore.dispatch(
			GET_LIST_CONTRACT_BY_CUSTOMER({
				id: Number(id),
				isSearch: true,
				pageSize: 10,
				pageNumber: idx,
			}) as unknown as UnknownAction,
		);
	};

	useEffect(() => {
		initListContract();
	}, []);

	const thead = [
		t("CONTRACT_NO"),
		t("STATUS"),
		t("CONTRACT_APPLICATION_DATE"),
		t("PRODUCT"),
		t("INSURANCE_START_DATE"),
		t("INSURANCE_END_DATE"),
		t("REGISTRATION_PERIOD"),
		t("PAYMENT_AMOUNT"),
		t("PAYMENT_INFORMATION"),
		t("CERTIFICATE_OF_MEMBER"),
	];

	console.log("listContract", listContract);

	const tbody = useMemo(() => {
		return listContract.length > 0 ? (
			listContract.map((contract, idx) => {
				const onClick = () => navigate(`/contract/view/${contract?.id}`);
				return (
					<tr onClick={onClick} role="button" key={"contract" + idx}>
						<td className="text-center">{contract?.code || NOT_AVAILABLE}</td>
						<td className="text-center">
							{contract?.fullName || NOT_AVAILABLE}
						</td>
						<td className="text-center">
							<TextStatus
								colorClass={contract?.status?.toLowerCase()}
								textStatus={t(contract?.status)}
							/>
						</td>
						<td className="text-center">
							{contract?.product?.name || NOT_AVAILABLE}
						</td>
						<td className="text-center">
							{contract?.insuranceStartDate || NOT_AVAILABLE}
						</td>
						<td className="text-center">
							{contract?.insuranceEndDate || NOT_AVAILABLE}
						</td>
						<td className="text-center">{"365"}</td>
						<td className="text-center">
							{contract?.finalAmount
								? formatMoney(contract?.finalAmount)
								: NOT_AVAILABLE}
						</td>
						<td className="text-center">
							{contract?.payment?.payMethod || NOT_AVAILABLE}
						</td>
						<td className="text-center">{contract?.certificateNumber}</td>
					</tr>
				);
			})
		) : (
			<tr>
				{thead.map((item, idx) => (
					<td key={item + idx} className="text-center">
						{NOT_AVAILABLE}
					</td>
				))}
			</tr>
		);
	}, [listContract]);

	return (
		<TableList
			title={t("INSURANCE_CONTACT_DETAILS")}
			thead={thead}
			tbody={tbody}
			countPage={totalPagesStore}
			currentPage={currentPageStore}
			handleChangePage={handleChangePage}
		/>
	);
};

export default memo(InsuranceContactDetail);
