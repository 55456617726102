import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { apiRequest } from "../../common/api.request";
import { TYPE } from "../../common/define";
import { queryApi, uploadCertificateFileAPI } from "../../common/queryAPI";
import { globalStore } from "../store";
import { contractInfoActions } from "./contractInfoSlice";
import { listContractMessageActions } from "./contractMessage";
import { contractStatusActions } from "./contractStatusSlice";
// import { listCustomerActions } from "./customerInfoSlice";

export const GET_CONTRACT_INFO = createAsyncThunk(
	"contractInfoSlice/GET_CONTRACT_INFO",
	async (data?: { contractId: number }) => {
		try {
			const { contractId } = data || {};

			const res = await queryApi(
				apiRequest.adminGetContractInfo,
				{},
				{ contractId },
			);

			console.log("res", res);

			if (res.type === TYPE.SUCCESS && res.data) {
				globalStore.dispatch(contractInfoActions.updateData(res.data));
			}

			if (res.type === TYPE.ERROR) {
				globalStore.dispatch(
					contractInfoActions.setErrorCode(res.messages[0].code),
				);
			}
		} catch (e) {
			console.log("error", e);
		}
	},
);

export const GET_CONTRACT_STATUS_ALLOW_UPDATE = createAsyncThunk(
	"contractInfoSlice/GET_CONTRACT_STATUS_ALLOW_UPDATE",
	async (data: { id: number }) => {
		try {
			const { id } = data;
			const res = await queryApi(
				apiRequest.adminGetContractStatusAllowUpdate,
				{},
				{ contractId: id },
			);

			console.log("res", res);

			if (res.type === TYPE.SUCCESS && res.data) {
				globalStore.dispatch(contractStatusActions.updateData(res.data));
			}

			if (res.type === TYPE.ERROR) {
				globalStore.dispatch(
					contractStatusActions.setErrorCode(res.messages[0].code),
				);
			}
		} catch (e) {
			console.log("error", e);
		}
	},
);

export const UPDATE_CONTRACT_STATUS_ACTION = createAsyncThunk(
	"contractInfoSlice/UPDATE_CONTRACT_STATUS_ACTION",
	async (data: {
		id: number;
		note: string;
		status: string;
		callbackSuccess?: (data?: any) => void;
	}) => {
		try {
			const { id, note, status, callbackSuccess } = data;
			console.log({
				contractId: id,
				note,
				status,
			});
			const res = await queryApi(apiRequest.adminUpdateContractStatus, {
				contractId: id,
				note,
				status,
			});

			console.log("res", res);

			if (res.type === TYPE.SUCCESS && res.data) {
				globalStore.dispatch(GET_CONTRACT_INFO({ contractId: id }) as any);
				// globalStore.dispatch(contractStatusActions.updateData(res.data));

				callbackSuccess && callbackSuccess(res.data);
			}

			if (res.type === TYPE.ERROR) {
				// globalStore.dispatch(
				// 	contractStatusActions.setErrorCode(res.messages[0].code),
				// );
			}
		} catch (e) {
			console.log("error", e);
		}
	},
);

export const SAVE_CERTIFICATE_FILE = createAsyncThunk(
	"contractInfoSlice/SAVE_CERTIFICATE_FILE",
	async (data?: {
		formData: any;
		file: any;
		callbackSuccess?: (data?: any) => void;
		callbackFailed?: (data?: any) => void;
	}) => {
		try {
			const { formData, file, callbackSuccess, callbackFailed } = data || {};

			const res = await uploadCertificateFileAPI(file, formData);
			if (res.type === TYPE.SUCCESS && res.messages[0].code) {
				callbackSuccess && callbackSuccess(res.messages[0].code);
			}

			if (res.type === TYPE.ERROR) {
				callbackFailed && callbackFailed(res.messages[0].code);
				toast.error(res.messages[0].code);
			}
		} catch (e) {
			console.log("error", e);
		}
	},
);

export const RESEND_MAIL = createAsyncThunk(
	"contractInfoSlice/RESEND_MAIL",
	async (data: { id: number; callbackSuccess?: (data?: any) => void }) => {
		try {
			const { id, callbackSuccess } = data;

			const res = await queryApi(
				apiRequest.adminResendMail,
				{},
				{ contractId: id },
			);

			if (res.type === TYPE.SUCCESS) {
				callbackSuccess && callbackSuccess();
			}

			if (res.type === TYPE.ERROR) {
				globalStore.dispatch(
					contractStatusActions.setErrorCode(res.messages[0].code),
				);
			}
		} catch (e) {
			console.log("error", e);
		}
	},
);

export const GET_LIST_CONTRACT_EMAIL_MESSAGE = createAsyncThunk(
	"contractInfoSlice/GET_LIST_CONTRACT_EMAIL_MESSAGE",
	async (data: { id: number }) => {
		try {
			const { id } = data;
			const res = await queryApi(
				apiRequest.adminGetContractEmailMessage,
				{},
				{ contractId: id },
			);

			console.log("res", res);

			if (res.type === TYPE.SUCCESS && res.data) {
				globalStore.dispatch(listContractMessageActions.updateData(res.data));
			}

			if (res.type === TYPE.ERROR) {
				globalStore.dispatch(
					listContractMessageActions.setErrorCode(res.messages[0].code),
				);
			}
		} catch (e) {
			console.log("error", e);
		}
	},
);
