import React, { useEffect, useState } from "react";
import { useLocale } from "../../../hooks/useLocale";
import WrapperPage from "../../../components/WrapperPage";
import HeaderMini from "../../../components/HeaderMini";
import { CHILD_LINK, NOT_AVAILABLE, PARENT_LINK } from "../../../common/define";
import { useParams } from "react-router-dom";
import useActiveCurrentPage from "../../../hooks/useActiveCurrentPage";
import { globalStore, RootState } from "../../../state/store";
import { GET_PRODUCT_DETAIL } from "../../../state/listProduct/actions";
import { UnknownAction } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import ProductInformationOnlyView from "../../../components/ProductInformationOnlyView";
import ProductRewardInfoOnlyView from "../../../components/ProductRewardInfoOnlyView";
import ProductInfoPostTransitOnlyView from "../../../components/ProductInfoPostTransitOnlyView";
import ProductInfoActualOnlyView from "../../../components/ProductInfoActualOnlyView";

const EditProductPage: React.FC = () => {
	const { t } = useLocale();
	const { id } = useParams();
	const productDetail = useSelector(
		(state: RootState) => state.productDetailReducers.data,
	);

	const [productStatus, setProductStatus] = useState(
		productDetail?.status ? t(productDetail?.status) : NOT_AVAILABLE,
	);

	const initProductDetail = () => {
		id &&
			globalStore.dispatch(
				GET_PRODUCT_DETAIL({ id: Number(id) }) as unknown as UnknownAction,
			);
	};

	useEffect(() => {
		initProductDetail();
	}, [id]);

	useActiveCurrentPage(CHILD_LINK.VIEW_PRODUCT, PARENT_LINK.PRODUCT);

	return (
		<WrapperPage className="container-fluid py-3">
			<HeaderMini
				title={`${t("PRODUCT_ID_INFORMATION", {
					id: 8001,
				})}`}
				breadcrumbs={[t("PRODUCT_MANAGEMENT"), t("SALES_PRODUCT_MANAGEMENT")]}
			/>

			<ProductInformationOnlyView
				productNumber={`${productDetail?.code || NOT_AVAILABLE}`}
				productName={productDetail?.name || NOT_AVAILABLE}
				minAge={productDetail?.minAge || 0}
				maxAge={productDetail?.maxAge || 0}
				file={productDetail?.feeFile ? productDetail.feeFile : undefined}
				status={
					productDetail?.status ? t(productDetail?.status) : NOT_AVAILABLE
				}
				productStatus={productStatus}
				setProductStatus={setProductStatus}
			/>

			<div className="card">
				<ProductRewardInfoOnlyView />
				<ProductInfoPostTransitOnlyView />
				<ProductInfoActualOnlyView />
			</div>

			<div className="p-3">
				<div>
					<div className="text-end">
						<button
							type="submit"
							className="btn bg-danger-subtle text-danger ms-6 rounded-pill me-4"
						>
							{t("CANCEL")}
						</button>
						<button type="submit" className="btn btn-success rounded-pill px-4">
							{t("SAVE")}
						</button>
					</div>
				</div>
			</div>
		</WrapperPage>
	);
};

export default EditProductPage;
