import { useLocation } from "react-router-dom";
import qs from "qs";

export function useQueryParams() {
	const { search } = useLocation();

	return qs.parse(search, { ignoreQueryPrefix: true }) as Record<
		string,
		string
	>;
}
