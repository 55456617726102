import { createAsyncThunk } from "@reduxjs/toolkit";
import { globalStore } from "../../../store";
import { queryApi } from "../../../../common/queryAPI";
import { apiRequest } from "../../../../common/api.request";
import { TYPE } from "../../../../common/define";
import { configMedicalActions } from "./configMedicalSlice";
import { TFunction } from "i18next";
import Swal from "sweetalert2";
// import { listCustomerActions } from "./customerInfoSlice";
import { v4 as uuidv4 } from "uuid";

export const GET_CONFIG_MEDICAL = createAsyncThunk(
	"configMedicalSlice/GET_CONFIG_MEDICAL",
	async () => {
		try {
			const res = await queryApi(apiRequest.adminGetListMedicalConfig);

			if (res.type === TYPE.SUCCESS && res.data) {
				globalStore.dispatch(
					configMedicalActions.updateConfigMedical(res.data),
				);
			}

			if (res.type === TYPE.ERROR) {
				globalStore.dispatch(
					configMedicalActions.setErrorCode(res.messages[0].code),
				);
			}
		} catch (e) {
			console.log("error", e);
		}
	},
);

export const CREATE_CONFIG_MEDICAL = createAsyncThunk(
	"configMedicalSlice/CREATE_CONFIG_MEDICAL",
	async (data: {
		name: string;
		typeValue: string;
		callback?: (data?: number | any) => void;
	}) => {
		try {
			const code = uuidv4();
			const { name, typeValue, callback } = data;
			const res = await queryApi(apiRequest.adminCreateMedicalConfig, {
				code,
				name,
				typeValue,
			});

			if (res.type === TYPE.SUCCESS) {
				callback && callback();
			}

			if (res.type === TYPE.ERROR) {
				globalStore.dispatch(
					configMedicalActions.setErrorCode(res.messages[0].code),
				);
			}
		} catch (e) {
			console.log("error", e);
		}
	},
);
