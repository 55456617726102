import { createAsyncThunk } from "@reduxjs/toolkit";
import { globalStore } from "../store";
import { queryApi } from "../../common/queryAPI";
import { apiRequest } from "../../common/api.request";
import { TYPE } from "../../common/define";
import { listContractActions } from "./listContractSlice";
// import { listMemberActions } from "./listProductActualLossSlice";

export const GET_LIST_CONTRACT_BY_MEMBER = createAsyncThunk(
	"listContractSlice/GET_LIST_CONTRACT_BY_MEMBER",
	async (data?: {
		id: number;
		pageNumber?: number;
		pageSize?: number;
		isSearch?: boolean;
	}) => {
		try {
			const { pageNumber, pageSize, isSearch, id } = data || {};

			const keywordSearch = isSearch
				? globalStore.getState().listContractReducers.keyword
				: "";
			const countryIdSearch = isSearch
				? globalStore.getState().listContractReducers.countryId
				: "";

			const currentPage = isSearch
				? globalStore.getState().listContractReducers.currentPage
				: 0;

			const params = isSearch
				? {
						pageNumber: !currentPage ? currentPage : currentPage - 1,
						pageSize,
						keyword: keywordSearch,
						countryId: countryIdSearch,
					}
				: {
						pageNumber: !currentPage ? currentPage : currentPage - 1,
						pageSize,
						memberId: id,
					};

			const res = await queryApi(
				apiRequest.adminGetListContractByMember,
				{},
				params,
			);

			if (res.data) {
				globalStore.dispatch(listContractActions.updateData(res.data));
			}

			if (res.type === TYPE.ERROR) {
				globalStore.dispatch(
					listContractActions.setErrorCode(res.messages[0].code),
				);
			}
		} catch (e) {
			console.log("error", e);
		}
	},
);

export const GET_LIST_CONTRACT_BY_CUSTOMER = createAsyncThunk(
	"listContractSlice/GET_LIST_CONTRACT_BY_MEMBER",
	async (data?: {
		id: number;
		pageNumber?: number;
		pageSize?: number;
		isSearch?: boolean;
	}) => {
		try {
			const { pageNumber, pageSize, isSearch, id } = data || {};

			const keywordSearch = isSearch
				? globalStore.getState().listContractReducers.keyword
				: "";
			const countryIdSearch = isSearch
				? globalStore.getState().listContractReducers.countryId
				: "";

			const currentPage = isSearch
				? globalStore.getState().listContractReducers.currentPage
				: 0;

			const params = isSearch
				? {
						pageNumber: !currentPage ? currentPage : currentPage - 1,
						pageSize,
						keyword: keywordSearch,
						countryId: countryIdSearch,
					}
				: {
						pageNumber: !currentPage ? currentPage : currentPage - 1,
						pageSize,
						customerId: id,
					};

			const res = await queryApi(
				apiRequest.adminGetListContractByCustomer,
				{},
				params,
			);

			console.log(res);

			if (res.data) {
				globalStore.dispatch(listContractActions.updateData(res.data));
			}

			if (res.type === TYPE.ERROR) {
				globalStore.dispatch(
					listContractActions.setErrorCode(res.messages[0].code),
				);
			}
		} catch (e) {
			console.log("error", e);
		}
	},
);

export const GET_LIST_CONTRACT = createAsyncThunk(
	"listContractSlice/GET_LIST_CONTRACT",
	async (data?: {
		pageNumber?: number;
		pageSize?: number;
		isSearch?: boolean;
		statuses?: any;
	}) => {
		try {
			const { pageNumber, pageSize, isSearch, statuses } = data || {};
			console.log("statuses", statuses);

			const keywordSearch = isSearch
				? globalStore.getState().listContractReducers.keyword
				: "";
			const countryIdSearch = isSearch
				? globalStore.getState().listContractReducers.countryId
				: "";

			const currentPage = isSearch
				? globalStore.getState().listContractReducers.currentPage
				: 0;

			const params = isSearch
				? {
						pageNumber: !currentPage ? currentPage : currentPage - 1,
						pageSize,
						keyword: keywordSearch,
						countryId: countryIdSearch,
					}
				: {
						pageNumber: !currentPage ? currentPage : currentPage - 1,
						pageSize,
						statuses: statuses ? statuses?.toString() : null,
					};

			const res = await queryApi(apiRequest.adminGetListContract, {}, params);

			console.log("res", res);
			if (res.type === TYPE.SUCCESS) {
				globalStore.dispatch(listContractActions.updateData(res.data));
			}

			if (res.type === TYPE.ERROR) {
				globalStore.dispatch(
					listContractActions.setErrorCode(res.messages[0].code),
				);
			}
		} catch (e) {
			console.log("error", e);
		}
	},
);
