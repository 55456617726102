import React, { memo, useEffect, useMemo } from "react";
import { useLocale } from "../../hooks/useLocale";
import TextStatus from "../../components/TextStatus";
import Table from "../../components/Table";
import { useNavigate, useParams } from "react-router-dom";
import { globalStore, RootState } from "../../state/store";
import { listContractActions } from "../../state/listContract/listContractSlice";
import { GET_LIST_CONTRACT_BY_MEMBER } from "../../state/listContract/actions";
import { UnknownAction } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { formatMoney } from "../../common/utils";
import { NOT_AVAILABLE } from "../../common/define";
import NoData from "../../components/NoData";

const InsuranceContactDetail: React.FC = () => {
	const { t } = useLocale();

	const { id } = useParams();

	console.log("id-InsuranceContactDetail", id);

	const navigate = useNavigate();

	const listContract = useSelector(
		(state: RootState) => state.listContractReducers.data.content,
	);
	const listContractData = useSelector(
		(state: RootState) => state.listContractReducers.data,
	);

	const totalPagesStore = useSelector(
		(state: RootState) => state.listContractReducers.data.totalPages,
	);

	const currentPageStore = useSelector(
		(state: RootState) => state.listContractReducers.currentPage,
	);

	const initListContract = () => {
		globalStore.dispatch(listContractActions.updateCurrentId(Number(id)));
		globalStore.dispatch(listContractActions.updateCurrentPage(1));
		globalStore.dispatch(
			GET_LIST_CONTRACT_BY_MEMBER({
				id: Number(id),
			}) as unknown as UnknownAction,
		);
	};

	const goToDetail = (idx: number) => {
		navigate(`/member/${idx}`);
	};

	const handleChangePage = async (idx: number) => {
		console.log("onChangePage", idx);

		await globalStore.dispatch(
			listContractActions.updateCurrentPage(idx) as unknown as UnknownAction,
		);

		await globalStore.dispatch(
			GET_LIST_CONTRACT_BY_MEMBER({
				id: Number(id),
				isSearch: true,
				pageSize: 10,
				pageNumber: idx,
			}) as unknown as UnknownAction,
		);
	};

	useEffect(() => {
		initListContract();
	}, []);

	const thead = [
		t("CONTRACT_NO"),
		t("NAME"),
		t("STATUS"),
		t("CONTRACT_APPLICATION_DATE"),
		t("PRODUCT"),
		t("REGISTRATION_PERIOD"),
		t("PAYMENT_AMOUNT"),
		t("INSURANCE_START_DATE"),
		t("INSURANCE_END_DATE"),
		t("RESIDENT_REGISTRATION_NUMBER"),
	];

	const tbody = useMemo(() => {
		return listContract.length > 0 ? (
			listContract.map((contract: any, idx: number) => {
				const idCard = contract?.idCard
					? `${contract?.idCard?.slice(0, 6)}-${contract?.idCard?.slice(6)}`
					: NOT_AVAILABLE;

				return (
					<tr role="button" key={"contract" + idx}>
						<td className="text-center">{contract?.code}</td>
						<td className="text-center">{contract?.fullName}</td>
						<td className="text-center">
							<TextStatus
								colorClass={contract?.status?.toLowerCase()}
								textStatus={t(contract?.status)}
							/>
						</td>
						<td className="text-center">{contract.applyDate}</td>
						<td className="text-center">{contract?.product?.name}</td>
						<td className="text-center">{"365"}</td>
						<td className="text-center">
							{contract?.finalAmount ? formatMoney(contract?.finalAmount) : 0}
						</td>
						<td className="text-center">{contract?.insuranceStartDate}</td>
						<td className="text-center">{contract?.insuranceEndDate}</td>
						<td className="text-center">{idCard}</td>
					</tr>
				);
			})
		) : (
			<></>
		);
	}, [listContract]);

	return (
		<Table
			emptyTitle={t("THERE_IS_NO_CONTRACTED_INSURANCE")}
			title={t("INSURANCE_CONTACT_DETAILS")}
			thead={thead}
			tbody={tbody}
			countPage={totalPagesStore}
			currentPage={currentPageStore}
			handleChangePage={handleChangePage}
			total={listContract.length}
		/>
	);
};

export default memo(InsuranceContactDetail);
