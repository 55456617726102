import React, { useCallback } from "react";
import { useParams } from "react-router-dom";

import WrapperPage from "../../components/WrapperPage";
import { useLocale } from "../../hooks/useLocale";
import HeaderMini from "../../components/HeaderMini";
import { globalStore, RootState } from "../../state/store";
import useEffectOnce from "../../hooks/useEffectOnce";
import { useSelector } from "react-redux";
import { UnknownAction } from "@reduxjs/toolkit";
import UserUpdateForm from "../../components/User/UpdateForm";
import UserChangePasswordForm from "../../components/User/ChangePasswordForm";

const MyPage: React.FC = () => {
	const { t } = useLocale();
	const { id } = useParams();

	const user = useSelector((state: RootState) => state.userInfoReducers);

	const isLoading = useSelector(
		(state: RootState) => state.userInfoReducers.isLoading,
	);
	console.log(user);
	return (
		<WrapperPage isLoading={isLoading} className="container-fluid py-3">
			<HeaderMini
				title={`${t("ADMIN_INFORMATION_HEADER")}`}
				breadcrumbs={[t("MY_PAGE"), t("ADMIN_INFORMATION_HEADER")]}
			/>
			<div className="px-3">
				<UserUpdateForm />
				<UserChangePasswordForm />
			</div>
		</WrapperPage>
	);
};

export default MyPage;
