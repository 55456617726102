import React, { memo, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocale } from "../../../hooks/useLocale";
import TableList from "../../../components/TableList";
import { useSelector } from "react-redux";
import { globalStore, RootState } from "../../../state/store";
import { NOT_AVAILABLE } from "../../../common/define";
import { getFileNameWithURL } from "../../../common/utils";
import { listProductActions } from "../../../state/listProduct/listProducSlice";
import { GET_LIST_PRODUCT } from "../../../state/listProduct/actions";
import { UnknownAction } from "@reduxjs/toolkit";
import moment from "moment";
import Table from "../../../components/Table";

const ProductList: React.FC = () => {
	const { t } = useLocale();
	const navigate = useNavigate();

	const listProduct = useSelector(
		(state: RootState) => state.listProductReducers.data.content,
	);
	const totalPagesStore = useSelector(
		(state: RootState) => state.listProductReducers.data.totalPages,
	);
	const totalElements = useSelector(
		(state: RootState) => state.listProductReducers.data.totalElements,
	);
	const currentPageStore = useSelector(
		(state: RootState) => state.listProductReducers.currentPage,
	);

	const goToDetail = (idx: number) => {
		navigate(`product-detail/${idx}`);
	};

	const handleChangePage = async (idx: number) => {
		await globalStore.dispatch(
			listProductActions.updateCurrentPage(idx) as unknown as UnknownAction,
		);
	};

	const thead = [
		t("PRODUCT_NO"),
		t("PRODUCT_NAME"),
		t("PRODUCT_REGISTRATION_DATE"),
		t("FILE"),
		t("REGISTRATION_COUNTRY"),
		t("STATUS"),
	];

	const tbody = useMemo(() => {
		return listProduct?.length > 0 ? (
			listProduct.map((item, idx) => (
				<tr
					role="button"
					key={"member" + idx}
					onClick={() => goToDetail(item.id)}
				>
					<td className="text-center">{item.code || NOT_AVAILABLE}</td>
					<td className="text-center">{item.name || NOT_AVAILABLE}</td>
					<td className="text-center">
						{item.createdDate
							? moment(item.createdDate).format("YYYY.MM.DD")
							: NOT_AVAILABLE}
					</td>
					<td className="text-center">
						{item.feeFile ? getFileNameWithURL(item.feeFile) : NOT_AVAILABLE}
					</td>
					<td className="text-center">{item.country?.name || NOT_AVAILABLE}</td>
					<td className="text-center">{item.status || NOT_AVAILABLE}</td>
				</tr>
			))
		) : (
			<tr>
				{thead.map((_item, idx) => (
					<td key={idx} className="text-center"></td>
				))}
			</tr>
		);
	}, [listProduct]);

	useEffect(() => {
		globalStore.dispatch(
			GET_LIST_PRODUCT({
				isSearch: true,
				pageSize: 10,
				pageNumber: currentPageStore,
			}) as unknown as UnknownAction,
		);
	}, [currentPageStore]);

	useEffect(() => {
		globalStore.dispatch(listProductActions.updateCurrentPage(1));
	}, []);

	return (
		<Table
			emptyTitle={t("EMPTY_PRODUCT")}
			title={`${t("TOTAL")} (${totalElements} ${t("CASES")})`}
			thead={thead}
			tbody={tbody}
			countPage={totalPagesStore}
			currentPage={currentPageStore}
			handleChangePage={handleChangePage}
			total={listProduct.length}
		/>
	);
};

export default memo(ProductList);
