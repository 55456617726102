import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IError, IProductFeature, IProductPrice } from "../../common/define";
import { addAsyncReducers } from "../store";
import { GET_PRODUCT_ACTUAL_LOSS_DETAIL } from "./actions";

export interface IProductActualLossDetail {
	data: {
		id: number | undefined;
		name: string;
		code: string;
		feeFile: string;
		productPrices: IProductPrice[];
		productFeatures: IProductFeature[];
	};
}

export interface IAuth {
	access_token: string;
	access_token_expires_dttm: string;
	refresh_token: string;
	refresh_token_expires_dttm: string;
}

interface IOtherState {
	isLoading: boolean;
}

const initialState: IProductActualLossDetail & IOtherState & IError = {
	data: {
		id: undefined,
		name: "",
		code: "",
		feeFile: "",
		productPrices: [],
		productFeatures: [],
	},
	result_code: "",
	result_msg: "",
	code: "",
	isLoading: false,
};

const productActualLossDetailSlice = createSlice({
	name: "productActualLossDetailSlice",
	initialState,
	reducers: {
		updateProductActualLossDetail: (state, action: PayloadAction<any>) => {
			return {
				...state,
				data: action.payload,
			};
		},
		setErrorCode: (state, action: PayloadAction<string>) => {
			state.code = action.payload;
			return state;
		},
		updateKeyword: (state, action: PayloadAction<string>) => {
			return {
				...state,
				keyword: action.payload,
			};
		},
		updateCountryId: (state, action: PayloadAction<string>) => {
			return {
				...state,
				countryId: action.payload,
			};
		},
		updateCurrentPage: (state, action: PayloadAction<number>) => {
			return {
				...state,
				currentPage: action.payload,
			};
		},
		reset: () => initialState,
	},
	extraReducers: (builder) => {
		addAsyncReducers(builder, GET_PRODUCT_ACTUAL_LOSS_DETAIL, initialState);
	},
});

export const productActualLossDetailActions =
	productActualLossDetailSlice.actions;

export const productActualLossDetailReducers =
	productActualLossDetailSlice.reducer;

// export const authSelectors = {
// 	isLoading: (state: RootState) => state.authReducers.isLoading,
// 	code: (state: RootState) => state.authReducers.code,
// };
