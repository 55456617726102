import { createAsyncThunk } from "@reduxjs/toolkit";
import { globalStore } from "../store";
import { queryApi } from "../../common/queryAPI";
import { apiRequest } from "../../common/api.request";
import { TYPE } from "../../common/define";
import { configActualLossActions } from "./configActualLossSlice";
import { TFunction } from "i18next";
import Swal from "sweetalert2";
// import { listCustomerActions } from "./customerInfoSlice";
import { v4 as uuidv4 } from "uuid";

export const GET_CONFIG_ACTUAL_LOSS = createAsyncThunk(
	"configActualLossSlice/GET_CONFIG_ACTUAL_LOSS",
	async () => {
		try {
			const res = await queryApi(apiRequest.adminGetConfigActualLoss);

			if (res.type === TYPE.SUCCESS && res.data) {
				globalStore.dispatch(
					configActualLossActions.updateConfigActualLoss(res.data),
				);
			}

			if (res.type === TYPE.ERROR) {
				globalStore.dispatch(
					configActualLossActions.setErrorCode(res.messages[0].code),
				);
			}
		} catch (e) {
			console.log("error", e);
		}
	},
);

export const CREATE_CONFIG_ACTUAL_LOSS = createAsyncThunk(
	"configActualLossSlice/CREATE_CONFIG_ACTUAL_LOSS",
	async (data: {
		name: string;
		typeValue: string;
		callback?: (data?: number | any) => void;
	}) => {
		try {
			const { name, typeValue, callback } = data;
			const code = uuidv4();
			const res = await queryApi(apiRequest.adminCreateActualLossConfig, {
				code,
				name,
				typeValue,
			});

			if (res.type === TYPE.SUCCESS) {
				callback && callback();
			}

			if (res.type === TYPE.ERROR) {
				globalStore.dispatch(
					configActualLossActions.setErrorCode(res.messages[0].code),
				);
			}
		} catch (e) {
			console.log("error", e);
		}
	},
);

export const CREATE_PRODUCT_ACTUAL_LOSS = createAsyncThunk(
	"configActualLossSlice/CREATE_PRODUCT_ACTUAL_LOSS",
	async (data: {
		t?: TFunction<"translation", undefined>;
		name: string;
		items: {
			code: string;
			name: string;
			price: number;
		}[];
		callback?: (data?: number | any) => void;
	}) => {
		try {
			const { name, items, t, callback } = data;
			const res = await queryApi(apiRequest.adminCreateProductActualLoss, {
				name,
				items,
			});

			if (res.type === TYPE.SUCCESS && res.data) {
				console.log("res.data---=====---", res.data);
				callback && callback(res.data.id);
			}

			if (res.type === TYPE.ERROR) {
				globalStore.dispatch(
					configActualLossActions.setErrorCode(res.messages[0].code),
				);
			}
		} catch (e) {
			console.log("error", e);
		}
	},
);
