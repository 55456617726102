import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IContractDetail, IError } from "../../common/define";
import { addAsyncReducers } from "../store";
import { GET_CONTRACT_INFO } from "./actions";

interface IState {
	data: string[] | undefined;
}

interface IOtherState {
	isLoading: boolean;
}

const initialState: IState & IOtherState & IError = {
	data: undefined,
	result_code: "",
	result_msg: "",
	code: "",
	isLoading: false,
};

const contractStatusSlice = createSlice({
	name: "contractStatusSlice",
	initialState,
	reducers: {
		updateData: (state, action: PayloadAction<string[]>) => {
			return {
				...state,
				data: action.payload,
			};
		},
		setErrorCode: (state, action: PayloadAction<string>) => {
			state.code = action.payload;
			return state;
		},
		reset: () => initialState,
	},
	extraReducers: (builder) => {
		addAsyncReducers(builder, GET_CONTRACT_INFO, initialState);
	},
});

export const contractStatusActions = contractStatusSlice.actions;
export const contractStatusReducers = contractStatusSlice.reducer;

// export const authSelectors = {
// 	isLoading: (state: RootState) => state.authReducers.isLoading,
// 	code: (state: RootState) => state.authReducers.code,
// };
